<template>
  <div>
    <div class="form-row align-items-start mt-3">
      <div class="col-md-5">
        <label>Contrat</label>
        <b-form-select
          :disabled="readonly"
          :options="signedDocumentModels"
          :name="`signed_document_model_id_${index}`"
          size="sm"
          v-model="model.signed_document_model_id"
          v-validate="'required'"
          :state="state(`signed_document_model_id_${index}`)">
        </b-form-select>
      </div>
      <div class="col-md-6">
        <label>Description</label>
        <b-form-textarea
          :readonly="readonly"
          :name="`description_${index}`"
          class="form-control form-control-sm"
          v-model="model.description"
          v-validate="'required'"
          data-vv-delay="500"
          :state="state(`description_${index}`)"
        />
      </div>
      <div class="col-md-1" style="margin-top: 29px;" v-if="!readonly">
        <b-button
          size="sm"
          variant="outline-danger"
          class="btn-icon btn-icon-only btn-height"
          @click="remove()">
          <i class="pe-7s-trash btn-icon-wrapper"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form.vue'

  export default {
    mixins: [
      Form
    ],
    props: {
      signedDocumentModels: Array,
      index: {
        type: Number,
        default: 0
      }
    },
    methods: {
      remove() {
        if (this.model.id) {
          this.$set(this.model, '_destroy', '1')
          this.$emit('input', this.model)
        } else {
          this.$parent.items.splice(this.index, 1)
        }
      }
    }
  }
</script>
