<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} une résidence
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab active :disabled="manager" @click="disableSubmit = false">
                <template slot="title">
                  Général <i class="small fas fa-lock" v-if="manager"></i>
                </template>
                <h5 class="card-title">Contexte</h5>
                <div class="form-row">
                  <div class="col-md-6">
                    <label :class="{'text-success': model.brand_id}">Enseigne*</label>
                    <b-form-select
                      name="residence_brand_id"
                      :disabled="readonly"
                      v-model="model.brand_id"
                      v-validate="'required'"
                      :options="brands"
                      size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez une enseigne</option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">Informations</h5>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Nom de la résidence*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="residence_title"
                      size="sm"
                      v-model="model.title"
                      v-validate="'required|uniqueness:title'"
                      :state="state('residence_title')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('residence_title') }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-md-4">
                    <label>Clé*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="residence_key"
                      size="sm"
                      v-model="model.key"
                      v-validate="'required|length:2|uniqueness:key'"
                      placeholder="XX"
                      :state="state('residence_key')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('residence_key') }}
                    </b-form-invalid-feedback>
                    <b-form-text>
                      Au format&nbsp;: majuscule, 2 caractères
                    </b-form-text>
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">Adresse</h5>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Adresse*</label>
                    <b-form-textarea
                      :readonly="readonly"
                      name="residence_address"
                      size="sm"
                      v-model="model.address"
                      v-validate="'required'"
                      :state="state('residence_address')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('residence_address') }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-md-4">
                    <label>Complément d'adresse</label>
                    <b-form-input
                      :readonly="readonly"
                      size="sm"
                      v-model="model.address_complement" />
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Code postal*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="residence_zip_code"
                      v-mask="'#####'"
                      size="sm"
                      v-model="model.zip_code"
                      v-validate="'required|length:5'"
                      :state="state('residence_zip_code')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('residence_zip_code') }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-md-4">
                    <label>Ville*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="residence_city"
                      size="sm"
                      v-model="model.city"
                      v-validate="'required'"
                      :state="state('residence_city')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('residence_city') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>URL de la fiche résidence sur arpej.fr</label>
                    <b-form-input
                      :readonly="readonly"
                      name="residence_url"
                      size="sm"
                      v-model="model.url"
                      v-validate="'url'"
                      placeholder="https://"
                      data-vv-as=" "
                      :state="state('residence_url')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('residence_url') }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-md-4">
                    <label>URL de la visite virtuelle</label>
                    <b-form-input
                      :readonly="readonly"
                      name="residence_virtual_tour_url"
                      size="sm"
                      v-model="model.virtual_tour_url"
                      v-validate="'url'"
                      placeholder="https://"
                      data-vv-as=" "
                      :state="state('residence_virtual_tour_url')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('residence_virtual_tour_url') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">Contact</h5>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Téléphone</label>
                    <PhoneInput
                      v-model="model.phone_number"
                      :code=" model.phone_country_code"
                      name="phone_number"
                      @code="setCountryCode($event, 'phone_country_code')"
                      :readonly="readonly" />
                    <b-form-text>
                      Au format international : +33 0 00 00 00 00
                    </b-form-text>
                  </div>
                  <div class="col-md-4">
                    <label>Téléphone (Urgence technique)</label>
                    <PhoneInput
                      v-model="model.technical_emergency_phone_number"
                      :code=" model.technical_emergency_phone_country_code"
                      name="technical_emergency_phone_number"
                      @code="setCountryCode($event, 'technical_emergency_phone_country_code')"
                      :readonly="readonly" />
                    <b-form-text>
                      Au format international : +33 0 00 00 00 00
                    </b-form-text>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Téléphone (Problèmes internet)</label>
                    <PhoneInput
                      v-model="model.internet_problem_phone_number"
                      :code=" model.internet_problem_phone_country_code"
                      name="internet_problem_phone_number"
                      @code="setCountryCode($event, 'internet_problem_phone_country_code')"
                      :readonly="readonly" />
                    <b-form-text>
                      Au format international : +33 0 00 00 00 00
                    </b-form-text>
                  </div>
                  <div class="col-md-4">
                    <label>Téléphone (Résidence)</label>
                    <PhoneInput
                      v-model="model.tenants_phone_number"
                      :code=" model.tenants_phone_country_code"
                      name="tenants_phone_number"
                      @code="setCountryCode($event, 'tenants_phone_country_code')"
                      :readonly="readonly" />
                    <b-form-text>
                      Au format international : +33 0 00 00 00 00
                    </b-form-text>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Contact principal*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="residence_agent_email"
                      size="sm"
                      v-model="model.agent_email"
                      v-validate="'required|email'"
                      :state="state('residence_agent_email')"  />
                    <b-form-invalid-feedback>
                      {{ errors.first('residence_agent_email') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">Caractéristiques</h5>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <b-form-checkbox
                      :disabled="readonly"
                      v-model="model.accessible_housing_subsidies_als">
                      Aides aux logements accessibles : ALS
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-4">
                    <b-form-checkbox
                      :disabled="readonly"
                      v-model="model.accessible_housing_subsidies_apl">
                      Aides aux logements accessibles : APL
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6" v-for="locale in this.$locales" :key="locale">
                    <div class="form-row mt-3">
                      <div class="col-md-12">
                        <label>{{ locale.toUpperCase() }}: Description</label>
                        <b-form-textarea
                          :readonly="readonly"
                          size="sm"
                          v-model="model['description_' + locale]" />
                      </div>
                    </div>
                    <div class="form-row mt-3">
                      <div class="col-md-12">
                        <label>{{ locale.toUpperCase() }}: Commentaire facultatif affiché en front pour le champ "Equipé"</label>
                        <b-form-input
                          :readonly="readonly"
                          size="sm"
                          v-model="model['optional_comment_equipped_' + locale]" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <b-form-checkbox
                      :disabled="readonly"
                      v-model="model.school_reserved"
                      name="school_reserved">
                      Réservataire
                    </b-form-checkbox>
                    <b-form-text v-if="!readonly">
                      Si vous activez cette option, vous devrez sélectionner au moins une école
                    </b-form-text>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-12">
                    <label>Écoles réservataires</label>
                    <div class="position-relative form-group">
                      <search-select
                        :url="schoolsUrl"
                        v-model="model.school_ids"
                        :disabled="readonly"
                        order="title"
                        direction="asc"
                        :multiple="true"
                        :state="state('residence_school_ids')"
                      >
                        <template slot="selected-option" slot-scope="school">
                          {{ school.title }} - {{ school.university }} - {{ school.city }}
                        </template>
                        <template slot="option" slot-scope="school">
                          {{ school.title }}
                          <span class="text-muted">{{ school.university }} - {{ school.city }}</span>
                        </template>
                      </search-select>
                      <b-form-invalid-feedback>
                        {{ errors.first('residence_school_ids') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">Publication</h5>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>Date de publication</label>
                    <DatePicker
                      :disabled="readonly"
                      v-model="model.published_at"
                      lang="fr"
                      format="DD/MM/YYYY" />
                  </div>
                </div>
                <item-information :value="value" />
              </b-tab>
              <b-tab :disabled="manager" @click="disableSubmit = false">
                <template slot="title">
                  Services <i class="small fas fa-lock" v-if="manager"></i>
                </template>
                <ResidenceFormService
                  :services_options="services"
                  :new_residence_service="new_residence_service"
                  :readonly="readonly"
                  :value="value" />
              </b-tab>
              <b-tab :disabled="manager" @click="disableSubmit = false">
                <template slot="title">
                  Contrats <i class="small fas fa-lock" v-if="manager"></i>
                </template>
                <h5 class="card-title">Contrats</h5>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Contrat LD</label>
                    <div class="position-relative form-group">
                      <b-form-select
                        :disabled="readonly"
                        v-model="model.signed_document_model_ids_for_list1"
                        multiple
                        :options="signedDocumentModels"
                        size="sm">
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label>Contrat MD</label>
                    <div class="position-relative form-group">
                      <b-form-select
                        :disabled="readonly"
                        v-model="model.signed_document_model_ids_for_list2"
                        multiple
                        :options="signedDocumentModels"
                        size="sm">
                      </b-form-select>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Horaires" @click="disableSubmit = false">
                <ResidenceFormDays
                  :readonly="readonly"
                  :value="value" />
              </b-tab>
              <b-tab :disabled="manager" @click="disableSubmit = true">
                <template slot="title">
                  Photos <i class="small fas fa-lock" v-if="manager || method === 'post'"></i>
                </template>
                <div v-if="!readonly">
                  <PictureForm :action="action" :method="method" :name="name" title="résidence" />
                </div>
                <div class="form-row" v-else>
                  <div v-for="picture in model.pictures" class="col-md-3 picture-card">
                    <img v-bind:src="picture.url">
                    <div v-for="locale in $locales" :key="locale">
                      <hr class="mt-3">
                      <h5 class="card-title">{{ locale }}</h5>
                      <p>{{ picture['title_' + locale] }}</p>
                      <p>{{ picture['caption_' + locale] }}</p>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="disabled"
                @click.stop.prevent="submit">
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import UniquenessValidator from '../mixins/UniquenessValidator'
  import axios from 'axios'
  import DatePicker from 'vue2-datepicker'
  import ResidenceFormService from './ResidenceFormService'
  import ResidenceFormDays from './ResidenceFormDays'

  export default {
    mixins: [
      Form,
      UniquenessValidator
    ],
    components: {
      DatePicker,
      ResidenceFormService,
      ResidenceFormDays
    },
    props: {
      brands: Array,
      services: Array,
      schoolsUrl: String,
      signedDocumentModels: Array,
      new_residence_service: Object,
      manager: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
