<template>
  <div>
    <h5 class="card-title">Tarif</h5>
    <div class="form-row">
      <div class="col-md-12">
        <label>Tarif</label>
        <b-form-select
          :disabled="readonly"
          v-model="selectedPricingId"
          :options="pricings.options"
          @change="setCurrentOfferPricing"
          size="sm">
        </b-form-select>
      </div>
    </div>
    <hr class="mt-3">
    <h5 class="card-title">Montants</h5>
    <div class="form-row" v-for="amount in amounts" :key="amount.key">
      <div class="col-md-3 mb-4">
        <label>{{ amount.title }}{{ amount.required ? '*' : '' }}</label>
        <b-form-select
          size="sm"
          :disabled="readonly"
          :options="amount.options"
          :name="amount.key + '_type'"
          v-validate="{ 'required': amount.required }"
          v-model="offerPricing[`${amount.key}_type`]">
          <template slot="first">
            <option :value="null" disabled>Choisir...</option>
          </template>
        </b-form-select>
      </div>
      <div class="col-md-3" v-if="displayAmount(amount.key)">
        <label>{{ amountLabel(amount.key) }}{{ amount.required ? '*' : '' }}</label>
        <b-input-group size="sm" append="€">
          <b-form-input
            :readonly="readonly"
            type="number"
            min="0"
            placeholder="0"
            :name="amount.key + '_from'"
            v-validate="amountFromValidation(amount)"
            v-model="offerPricing[`${amount.key}_from`]"
            :state="state(amount.key + '_from')"
            @focus="$event.target.select()" />
        </b-input-group>
        <b-form-text>En €</b-form-text>
      </div>
      <div class="col-md-3" v-if="offerPricing[`${amount.key}_type`] === 'range'">
        <label>jusqu'à...</label>
        <b-input-group size="sm" append="€">
          <b-form-input
            :readonly="readonly"
            type="number"
            min="0"
            placeholder="0"
            :name="amount.key + '_to'"
            v-validate="{ 'min_value': offerPricing[`${amount.key}_from`] }"
            v-model="offerPricing[`${amount.key}_to`]"
            :state="state(amount.key + '_to')"
            @focus="$event.target.select()" />
        </b-input-group>
        <b-form-text>En €</b-form-text>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'

  export default {
    mixins: [
      Form
    ],
    props: {
      pricings: {
        type: Object,
        default: () => {}
      },
      new_offer_pricing: {
        type: Object,
        default: () => {}
      },
      optionsRent: {
        type: Array,
        default: () => []
      },
      optionsRentPlus: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        offerPricing: {},
        selectedPricingId: null,
        amounts: [
          {
            key: 'rent_amount',
            title: 'Loyer mensuel TTC & TCC',
            options: this.optionsRent,
            required: true
          }, {
            key: 'charges_amount',
            title: 'Charges locatives',
            options: this.optionsRentPlus,
            required: false
          }, {
            key: 'packages_amount',
            title: "Prestation d'entrée",
            options: this.optionsRentPlus,
            required: false
          }, {
            key: 'auxiliary_amount',
            title: 'Loyer accessoire',
            options: this.optionsRentPlus,
            required: false
          }, {
            key: 'deposit_amount',
            title: 'Dépôt de garantie',
            options: this.optionsRentPlus,
            required: true
          }
        ]
      }
    },
    computed: {
      pricingTitle() {
        return this.pricings.options.find(opt => opt.value === this.selectedPricingId).text
      },
      isNew() {
        return !_.some(this.model.offer_pricings_attributes, { offer_id: this.offerPricing.offer_id })
      }
    },
    created() {
      this.selectedPricingId = this.pricings.current.id
      this.setCurrentOfferPricing()
      if (this.model.offer_pricings_count === 0) {
        this.model.offer_pricings_attributes.push(this.offerPricing)
      }
    },
    methods: {
      displayAmount(key) {
        return this.offerPricing[`${key}_type`]
          && this.offerPricing[`${key}_type`] !== 'none'
          && this.offerPricing[`${key}_type`] !== 'free'
      },
      amountLabel(key) {
        if (this.offerPricing[`${key}_type`] === 'unique') return 'Unique'
        if (this.offerPricing[`${key}_type`] === 'from') return 'À partir de'
        if (this.offerPricing[`${key}_type`] === 'range') return 'De...'
      },
      amountFromValidation(amount) {
        if (amount.required) return 'required|min_value:0'
        return 'min_value:0'
      },
      setCurrentOfferPricing() {
        let offerPricing = this.model.offer_pricings_attributes.find(op => op.pricing_id === this.selectedPricingId)
        if (offerPricing) {
          this.offerPricing = offerPricing
        } else {
          this.offerPricing = this.new_offer_pricing
          this.offerPricing.pricing_id = this.selectedPricingId
        }
      }
    },
    watch: {
      selectedPricingId(id) {
        if (this.model.offer_pricings_count > 0) {
          let alreadySet = _.some(this.model.offer_pricings_attributes, { offer_id: null })
          this.$nextTick(() => {
            if (this.isNew && !alreadySet) {
              this.model.offer_pricings_attributes.push(this.offerPricing)
            }
            if (!this.isNew && alreadySet) {
              _.remove(this.model.offer_pricings_attributes, op => op.offer_id === null)
            }
          })
        } else {
          this.offerPricing.pricing_id = id
        }
      }
    }
  }
</script>