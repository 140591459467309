<template>
  <div class="col-md-4">
    <label :class="{'text-success': value}">{{ label_ }}</label>
    <div class="position-relative form-group">
      <date-picker v-model="date"
        lang="fr"
        format="DD/MM/YYYY"
        value-type="format"
        @input="handleInput"
        @clear="(clear)=>{date=''}"
        :range="range">
      </date-picker>
      <small class="form-text text-muted">{{ subtitle_ }}</small>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker'

  export default {
    components: {
      DatePicker
    },
    props: {
      type: {
        type: String,
        default: 'create'
      },
      value: {
        type: [Date, String, Array]
      },
      range: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        date: this.range ? (this.value!==undefined ? (this.value+'').split(',') : '') : this.value
      }
    },
    computed: {
      label_() {
        if (this.label) return this.label
        let l = this.type === 'create' ? 'Crée le' : 'Modifié le'
        return this.range ? l.replace(' le', ' dans la période') : l
      },
      subtitle_() {
        if (this.subtitle) return this.subtitle
        let s = this.type === 'create' ? 'Date de création' : 'Date de modification'
        return this.range ? s.replace('Date', 'Période') : s
      }
    },
    methods: {
      handleInput() {
        this.$emit('input', this.date)
      }
    }
  }
</script>
