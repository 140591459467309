<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} un partenaire
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab title="Général" active>
                <h5 class="card-title">INFORMATIONS</h5>
                <div class="form-row">
                  <div class="col-md-12">
                    <label>Intitulé du partenaire*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="partner_title"
                      size="sm"
                      v-model="model.title"
                      v-validate="'required|uniqueness:title'"
                      data-vv-delay="500"
                      :state="state('partner_title')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('partner_title') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-12">
                    <label>Code*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="partner_code"
                      size="sm"
                      v-model="model.code"
                      v-validate="{ required: true, uniqueness: 'code', regex: /^[\w-]+$/ }"
                      data-vv-delay="500"
                      :state="state('partner_code')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('partner_code') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Contrats">
                <partner-signed-document-model-data-form
                  :readonly="readonly"
                  :signed-document-models="signedDocumentModels"
                  v-model="model"
                />
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit">
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import UniquenessValidator from '../mixins/UniquenessValidator'
  import PartnerSignedDocumentModelDataForm from './PartnerSignedDocumentModelDataForm'

  export default {
    mixins: [
      Form,
      UniquenessValidator
    ],
    components: {
      PartnerSignedDocumentModelDataForm
    },
    props: {
      signedDocumentModels: Array
    }
  }
</script>
