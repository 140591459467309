<template>
  <div>
    <h5 class="card-title">Contrats</h5>
    <div v-show="items.length == 0 && readonly">
      <p>Aucun contrat sélectionné.</p>
    </div>
    <SignedDocumentModelDataForm
      :signed-document-models="availableSignedDocumentModels(item)"
      v-for="(item, index) in items"
      v-show="item._destroy != '1'"
      v-model="items[index]"
      :index="index"
      :key="index"
      :readonly="readonly"
    />
    <div class="mt-4 mb-2" v-if="!readonly">
      <b-button
        size="sm"
        variant="outline-secondary"
        @click="newSignedDocumentModelData()">
        + Ajouter un contrat
      </b-button>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import SignedDocumentModelDataForm from '../signed_document_model_data/SignedDocumentModelDataForm'

  export default {
    mixins: [
      Form
    ],
    components: {
      SignedDocumentModelDataForm
    },
    props: {
      signedDocumentModels: Array
    },
    data() {
      return {
        items: this.value.signed_document_model_data_attributes,
        current: {}
      }
    },
    methods: {
      availableSignedDocumentModels(item = null) {
        let selection = _.map(_.reject(this.items, { _destroy: '1' } ), 'signed_document_model_id')
        return _.reject(this.signedDocumentModels, (sdm) => (item == null || item.signed_document_model_id != sdm.value) && _.includes(selection, sdm.value))
      },
      newSignedDocumentModelData() {
        let available = this.availableSignedDocumentModels()
        if (available.length > 0) {
          this.items.push({
            id: null,
            signed_document_model_id: available[0].value,
            description: null
          })
        }
      }
    }
  }
</script>
