<template>
  <div>
    <div class="mb-4 widget-content bg-light" v-if="!readonly">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="widget-subheading">
            Supprimer ce {{ typeTitle }}&nbsp;?
          </div>
        </div>
        <div class="widget-content-right">
          <button type="button" class="btn ml-3 btn-sm btn-icon btn-transition btn-outline-danger" @click="$delete(selectedPeople, index)">
            <i class="pe-7s-trash btn-icon-wrapper"></i>
            Supprimer
          </button>
        </div>
      </div>
    </div>
    <div v-if="availablePeople.length > 0 && !readonly">
      <h5 class="card-title text-uppercase">
        {{ typeTitle }}s enregistrés
      </h5>
      <div class="form-row">
        <div class="col-md-12">
          <label>
            Souhaitez-vous utiliser l'un des {{ typeTitle }}s déjà enregistré&nbsp;?
          </label>
          <b-form-radio :name="`${prefix}_${index}__create`" v-model="model._create" :value="false" @change="setPersonId(availablePeople[0].id)">
            Oui, le {{ typeTitle }} est
            <b-form-select
              class="mb-2"
              :value="model.id"
              @change="setPersonId($event)"
              :disabled="model._create"
              size="sm">
              <option v-for="person in availablePeople" :value="person.id">
                {{ person.first_name }} {{ person.last_name }}
              </option>
            </b-form-select>
          </b-form-radio>
          <b-form-radio :name="`${prefix}_${index}__create`" v-model="model._create" :value="true" @change="setPersonId(null)">
            Non, c'est un nouveau {{ typeTitle }}
          </b-form-radio>
        </div>
      </div>
      <hr class="my-3">
    </div>
    <h5 class="card-title text-uppercase">
      {{ typeTitle }}
    </h5>
    <div class="form-row mt-3">
      <div class="col-md-6">
        <label>
          Situation
          <span v-show="isRequired('situation')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_situation`"
            :disabled="readonly"
            v-model="model.situation"
            :options="situations"
            size="sm"
            v-validate="{ required: isRequired('situation') }"
            :state="state(`${prefix}_${index}_situation`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_situation`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-6" v-if="isPresent('contract_situation')">
        <label>
          Situation pour le contrat
          <span v-show="isRequired('contract_situation')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_contract_situation`"
            :disabled="readonly"
            v-model="model.contract_situation"
            :options="contractSituations"
            size="sm"
            v-validate="{ required: isRequired('contract_situation') }"
            :state="state(`${prefix}_${index}_contract_situation`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_contract_situation`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-2">
      <div class="col-md-4">
        <label>
          Civilité
          <span v-show="isRequired('gender')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-radio-group
            v-model="model.gender"
            :disabled="readonly"
            :options="[{ text: 'Monsieur', value: 'male' }, { text: 'Madame', value: 'female' }]"
            :name="`${prefix}_${index}_gender`"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_gender`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-2">
      <div class="col-md-4">
        <label>
          Prénom
          <span v-show="isRequired('first_name')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_first_name`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.first_name"
            v-validate="{ required: isRequired('first_name') }"
            :state="state(`${prefix}_${index}_first_name`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_first_name`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-4">
        <label>
          Nom
          <span v-show="isRequired('last_name')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_last_name`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.last_name"
            v-validate="{ required: isRequired('last_name') }"
            :state="state(`${prefix}_${index}_last_name`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_last_name`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-4">
        <label>Nom de jeune fille</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_original_last_name`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.original_last_name"
            :state="state(`${prefix}_${index}_last_name`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_last_name`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-2">
      <div class="col-md-6">
        <label>
          Email
          <span v-show="isRequired('email')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_email`"
            type="email"
            class="form-control form-control-sm"
            v-model="model.email"
            v-validate="{ required: isRequired('email'), email: true }"
            :state="state(`${prefix}_${index}_email`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_email`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-6">
        <label>
          Téléphone
          <span v-show="isRequired('phone_number')">*</span>
        </label>
        <PhoneInput
          v-model="model.phone_number"
          :code=" model.phone_country_code"
          :name="`${prefix}_${index}_phone_number`"
          @code="setCountryCode($event, 'phone_country_code')"
          :required="isRequired('phone_number')"
          :readonly="readonly" />
      </div>
    </div>
    <div class="form-row mt-2">
      <div class="col-md-3" v-if="isPresent('address')">
        <label>
          Adresse
          <span v-show="isRequired('address')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-textarea
            :readonly="readonly"
            :name="`${prefix}_${index}_address`"
            class="form-control form-control-sm"
            v-model="model.address"
            v-validate="{ required: isRequired('address') }"
            :state="state(`${prefix}_${index}_address`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_address`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3" v-if="isPresent('international_address')">
        <label>Adresse complète*</label>
        <div class="position-relative form-group">
          <b-form-textarea
            :readonly="readonly"
            :name="`${prefix}_${index}_international_address`"
            class="form-control form-control-sm"
            v-model="model.international_address"
            v-validate="{ required: isRequired('international_address') }"
            :state="state(`${prefix}_${index}_international_address`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_international_address`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3" v-if="isPresent('zip_code')">
        <label>
          Code postal
          <span v-show="isRequired('zip_code')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            v-mask="'#####'"
            :name="`${prefix}_${index}_zip_code`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.zip_code"
            v-validate="{ required: isRequired('zip_code') }"
            :state="state(`${prefix}_${index}_zip_code`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_zip_code`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3">
        <label>
          Ville
          <span v-show="isRequired('city')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_city`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.city"
            v-validate="{ required: isRequired('city') }"
            :state="state(`${prefix}_${index}_city`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_city`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3">
        <label>
          Pays
          <span v-show="isRequired('country_id')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_country_id`"
            :disabled="readonly"
            v-model="model.country_id"
            :options="countries"
            size="sm"
            v-validate="{ required: isRequired('country_id') }"
            :state="state(`${prefix}_${index}_country_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_country_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-2">
      <div class="col-md-3">
        <label>
          Date de naissance
          <span v-show="isRequired('date_of_birth')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_date_of_birth`"
            type="text"
            class="form-control form-control-sm"
            v-mask="'##/##/####'"
            placeholder="JJ/MM/AAAA"
            v-model="model.date_of_birth"
            v-validate="{ required: isRequired('date_of_birth'), date_format: 'dd/MM/yyyy' }"
            :state="state(`${prefix}_${index}_date_of_birth`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_date_of_birth`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3">
        <label>
          Ville de naissance
          <span v-show="isRequired('birth_city')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_birth_city`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.birth_city"
            v-validate="{ required: isRequired('birth_city') }"
            :state="state(`${prefix}_${index}_birth_city`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_birth_city`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3">
        <label>
          Pays de naissance
          <span v-show="isRequired('birth_country_id')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_birth_country_id`"
            :disabled="readonly"
            v-model="model.birth_country_id"
            :options="countries"
            size="sm"
            v-validate="{ required: isRequired('birth_country_id') }"
            :state="state(`${prefix}_${index}_birth_country_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_birth_country_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3">
        <label>
          Nationalité
          <span v-show="isRequired('nationality_id')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_nationality_id`"
            :disabled="readonly"
            v-model="model.nationality_id"
            :options="nationalities"
            size="sm"
            v-validate="{ required: isRequired('nationality_id') }"
            :state="state(`${prefix}_${index}_nationality_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_nationality_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-2">
      <div class="col-md-3">
        <label>
          Situation familiale
          <span v-show="isRequired('family_situation_id')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_family_situation_id`"
            :disabled="readonly"
            v-model="model.family_situation_id"
            :options="familySituations"
            size="sm"
            v-validate="{ required: isRequired('family_situation_id') }"
            :state="state(`${prefix}_${index}_family_situation_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_family_situation_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <hr class="my-3">
    <h5 class="card-title text-uppercase">
      Informations&nbsp;: {{ situationTitle }}
    </h5>
    <div class="form-row mt-2" v-if="isPresent('study_id')">
      <div class="col-md-12">
        <label>Niveau d'étude<span v-show="isRequired('study_id')">*</span></label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_study_id`"
            :disabled="readonly"
            v-model="model.study_id"
            :options="studies"
            size="sm"
            v-validate="{ required: isRequired('study_id') }"
            :state="state(`${prefix}_${index}_study_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_study_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('school_type_id')">
      <div class="col-md-12">
        <label>Type d'établissement</label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_school_type_id`"
            :disabled="readonly"
            v-model="model.school_type_id"
            :options="schoolTypes"
            size="sm"
            :state="state(`${prefix}_${index}_school_type_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_school_type_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('school_id')">
      <div class="col-md-12">
        <label>Établissement</label>
        <div class="position-relative form-group">
          <search-select
            :url="schoolsUrl"
            v-model="model.school_id"
            :disabled="readonly"
            order="title"
            direction="asc"
            :state="state(`${prefix}_${index}_school_id`)"
          >
            <template slot="selected-option" slot-scope="school">
              {{ school.title }} - {{ school.university }} - {{ school.city }}
            </template>
            <template slot="option" slot-scope="school">
              {{ school.title }}
              <span class="text-muted">{{ school.university }} - {{ school.city }}</span>
            </template>
          </search-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_school_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('study_sector_id')">
      <div class="col-md-12">
        <label>Secteur d'étude</label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_study_sector_id`"
            :disabled="readonly"
            v-model="model.study_sector_id"
            :options="studySectors"
            size="sm"
            :state="state(`${prefix}_${index}_study_sector_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_study_sector_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('other_school')">
      <div class="col-md-12">
        <label>Autre établissement</label>
        <div class="position-relative form-group">
          <b-form-input :readonly="readonly" size="sm" v-model="model.other_school" />
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('scholarship_owner')">
      <div class="col-md-12">
        <div class="position-relative form-group">
          <label>Bénéficiez-vous d'une bourse&nbsp;?</label>
          <div class="position-relative form-group">
            <b-form-radio-group
              v-model="model.scholarship_owner"
              :options="[{ text: 'Oui', value: true }, { text: 'Non', value: false }]"
              :name="`${prefix}_${index}_scholarship_owner`"
            />
            <b-form-invalid-feedback>
              {{ errors.first(`${prefix}_${index}_scholarship_owner`) }}
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('parents_address')">
      <div class="col-md-3">
        <label>Adresse des parents</label>
        <div class="position-relative form-group">
          <b-form-textarea
            :readonly="readonly"
            :name="`${prefix}_${index}_parents_address`"
            class="form-control form-control-sm"
            v-model="model.parents_address"
            :state="state(`${prefix}_${index}_parents_address`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_parents_address`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3">
        <label>Code postal des parents</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            v-mask="'#####'"
            :name="`${prefix}_${index}_parents_zip_code`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.parents_zip_code"
            :state="state(`${prefix}_${index}_parents_zip_code`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_parents_zip_code`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3">
        <label>Ville des parents</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_parents_city`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.parents_city"
            :state="state(`${prefix}_${index}_parents_city`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_parents_city`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3">
        <label>Pays des parents</label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_parents_country_id`"
            :disabled="readonly"
            v-model="model.parents_country_id"
            :options="countries"
            size="sm"
            :state="state(`${prefix}_${index}_parents_country_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_parents_country_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('company_name')">
      <div class="col-md-8">
        <label>
          Employeur / Lieu de travail / Nom de l'entreprise
          <span v-show="isRequired('company_name')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_company_name`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.company_name"
            v-validate="{ required: isRequired('company_name') }"
            :state="state(`${prefix}_${index}_company_name`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_company_name`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-4" v-if="isPresent('company_phone_number')">
        <label>Téléphone employeur / professionnel</label>
        <PhoneInput
          v-model="model.company_phone_number"
          :code=" model.company_phone_country_code"
          :name="`${prefix}_${index}_company_phone_number`"
          @code="setCountryCode($event, 'company_phone_country_code')"
          :readonly="readonly" />
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('company_address')">
      <div class="col-md-3">
        <label>Adresse employeur / professionnelle</label>
        <div class="position-relative form-group">
          <b-form-textarea
            :readonly="readonly"
            :name="`${prefix}_${index}_company_address`"
            class="form-control form-control-sm"
            v-model="model.company_address"
            :state="state(`${prefix}_${index}_company_address`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_company_address`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3">
        <label>Code postal employeur / professionnel</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            v-mask="'#####'"
            :name="`${prefix}_${index}_occupation`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.company_zip_code"
            :state="state(`${prefix}_${index}_occupation`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_occupation`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3">
        <label>Ville employeur / professionnelle</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_company_city`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.company_city"
            :state="state(`${prefix}_${index}_company_city`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_company_city`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-3">
        <label>Pays employeur / professionnel</label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_company_country_id`"
            :disabled="readonly"
            v-model="model.company_country_id"
            :options="countries"
            size="sm"
            :state="state(`${prefix}_${index}_company_country_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_company_country_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('occupation')">
      <div class="col-md-12">
        <label>Profession</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_occupation`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.occupation"
            :state="state(`${prefix}_${index}_occupation`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_occupation`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1">
      <div class="col-md-4" v-if="isPresent('activity_sector_id')">
        <label>Secteur d'activité</label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_activity_sector_id`"
            :disabled="readonly"
            v-model="model.activity_sector_id"
            :options="activitySectors"
            size="sm"
            :state="state(`${prefix}_${index}_activity_sector_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_activity_sector_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-4" v-if="isPresent('hiring_date')">
        <label>
          Date d'embauche
          <span v-show="isRequired('hiring_date')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_hiring_date`"
            type="text"
            class="form-control form-control-sm"
            v-mask="'##/##/####'"
            placeholder="JJ/MM/AAAA"
            v-model="model.hiring_date"
            v-validate="{ required: isRequired('hiring_date'), date_format: 'dd/MM/yyyy' }"
            :state="state(`${prefix}_${index}_hiring_date`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_hiring_date`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-4" v-if="isPresent('employment_contract_id')">
        <label>Contrat de travail</label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_employment_contract_id`"
            :disabled="readonly"
            v-model="model.employment_contract_id"
            :options="employmentContracts"
            size="sm"
            :state="state(`${prefix}_${index}_employment_contract_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_employment_contract_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('net_monthly_income')">
      <div class="col-md-12">
        <label>
          Revenus mensuels nets
          <span v-show="isRequired('net_monthly_income')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_net_monthly_income`"
            type="number"
            class="form-control form-control-sm"
            v-model="model.net_monthly_income"
            v-validate="{ required: isRequired('net_monthly_income') }"
            :state="state(`${prefix}_${index}_net_monthly_income`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_net_monthly_income`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('monthly_family_allowances')">
      <div class="col-md-12">
        <label>
          Allocations familiales mensuelles
          <span v-show="isRequired('monthly_family_allowances')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_monthly_family_allowances`"
            type="number"
            class="form-control form-control-sm"
            v-model="model.monthly_family_allowances"
            v-validate="{ required: isRequired('monthly_family_allowances') }"
            :state="state(`${prefix}_${index}_monthly_family_allowances`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_monthly_family_allowances`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('other_monthly_income')">
      <div class="col-md-6">
        <label>
          Autres revenus mensuels
          <span v-show="isRequired('other_monthly_income')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_other_monthly_income`"
            type="number"
            class="form-control form-control-sm"
            v-model="model.other_monthly_income"
            v-validate="{ required: isRequired('other_monthly_income') }"
            :state="state(`${prefix}_${index}_other_monthly_income`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_other_monthly_income`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-6">
        <label>
          Précisez la nature
          <span v-show="isRequired('other_monthly_income_details')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-textarea
            :readonly="readonly"
            :name="`${prefix}_${index}_other_monthly_income_details`"
            class="form-control form-control-sm"
            v-model="model.other_monthly_income_details"
            v-validate="{ required: isRequired('other_monthly_income_details') }"
            :state="state(`${prefix}_${index}_other_monthly_income_details`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_other_monthly_income_details`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('owner')">
      <div class="col-md-12">
        <div class="position-relative form-group">
          <label>Propriétaire&nbsp;?</label>
          <div class="position-relative form-group">
            <b-form-radio-group
              v-model="model.owner"
              :options="[{ text: 'Oui', value: true }, { text: 'Non', value: false }]"
              :name="`${prefix}_${index}_owner`"
            />
            <b-form-invalid-feedback>
              {{ errors.first(`${prefix}_${index}_owner`) }}
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('monthly_rent_for_tenants')">
      <div class="col-md-12">
        <label>Loyer mensuel pour les locataires</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_monthly_rent_for_tenants`"
            type="number"
            class="form-control form-control-sm"
            v-model="model.monthly_rent_for_tenants"
            v-validate="{ required: isRequired('monthly_rent_for_tenants') }"
            :state="state(`${prefix}_${index}_monthly_rent_for_tenants`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_monthly_rent_for_tenants`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('other_monthly_expenses')">
      <div class="col-md-12">
        <label>Autres charges mensuelles</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_other_monthly_expenses`"
            type="number"
            class="form-control form-control-sm"
            v-model="model.other_monthly_expenses"
            v-validate="{ required: isRequired('other_monthly_expenses') }"
            :state="state(`${prefix}_${index}_other_monthly_expenses`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_other_monthly_expenses`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('financial_situation_comment')">
      <div class="col-md-6">
        <label>Commentaire sur la situation financière</label>
        <div class="position-relative form-group">
          <b-form-textarea
            :readonly="readonly"
            :name="`${prefix}_${index}_financial_situation_comment`"
            class="form-control form-control-sm"
            v-model="model.financial_situation_comment"
            v-validate="{ required: isRequired('financial_situation_comment') }"
            :state="state(`${prefix}_${index}_financial_situation_comment`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_financial_situation_comment`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('activity_started_at')">
      <div class="col-md-6">
        <label>Date de début d'activité</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_activity_started_at`"
            type="text"
            class="form-control form-control-sm"
            v-mask="'##/##/####'"
            placeholder="JJ/MM/AAAA"
            v-model="model.activity_started_at"
            v-validate="{ required: isRequired('activity_started_at'), date_format: 'dd/MM/yyyy' }"
            :state="state(`${prefix}_${index}_activity_started_at`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_activity_started_at`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('retired_since')">
      <div class="col-md-6">
        <label>
          Retraité depuis
          <span v-show="isRequired('retired_since')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_retired_since`"
            type="text"
            class="form-control form-control-sm"
            v-mask="'##/##/####'"
            placeholder="JJ/MM/AAAA"
            v-model="model.retired_since"
            v-validate="{ required: isRequired('retired_since'), date_format: 'dd/MM/yyyy' }"
            :state="state(`${prefix}_${index}_retired_since`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_retired_since`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('situation_comment')">
      <div class="col-md-6">
        <label>
          Commentaire sur la situation
          <span v-show="isRequired('situation_comment')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-textarea
            :readonly="readonly"
            :name="`${prefix}_${index}_situation_comment`"
            class="form-control form-control-sm"
            v-model="model.situation_comment"
            v-validate="{ required: isRequired('situation_comment') }"
            :state="state(`${prefix}_${index}_situation_comment`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_situation_comment`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('company_type_id')">
      <div class="col-md-6">
        <label>
          Type de société
          <span v-show="isRequired('company_type_id')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_company_type_id`"
            :disabled="readonly"
            v-model="model.company_type_id"
            :options="companyTypes"
            size="sm"
            :state="state(`${prefix}_${index}_company_type_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_company_type_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1">
      <div class="col-md-6" v-if="isPresent('tax_revenue_year_minus_one')">
        <label>
          Revenu fiscal de référence (année en cours moins 1)
          <span v-show="isRequired('tax_revenue_year_minus_one')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_tax_revenue_year_minus_one`"
            type="number"
            class="form-control form-control-sm"
            v-model="model.tax_revenue_year_minus_one"
            v-validate="{ required: isRequired('tax_revenue_year_minus_one') }"
            :state="state(`${prefix}_${index}_tax_revenue_year_minus_one`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_tax_revenue_year_minus_one`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-6" v-if="isPresent('tax_revenue_year_minus_two')">
        <label>
          Revenu fiscal de référence (année en cours moins 2)
          <span v-show="isRequired('tax_revenue_year_minus_two')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_tax_revenue_year_minus_two`"
            type="number"
            class="form-control form-control-sm"
            v-model="model.tax_revenue_year_minus_two"
            v-validate="{ required: isRequired('tax_revenue_year_minus_two') }"
            :state="state(`${prefix}_${index}_tax_revenue_year_minus_two`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_tax_revenue_year_minus_two`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('tenant_relationship_id')">
      <div class="col-md-6">
        <label>
          Lien de parenté
          <span v-show="isRequired('tenant_relationship_id')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_tenant_relationship_id`"
            :disabled="readonly"
            v-model="model.tenant_relationship_id"
            :options="relationships"
            size="sm"
            v-validate="{ required: isRequired('tenant_relationship_id') }"
            :state="state(`${prefix}_${index}_tenant_relationship_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_tenant_relationship_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('marital_status_id')">
      <div class="col-md-6">
        <label>
          Régime matrimonial
          <span v-show="isRequired('marital_status_id')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-select
            :name="`${prefix}_${index}_marital_status_id`"
            :disabled="readonly"
            v-model="model.marital_status_id"
            :options="maritalStatuses"
            size="sm"
            v-validate="{ required: isRequired('marital_status_id') }"
            :state="state(`${prefix}_${index}_marital_status_id`)">
          </b-form-select>
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_marital_status_id`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-1" v-if="isPresent('number_of_dependents')">
      <div class="col-md-6">
        <label>
          Nombre de personnes à charge
          <span v-show="isRequired('number_of_dependents')">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_number_of_dependents`"
            type="number"
            class="form-control form-control-sm"
            v-model="model.number_of_dependents"
            v-validate="{ required: isRequired('number_of_dependents') }"
            :state="state(`${prefix}_${index}_number_of_dependents`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_number_of_dependents`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-3" v-if="isPresent('accept_sepa')">
      <div class="col-md-12">
        <div class="position-relative form-group">
          <b-form-checkbox :disabled="readonly" v-model="model.accept_sepa">
            J'autorise Arpej à me prélever et je saisis ci-dessous mes coordonnées bancaires
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="form-row mt-3" v-if="model.accept_sepa">
      <div class="col-md-4">
        <label>Numéro IBAN*</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_bank_iban`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.bank_iban"
            v-validate="{ required: isRequired('bank_iban') }"
            :state="state(`${prefix}_${index}_bank_iban`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_bank_iban`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-4">
        <label>Code BIC*</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_bank_bic`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.bank_bic"
            v-validate="{ required: isRequired('bank_bic') }"
            :state="state(`${prefix}_${index}_bank_bic`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_bank_bic`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-4">
        <label>Nom de la banque*</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            :name="`${prefix}_${index}_bank_name`"
            type="text"
            class="form-control form-control-sm"
            v-model="model.bank_name"
            v-validate="{ required: isRequired('bank_name') }"
            :state="state(`${prefix}_${index}_bank_name`)"
          />
          <b-form-invalid-feedback>
            {{ errors.first(`${prefix}_${index}_bank_name`) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  export default {
    mixins: [
      Form
    ],
    props: {
      index: Number,
      allPeople: Array,
      selectedPeople: Array,
      situations: Array,
      countries: Array,
      nationalities: Array,
      familySituations: Array,
      studies: Array,
      schoolTypes: Array,
      studySectors: Array,
      activitySectors: Array,
      employmentContracts: Array,
      relationships: Array,
      maritalStatuses: Array,
      companyTypes: Array,
      fields: Object,
      contractSituations: Array,
      schoolsUrl: String
    },
    computed: {
      prefix() {
        return _.lowerCase(this.model.type)
      },
      typeTitle() {
        switch(this.model.type) {
          case 'Tenant':
            return 'locataire'
          case 'Guarantor':
            return 'garant'
        }
      },
      situationTitle() {
        return _.find(this.situations, { value: this.model.situation }).text
      },
      availablePeople() {
        return _.reject(this.allPeople, (person) => {
          return person.id != this.model.id && _.includes(_.map(this.selectedPeople, 'id'), person.id)
        })
      }
    },
    methods: {
      isPresent(field) {
        return this.isRequired(field) || this.recordPersonField(field) == 'present'
      },
      isRequired(field) {
        switch(field) {
          case 'bank_iban':
          case 'bank_bic':
          case 'bank_name':
            return this.model.accept_sepa
          default:
            return this.recordPersonField(field) == 'required'
        }
      },
      recordPersonField(field) {
        return this.fields[this.model.situation][field]
      },
      setPersonId(id) {
        if (id == null) {
          this.model.id = null
          this.model._create = true
        } else {
          _.assign(this.model, _.find(this.allPeople, { id }))
          this.model._create = false
        }
        this.$emit('input', this.model)
      }
    }
  }
</script>
