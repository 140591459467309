<template>
  <div class="content">
    <tabs
      :tabs="tabs"
      :currentTab="currentTab"
      wrapper-class="body-tabs shadow-tabs"
      tab-class="tab-item"
      tab-active-class="tab-item-active"
      line-class="tab-item-line"
      @onClick="changeTab"
    />
    <div v-if="currentTab === 'tab_table'">
      <b-row>
        <b-col md="12">
          <b-card class="mb-3" no-body>
            <div class="card-header">
              <i class="header-icon lnr-list icon-gradient bg-heavy-rain"></i>
              Liste des lots
              <div class="btn-actions-pane-right">
                <b-dropdown variant="outline-secondary" right :text="selectionLabel" :disabled="selection.length == 0">
                  <b-dropdown-item :href="exportUrl()">
                    <i class="text-muted far fa-fw fa-file-excel mr-2"></i>
                    Exporter (Excel)
                  </b-dropdown-item>
                  <b-dropdown-item-button @click="$refs.statusModal.show()" v-if="admin">
                    <i class="text-muted far fa-fw fa-edit mr-2"></i>
                    Changer l'état...
                  </b-dropdown-item-button>
                  <b-dropdown-divider />
                  <b-dropdown-item-button @click="clearSelection()">
                    Tout désélectionner
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>
            <div class="card-body">
              <div class="mb-3 widget-content bg-light" v-show="activeFiltersCount > 0" >
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-subheading">
                      <div class="badge badge-pill badge-secondary">
                        {{ activeFiltersCount }}
                      </div>
                      Filtres actifs
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <button type="button" class="btn ml-3 btn-sm btn-icon btn-transition btn-outline-info" @click="changeTab('tab_filters')">
                      <i class="pe-7s-filter btn-icon-wrapper"></i>
                      Modifier
                    </button>
                    <button type="button" class="btn ml-3 btn-sm btn-icon btn-transition btn-outline-danger" @click="clear">
                      <i class="pe-7s-trash btn-icon-wrapper"></i>
                      Effacer
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6">
                  <h6 class="pt-1">
                    <div class="badge badge-pill badge-primary">
                      {{ count }} / {{ total }}
                    </div>
                    <span class="ml-1 align-middle">
                      Résultats
                    </span>
                  </h6>
                </div>
                <div class="col-md-5">
                  <b-form-group>
                    <b-input-group>
                      <b-form-input v-model.trim="search" placeholder="Recherchez par mot clé" />
                      <b-input-group-append>
                        <b-btn class="btn-sm" :disabled="!search" @click="search = ''">
                          Effacer
                        </b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-md-1">
                  <b-form-group>
                    <b-form-select :options="pagination_options" v-model="per" />
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-table
                    class="table-responsive border-0"
                    show-empty
                    striped
                    hover
                    bordered
                    no-local-sorting
                    stacked="md"
                    :items="items"
                    :busy="loading"
                    :fields="fields"
                    @sort-changed="sortChanged"
                    empty-text="Aucun lot trouvé">
                    <div slot="table-busy" class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Chargement...</strong>
                    </div>
                    <template slot="HEAD_check">
                      <b-form-checkbox v-model="allSelected" @change="toggleAll" />
                    </template>
                    <template slot="check" slot-scope="data">
                      <b-form-checkbox :id="`selection-${data.item.id}`" v-model="selection" :value="data.item" />
                    </template>
                    <template slot="residence_title" slot-scope="data">
                      <a :href="'/admin/residences/'+data.item.residence_id">{{data.item.residence_title}}</a>
                    </template>
                    <template slot="unit_type_title" slot-scope="data">
                      <a :href="'/admin/unit_types/'+data.item.unit_type_id">{{data.item.unit_type_title}}</a>
                    </template>
                    <template slot="number" slot-scope="data">
                      <a :href="'/admin/units/'+data.item.id">{{data.item.number}}</a>
                    </template>
                    <template slot="actions" slot-scope="data">
                      <b-button :href="data.item.show_url" class="btn-sm btn-icon btn-icon-only" variant="outline-info" v-b-tooltip.hover title="Voir ce lot">
                        <i class="pe-7s-look btn-icon-wrapper"></i>
                      </b-button>
                      <b-button v-if="data.item.edit_url" :href="data.item.edit_url" class="btn-sm btn-icon btn-icon-only" variant="outline-info" v-b-tooltip.hover title="Modifier ce lot">
                        <i class="pe-7s-pen btn-icon-wrapper"></i>
                      </b-button>
                      <delete v-if="data.item.destroy_url" :href="data.item.destroy_url" tooltip="Supprimer ce lot" @deleted="fetch">
                        <i class="pe-7s-trash btn-icon-wrapper"></i>
                      </delete>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
            <b-pagination
              ref="pagination"
              size="md"
              align="center"
              :total-rows="count"
              :per-page="per"
              v-model="page" />
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-if="currentTab === 'tab_filters'">
      <b-row>
        <b-col md="12">
          <b-card class="mb-3" no-body>
            <form @submit.prevent="applyFilters">
              <div class="card-header">
                <i class="header-icon lnr-magic-wand icon-gradient bg-heavy-rain"></i>
                Filtrer les résultats
              </div>
              <div class="card-body">
                <h5 class="card-title">Contexte</h5>
                <div class="form-row">
                  <div class="col-md-12">
                    <label>Résidence</label>
                    <b-form-select v-model="filters.residence_id" :options="residences" size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez une résidence</option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Type</label>
                    <b-form-select v-model="filters.type" :options="units" size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez un type</option>
                      </template>
                    </b-form-select>
                  </div>
                  <div class="col-md-4">
                    <label>Type de lot</label>
                    <b-form-select v-model="filters.unit_type_id" :options="unit_types" size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez un type de lot</option>
                      </template>
                    </b-form-select>
                  </div>
                  <div class="col-md-4">
                    <label>Disponibilité</label>
                    <b-form-select v-model="filters.status" :options="statuses" size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez une disponibilité</option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">Informations</h5>
                <div class="form-row">
                  <div class="col-md-4">
                    <label :class="{'text-success': filters.number}">N° de lot</label>
                    <input type="text" class="form-control form-control-sm" v-model="filters.number">
                  </div>
                  <div class="col-md-4">
                    <label :class="{'text-success': filters.building_number}">N° de bâtiment</label>
                    <input type="text" class="form-control form-control-sm" v-model="filters.building_number">
                  </div>
                  <div class="col-md-4">
                    <label :class="{'text-success': filters.door_number}">N° de porte / place</label>
                    <input type="text" class="form-control form-control-sm" v-model="filters.door_number">
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-12">
                    <label class="mb-3">Surface habitable (appartement)</label>
                    <Slider class="mt-4 mx-4" v-bind="surfaceOptions" v-model="surfaceOptions.value" @callback="setSurface" key="surfaces" />
                  </div>
                  <div class="col-md-12 mt-2">
                    <label class="mb-3">Loyer TTC & TCC</label>
                    <Slider class="mt-4 mx-4" v-bind="rentAmountOptions" v-model="rentAmountOptions.value" @callback="setRentAmount" key="rent_amounts" />
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">Enregistrement</h5>
                <div class="form-row">
                  <datepicker type="create" v-model="filters.created_at"></datepicker>
                  <div class="col-md-8">
                    <label>Créé par</label>
                    <div class="position-relative form-group">
                      <select class="form-control form-control-sm">
                        <option>TODO</option>
                      </select>
                      <small class="form-text text-muted">
                        Le créateur de l'enregistrement
                      </small>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <datepicker type="update" v-model="filters.updated_at"></datepicker>
                  <div class="col-md-8">
                    <label>Modifié par</label>
                    <div class="position-relative form-group">
                      <select class="form-control form-control-sm">
                        <option>TODO</option>
                      </select>
                      <small class="form-text text-muted">
                        Le dernier éditeur de l'enregistrement
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-block text-right card-footer">
                <button type="button" data-toggle="button" aria-pressed="true" class="btn btn-lg btn-link active float-left" @click="changeTab('tab_table')">
                  <i class="pe-7s-back"></i>
                  Retour à la liste
                </button>
                <button type="button" class="btn mr-2 btn-transition btn-outline-danger btn-lg" @click="clear">
                  Supprimer les filtres
                </button>
                <button class="btn btn-hover-shine btn-transition btn-primary btn-lg" type="submit">
                  Appliquer les filtres
                </button>
              </div>
            </form>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-modal ref="statusModal" centered hide-footer title="Changement d'état">
      <b-form>
        <b-form-group label="Nouvel état des lots :">
          <b-form-select
            v-model="status"
            :options="statuses">
          </b-form-select>
        </b-form-group>
      </b-form>
      <hr class="my-4">
      <div class="text-right">
        <b-button variant="outline" @click="$refs['statusModal'].hide()">
          Annuler
        </b-button>
        <b-button variant="primary" :disabled="processing" @click="changeStatus">
          <span v-show="!processing">Modifier {{ selection.length }} lots(s)</span>
          <span v-show="processing">Patientez...</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import Slider from 'vue-slider-component'
  import axios from 'axios'
  import Swal from 'sweetalert'
  import Index from '../mixins/Index'

  const fields = [
    {
      key: "check",
      label: "",
      sortable: false,
      tdClass: 'fit text-center'
    },
    {
      key: "id",
      label: "Id",
      sortable: true,
      tdClass: 'fit'
    },
    {
      key: "residence_title",
      label: "Résidence",
      sortable: false,
      thStyle: { width: '40%' }
    },
    {
      key: "unit_type_title",
      label: "Type de lot",
      sortable: false,
      tdClass: 'fit',
      thClass: 'fit'
    },
    {
      key: "number",
      label: "N° de logement",
      sortable: true
    },
    {
      key: "building_number",
      label: "N° de bâtiment",
      sortable: true,
      tdClass: 'fit'
    },
    {
      key: "floor_number",
      label: "Étage",
      sortable: false,
      tdClass: 'fit'
    },
    {
      key: "door_number",
      label: "N° de porte / place",
      sortable: true,
      tdClass: 'fit',
      thClass: 'fit'
    },
    {
      key: "status",
      label: "Disponibilité",
      sortable: true,
      tdClass: 'fit'
    },
    {
      key: "surface",
      label: "Surface habitable",
      sortable: false,
      tdClass: 'fit'
    },
    {
      key: "rent_amount",
      label: "Loyer TTC & TCC",
      sortable: false,
      tdClass: 'fit',
      thClass: 'fit'
    },
    {
      key: "actions",
      label: '',
      sortable: false,
      tdClass: 'fit'
    }
  ]

  export default {
    mixins: [
      Index
    ],
    components: {
      Slider
    },
    props: {
      admin: Boolean,
      residences: {
        type: Array,
        default: () => []
      },
      unit_types: {
        type: Array,
        default: () => []
      },
      statuses: {
        type: Array,
        default: () => []
      },
      surfaces: {
        type: Object,
        default: () => {}
      },
      rent_amounts: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        fields,
        units: [
          { value: 'Accommodation', text: 'Appartement' },
          { value: 'ParkingSpace', text: 'Place de parking' }
        ],
        surfaceOptions: {
          value: this.surfaces.value,
          data: this.surfaces.range,
          height: 4,
          piecewise: true,
          processClass: 'bg-primary',
          tooltipClass: 'bg-primary border-primary',
          formatter: value => `${value} m2`
        },
        rentAmountOptions: {
          value: this.rent_amounts.value,
          data: this.rent_amounts.range,
          height: 4,
          piecewise: true,
          processClass: 'bg-primary',
          tooltipClass: 'bg-primary border-primary',
          formatter: value => `${value} €`
        },
        status: this.statuses[0].value,
        processing: false
      }
    },
    computed: {
      selectionLabel() {
        switch(this.selection.length) {
          case 0:
            return 'Aucune sélection'
          case 1:
            return '1 lot sélectionné'
          default:
            return `${this.selection.length} lots sélectionnés`
        }
      }
    },
    methods: {
      setSurface(values) {
        this.$set(this.filters, 'surface_min', values[0])
        this.$set(this.filters, 'surface_max', values[1])
      },
      setRentAmount(values) {
        this.$set(this.filters, 'rent_amount_min', values[0])
        this.$set(this.filters, 'rent_amount_max', values[1])
      },
      clear() {
        this.clearFilters()
        this.surfaceOptions.value = this.surfaces.value
        this.rentAmountOptions.value = this.rent_amounts.value
      },
      changeStatus() {
        if (this.processing) return
        this.processing = true
        axios.patch(this.url, {
          ids: this.selection.map(unit => unit.id),
          unit: {
            status: this.status
          }
        }).then((response) => {
          this.fetch()
          this.$refs.statusModal.hide()
          this.processing = false
          this.clearSelection()
        })
      }
    }
  }
</script>
