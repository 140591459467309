<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} un tarif
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab
                title="Général"
                active
                @click="submitEnabled = !readonly">
                <h5 class="card-title">CONTEXTE</h5>
                <div class="form-row">
                  <div class="col-md-12">
                    <label>Nom de l'organisme*</label>
                    <div class="position-relative form-group">
                      <b-form-select
                        name="organization_id"
                        :disabled="readonly"
                        :options="organizations"
                        v-model="model.organization_id"
                        v-validate="'required'"
                        :state="state('organization_id')"
                        size="sm" />
                      <b-form-invalid-feedback>
                        {{ errors.first('organization_id') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <hr class="mt-3">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <h5 class="card-title">INFORMATIONS</h5>
                    <label>Intitulé*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="pricing_title"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.title"
                      v-validate="'required|uniqueness:title'"
                      data-vv-delay="500"
                      :state="state('pricing_title')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('pricing_title') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-4">
                    <label :class="{'text-success': model.started_at}">Commence le</label>
                    <div class="position-relative form-group">
                      <DatePicker
                        v-model="model.started_at"
                        lang="fr"
                        :disabled="readonly"
                        :not-after="notAfterFinished"
                        format="DD/MM/YYYY" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label :class="{'text-success': model.finished_at}">Se termine le</label>
                    <div class="position-relative form-group">
                      <DatePicker
                        v-model="model.finished_at"
                        lang="fr"
                        :disabled="readonly"
                        :not-before="notBeforeStarted"
                        format="DD/MM/YYYY" />
                    </div>
                  </div>
                </div>
                <item-information :value="value" />
              </b-tab>
              <b-tab
                title="Import / Export"
                @click="submitEnabled = !readonly">
                <b-alert :show="model.importing" variant="info">
                  <i class="fas fa-info-circle"></i>
                  Un import est en cours.
                </b-alert>
                <h5 class="card-title">Export</h5>
                <div class="form-row">
                  <div class="col-md-4">
                    <label>
                      Exporter les <b>{{ model.unit_count }}</b> lots du tarif <b>{{ model.title}}</b> au format CSV
                    </label>
                    <b-button variant="outline-info" @click="exportCSV()">Exporter</b-button>
                  </div>
                </div>
                <hr class="mt-3">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <h5 class="card-title">Import</h5>
                    <label>Importer des lots</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <b-form-file
                      placeholder="Choisissez un fichier..."
                      browse-text="Parcourir"
                      drop-placeholder="Déposez votre fichier ici..."
                      accept=".csv"
                      v-model="csvFile"
                      :state="Boolean(csvFile)"
                    ></b-form-file>
                    <b-form-text>
                      Sélectionner votre fichier au format CSV (UTF-8, séparateur ";", entêtes de colonne), <a href="https://drive.google.com/file/d/1ZmWJGFuE7N8ZRr-ZawyEK-7XwunMIWop/view" target="_blank">exemple</a>.
                    </b-form-text>
                  </div>
                  <div class="col-md-4">
                    <b-button variant="outline-info" @click="importCSV()" :disabled="!csvFile">Importer</b-button>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit">
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  import DatePicker from 'vue2-datepicker'
  import Form from '../mixins/Form'
  import UniquenessValidator from '../mixins/UniquenessValidator'

  export default {
    components: {
      DatePicker
    },
    props: {
      organizations: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        params: {
        },
        csvFile: null
      }
    },
    computed: {
      notBeforeStarted() {
        return this.model.started_at
      },
      notAfterFinished() {
        return this.model.finished_at
      }
    },
    created() {
      this.params = {
        organization_id: this.model.organization_id
      }
    },
    mixins: [
      Form,
      UniquenessValidator
    ],
    watch: {
      model: {
        handler(model) {
          this.$set(this.params, 'organization_id', model.organization_id)
          this.$validator.validate('pricing_title')
        },
        deep: true
      }
    },
    methods: {
      async exportCSV() {
        try {
          const response = await axios.post(this.model.export_url)
          alert("L'export CSV vous sera adressé par e-mail dans les prochaines minutes.")
          document.location = response.headers.location
        } catch {
          alert("Une erreur est survenue. Veuillez réessayer ultérieurement.")
        }
      },
      async importCSV() {
        try {
          let formData = new FormData()
          formData.append('unit_import[csv]', this.csvFile)
          const response = await axios.post(
            this.model.import_url,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          document.location = response.headers.location
        } catch(error) {
          if(error.response && error.response.data && error.response.data.errors) {
            alert(error.response.data.errors.join('. '))
          } else {
            alert("Une erreur est survenue. Veuillez réessayer ultérieurement.")
          }
        }
      }
    }
  }
</script>
