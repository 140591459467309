<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} un client
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab title="Général" active>
                <h5 class="card-title">Contact</h5>
                <div class="form-row">
                  <div class="col-md-4">
                    <label>Civilité*</label>
                    <b-form-group class="position-relative form-group">
                      <b-form-radio
                        :disabled="readonly"
                        id="customer_gender-male"
                        class="custom-radio custom-control custom-control-inline"
                        name="customer_gender"
                        v-model="model.gender"
                        :value="'male'">
                        Monsieur
                      </b-form-radio>
                      <b-form-radio
                        :disabled="readonly"
                        id="customer_gender-female"
                        class="custom-radio custom-control custom-control-inline"
                        name="customer_gender"
                        v-model="model.gender"
                        :value="'female'">
                        Madame
                      </b-form-radio>
                    </b-form-group>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>Prénom*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="customer_first_name"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.first_name"
                      v-validate="'required'"
                      :state="state('customer_first_name')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('customer_first_name') }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6">
                    <label>Nom*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="customer_last_name"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.last_name"
                      v-validate="'required'"
                      :state="state('customer_last_name')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('customer_last_name') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>Email*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="customer_email"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.email"
                      v-validate="emailValidation"
                      data-vv-delay="500"
                      :state="state('customer_email')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('customer_email') }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6">
                    <label>Téléphone*</label>
                    <PhoneInput
                      v-model="model.phone_number"
                      :code=" model.phone_country_code"
                      name="phone_number"
                      @code="setCountryCode($event, 'phone_country_code')"
                      :required="true"
                      :readonly="readonly" />
                    <b-form-invalid-feedback class="d-block">
                      {{ messages[0] }}
                    </b-form-invalid-feedback>
                    <b-form-text>
                      Au format international : +33 0 00 00 00 00
                    </b-form-text>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div v-if="readonly"></div>
                  <div v-else-if="model.id" class="col-md-4">
                    <label>Modifier le mot de passe</label>
                    <b-form-input
                      :readonly="readonly"
                      name="customer_password"
                      size="sm"
                      v-model="model.password"
                      v-validate="'min:6'"
                      :state="state('customer_password')" />
                    <b-form-text>
                      Au moins 6 caractères
                    </b-form-text>
                  </div>
                  <div v-else class="col-md-4">
                    <label>Mot de passe*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="customer_password"
                      size="sm"
                      v-model="model.password"
                      v-validate="'required|min:6'"
                      :state="state('customer_password')" />
                    <b-form-text>
                       Au moins 6 caractères
                    </b-form-text>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-12">
                    <label>Compte confirmé</label>
                    <b-form-radio-group
                      :disabled="readonly"
                      :checked="model.confirmed"
                      @change="confirmUser"
                      :options="[{ text: 'Oui', value: true }, { text: 'Non', value: false }]" />
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">Suspension</h5>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>Compte désactivé le</label>
                    <div class="form-group">
                      <date-picker
                        :disabled="readonly"
                        name="customer_suspended_at"
                        v-model="model.suspended_at"
                        @clear="(clear)=>{model.suspended_at=''}"
                        lang="fr"
                        format="DD/MM/YYYY">
                      </date-picker>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label>Raison de la désactivation</label>
                    <b-form-select
                     :disabled="readonly"
                      v-model="model.suspension_reason_id"
                      :options="suspension_reasons"
                      size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez une raison</option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <hr class="my-3">
                <h5 class="card-title">INFORMATIONS</h5>
                <div class="form-row">
                  <div class="col-md-12">
                    <label>
                      Mot de passe réinitialisé le
                      <span class="mb-2 mr-2 badge badge-light" v-if="model.reset_password_sent_at">{{ model.reset_password_sent_at | moment('DD/MM/YYYY à HH:mm:ss') }}</span>
                      <span class="mb-2 mr-2 badge badge-light" v-else>jamais</span>
                    </label>
                  </div>
                  <div class="col-md-12">
                    <label>
                      Se souvenir de moi enregistré le
                      <span class="mb-2 mr-2 badge badge-light" v-if="model.remember_created_at">{{ model.remember_created_at | moment('DD/MM/YYYY à HH:mm:ss') }}</span>
                      <span class="mb-2 mr-2 badge badge-light" v-else>jamais</span>
                    </label>
                  </div>
                  <div class="col-md-12">
                    <label>
                      Nombre de connexions
                      <span class="mb-2 mr-2 badge badge-light">{{ model.sign_in_count }}</span>
                    </label>
                  </div>
                  <div class="col-md-12">
                    <label>
                      Connexion en cours depuis le
                      <span class="mb-2 mr-2 badge badge-light">{{ model.current_sign_in_at | moment('DD/MM/YYYY à HH:mm:ss') }}</span>
                    </label>
                  </div>
                  <div class="col-md-12">
                    <label>
                      Dernière connexion le
                      <span class="mb-2 mr-2 badge badge-light" v-if="model.last_sign_in_at">{{ model.last_sign_in_at | moment('DD/MM/YYYY à HH:mm:ss') }}</span>
                      <span class="mb-2 mr-2 badge badge-light" v-else>jamais</span>
                    </label>
                  </div>
                  <div class="col-md-12">
                    <label>
                      Compte confirmé le
                      <span class="mb-2 mr-2 badge badge-light" v-if="model.confirmed_at">{{ model.confirmed_at | moment('DD/MM/YYYY à HH:mm:ss') }}</span>
                      <span class="mb-2 mr-2 badge badge-light" v-else>jamais</span>
                    </label>
                  </div>
                  <div class="col-md-12">
                    <label>
                      Confirmation envoyée le
                      <span class="mb-2 mr-2 badge badge-light" v-if="model.confirmation_sent_at">{{ model.confirmation_sent_at | moment('DD/MM/YYYY à HH:mm:ss') }}</span>
                      <span class="mb-2 mr-2 badge badge-light" v-else>jamais</span>
                    </label>
                  </div>
                </div>
                <item-information :value="value" />
              </b-tab>
              <b-tab title="Dossier(s) lié(s)">
                <div class="form-row mt-3">
                  <div class="col-md-12">
                    <AssociatedRecord
                      v-model="model.records" />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit()"
              >
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import UniquenessValidator from '../mixins/UniquenessValidator'
  import DatePicker from 'vue2-datepicker'
  import AssociatedRecord from '../base/AssociatedRecord'

  export default {
    mixins: [
      Form,
      UniquenessValidator
    ],
    components: {
      DatePicker,
      AssociatedRecord
    },
    props: {
      suspension_reasons: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      emailValidation() {
        return {
          required: true,
          uniqueness: 'email',
          email: true
        }
      }
    },
    methods: {
      confirmUser(value) {
        this.model.confirmed_at = value ? new Date : 'null'
      }
    }
  }
</script>
