<template>
  <div>
    <h5 class="card-title">DESCRIPTION</h5>
    <div class="form-row">
      <div class="col-md-3">
        <label>N° de logement*</label>
        <b-form-input
          :readonly="readonly"
          name="unit_number"
          size="sm"
          placeholder="AR"
          v-model="model.number"
          v-validate="'required|uniqueness:number'"
          data-vv-delay="500"
          :state="state('unit_number')" />
        <b-form-invalid-feedback>
          {{ errors.first('unit_number') }}
        </b-form-invalid-feedback>
        <b-form-text>
          Commençant par AR...
        </b-form-text>
      </div>
      <div class="col-md-3">
        <label>N° de porte</label>
        <b-form-input :readonly="readonly" size="sm" v-model="model.door_number" />
      </div>
      <div class="col-md-3">
        <label>N° de bâtiment</label>
        <b-form-input :readonly="readonly" size="sm" v-model="model.building_number" />
      </div>
      <div class="col-md-3">
        <label>Étage</label>
        <b-form-select
          :disabled="readonly"
          v-model="model.floor_number_id"
          :options="floors"
          size="sm">
          <template slot="first">
            <option :value="null" disabled>Choisissez un étage</option>
          </template>
        </b-form-select>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <label>Adresse</label>
        <b-form-input :readonly="true" size="sm" v-model="model.address" />
      </div>
      <div class="col-md-3">
        <label>Code postal</label>
        <b-form-input :readonly="true" size="sm" v-model="model.zip_code" />
      </div>
      <div class="col-md-3">
        <label>Ville</label>
        <b-form-input :readonly="true" size="sm" v-model="model.city" />
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import UniquenessValidator from '../mixins/UniquenessValidator'

  export default {
    mixins: [
      Form,
      UniquenessValidator
    ],
    props: {
      accommodation: {
        type: Boolean,
        default: false
      },
      floors: {
        type: Array,
        required: true
      },
      residence_id: {
        type: Number
      }
    },
    data() {
      return {
        params: {}
      }
    },
    created() {
      this.$set(this.params, 'residence_id', this.residence_id)
    },
    watch: {
      residence_id(value) {
        this.$set(this.params, 'residence_id', value)
        this.$validator.validate('unit_number')
      }
    }
  }
</script>
