<template>
  <div class="d-flex">
    <div class="header-btn-lg pr-0">
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-lg" variant="link" no-caret right>
              <span slot="button-content">
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" class="rounded-circle" src="https://via.placeholder.com/64" alt="">
                  <font-awesome-icon class="ml-1" icon="angle-down"/>
                </div>
              </span>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-info">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                          <div class="widget-content-left mr-3">
                            <img width="42" class="rounded-circle" src="https://via.placeholder.com/64" alt="">
                          </div>
                          <div class="widget-content-left">
                            <div class="widget-heading">
                              {{ user.first_name }} {{ user.last_name }}
                            </div>
                            <div class="widget-subheading opacity-8">
                              {{ role }}
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="scroll-area-xs">
                <ul class="nav flex-column">
                  <li class="nav-item-header nav-item">Activité</li>
                  <li class="nav-item"><a :href="edit_url" class="nav-link">Mon compte</a></li>
                  <li class="nav-item"><a class="nav-link" @click="logout">Déconnexion</a></li>
                </ul>
              </div>
            </b-dropdown>
          </div>
          <div class="widget-content-left  ml-3 header-user-info">
            <div class="widget-heading">{{ user.first_name }} {{ user.last_name }}</div>
            <div class="widget-subheading">{{ role }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
  import axios from 'axios'

  library.add(faAngleDown)

  export default {
    components: {
      'font-awesome-icon': FontAwesomeIcon,
    },
    props: {
      user: {
        type: Object,
        required: true
      },
      role: {
        type: String,
        required: true
      },
      edit_url: {
        type: String,
        required: true
      },
      logout_url: {
        type: String,
        required: true
      }
    },
    methods: {
      logout() {
        axios.delete(this.logout_url).then(resp => {
          document.location = '/users/sign_in'
        })
      }
    }
  }
</script>
