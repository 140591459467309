<template>
  <div>
    <h5 class="card-title">
      État du dossier
    </h5>
    <b-alert variant="info" :show="model.state == 'granted_accepted'">
      Le dossier ne peut pas passer dans l'état
      <strong>Contrats envoyés par courrier en attente de signature</strong>
      ou
      <strong>Contrats envoyés en attente de signature électronique</strong>
      si aucune situation pour le contrat du locataire principal n'est sélectionnée.
    </b-alert>
    <b-alert variant="info" :show="model.desired_started_at == null">
      Certains états ne sont pas proposés ici car la
      <strong>date d'entrée souhaitée</strong>
      n'a pas été renseignée.
    </b-alert>
    <b-alert variant="info" :show="model.signed_document_model_ids.length == 0">
      Le dossier ne peut pas passer dans l'état
      <strong>Contrats envoyés en attente de signature électronique</strong>
      si aucun contrat n'est sélectionné.
    </b-alert>
    <div class="form-row">
      <div class="col-md-12">
        <label>Statut</label>
        <div class="position-relative form-group">
          <b-form-select
            name="record_status"
            disabled
            v-model="model.status"
            :options="statuses"
            size="sm">
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12">
        <label>État</label>
        <div class="position-relative form-group">
          <b-form-select
            name="record_state"
            :disabled="readonly"
            v-model="model.state"
            size="sm">
            <optgroup v-for="status in statuses" :label="status.text" v-if="statesFromStatus(status).length > 0">
              <option v-for="state in statesFromStatus(status)" :value="state.value" :disabled="isDisabledState(state.value)">
                {{ state.text }}
              </option>
            </optgroup>
          </b-form-select>
          <small v-if="readonly" class="form-text text-muted">
            Basculez en mode lecture pour <a :href="model.show_url">mettre à jour l'état</a>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'

  export default {
    mixins: [
      Form
    ],
    props: {
      statuses: Array,
      states: Array
    },
    methods: {
      isDisabledState(state) {
        return _.includes(
          [
            (this.model.state == 'created' ? 'pending' : null),
            'granted_waiting_price_acceptation',
            'granted_digitaly_signed',
            'refused_digital_signature_expired',
            'refused_digital_signature_refused'
          ],
          state
        )
      },
      statesFromStatus(status) {
        return _.filter(this.states, (state) => {
          return this.statusFromState(state.value) == status.value
        })
      },
      statusFromState(state) {
        return _.split(state, '_')[0]
      }
    },
    watch: {
      'model.state': function(newState) {
        this.model.status = this.statusFromState(newState)
      }
    }
  }
</script>
