<template>
  <div>
    <h5 class="card-title">Photos</h5>
    <div v-if="onPatch">
      <div class="card card-border border-info mb-3" v-if="pictures.length > 0" v-for="picture in orderedPictures" :key="'picture_' + picture.id">
        <div class="card-header-tab card-header">
          <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
            Modifier la photo <strong>&nbsp;{{ picture.id }}</strong>
          </div>
          <div class="btn-actions-pane-right">
            <button type="button" class="btn mr-3 btn-icon btn-icon-only btn-sm btn-danger" @click="deletePicture(picture.id)">
              <i class="pe-7s-trash btn-icon-wrapper"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <h5 class="card-title">Informations</h5>
          <div class="form-row">
            <div class="col-md-6" v-for="locale in $locales" :key="locale">
              <h5 class="card-title">{{ locale }}</h5>
              <div class="form-row mt-3">
                <div class="col-md-12">
                  <label>Titre*</label>
                  <b-form-input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="picture['title_' + locale]" />
                </div>
              </div>
              <div class="form-row mt-3">
                <div class="col-md-12">
                  <label>Description</label>
                  <b-form-textarea
                    class="form-control form-control-sm"
                    v-model="picture['caption_' + locale]" />
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <div class="position-relative form-group">
                <vue-dropzone
                  :ref="'edition' + picture.id"
                  :id="'edition' + picture.id"
                  :options="options"
                  :useCustomSlot="true">
                  <div class="dropzone-custom-content">
                    <h6>Glissez et déposez votre fichier</h6>
                    <div class="small">... ou cliquez pour sélectionner un fichier depuis votre ordinateur.</div>
                  </div>
                </vue-dropzone>
              </div>
            </div>
            <div class="col-2">
              <label>Position*</label>
              <b-form-input
                type="number"
                class="form-control form-control-sm"
                v-model="picture.position" />
            </div>
          </div>
        </div>
        <div class="d-block text-right card-footer">
          <button type="button" class="btn btn-sm btn-transition btn-outline-info" @click="updatePicture(picture)">
            Modifier
          </button>
        </div>
      </div>
      <div class="card card-border border-info mb-1" v-if="enableCreation">
        <div class="card-header-tab card-header">
          <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
            Ajouter une photo
          </div>
        </div>
        <div class="card-body">
          <h5 class="card-title">Informations</h5>
          <div class="form-row">
            <div class="col-md-6" v-for="locale in $locales" :key="locale">
              <h5 class="card-title">{{ locale }}</h5>
              <div class="form-row mt-3">
                <div class="col-md-12">
                  <label>Titre</label>
                  <b-form-input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="$data['title_' + locale]" />
                </div>
              </div>
              <div class="form-row mt-3">
                <div class="col-md-12">
                  <label>Description</label>
                  <b-form-textarea
                    class="form-control form-control-sm"
                    v-model="$data['caption_' + locale]" />
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <label>Charger votre photo</label>
              <div class="position-relative form-group">
                <vue-dropzone ref="creation"
                  :id="name"
                  :options="options"
                  :useCustomSlot="true"
                  @vdropzone-sending="appendNewPicture"
                  @vdropzone-success="clear">
                  <div class="dropzone-custom-content">
                    <h6>Glissez et déposez votre fichier</h6>
                    <div class="small">... ou cliquez pour sélectionner un fichier depuis votre ordinateur.</div>
                  </div>
                </vue-dropzone>
              </div>
            </div>
            <div class="col-2">
              <label>Position</label>
              <b-form-input
                type="number"
                class="form-control form-control-sm"
                v-model="position" />
            </div>
          </div>
        </div>
        <div class="d-block text-right card-footer">
          <button type="button" class="btn btn-sm btn-transition btn-outline-info" @click="createPicture">
            Ajouter
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-12 alert alert-secondary" v-else>
        <p class="mb-0">
          Veuillez enregistrer votre {{ title }} avant de pouvoir télécharger {{ pictureInfo }}.
        </p>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import VueDropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  export default {
    components: {
      VueDropzone
    },
    props: {
      action: {
        type: String
      },
      method: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      paramName: {
        type: String,
        default: '[pictures_attributes][]'
      },
      multiple: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: 'modèle'
      }
    },
    data() {
      return {
        options: {
          url: this.action,
          method: this.method,
          autoProcessQueue: false,
          addRemoveLinks: true,
          paramName: this.name + this.paramName + '[file]',
          maxFiles: 1,
          maxFilesize: 3,
          thumbnailWidth: 180,
          thumbnailHeight: 180,
          dictRemoveFile: 'Supprimer',
          dictFileTooBig: "L'image est trop lourde.\nMaximum : 3MiB.",
          dictMaxFilesExceeded: "Veuillez supprimer l'image en cours avant d'en sélectionner une nouvelle.",
          headers: {
            'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
          }
        },
        position: 0,
        pictures: []
      }
    },
    created() {
      this.setLocalizedAttr()
    },
    mounted() {
      this.onPatch && this.fetch()
    },
    computed: {
      onPatch() {
        return this.method === 'patch'
      },
      enableCreation() {
        return (this.pictures.length === 0 && !this.multiple) || this.multiple
      },
      pictureInfo() {
        return this.multiple ? 'vos photos' : 'votre photo'
      },
      orderedPictures() {
        return _.orderBy(this.pictures, 'position', 'asc')
      }
    },
    methods: {
      setLocalizedAttr() {
        this.$locales.forEach(locale => {
          this.$data['title_' + locale] = ''
          this.$data['caption_' + locale] = ''
        })
      },
      changeLocalizedAttr(formData, obj) {
        this.$locales.forEach(locale => {
          formData.append(this.name + this.paramName + `[title_${locale}]`, obj['title_' + locale])
          formData.append(this.name + this.paramName + `[caption_${locale}]`, obj['caption_' + locale])
        })
      },
      appendNewPicture(file, xhr, formData) {
        this.changeLocalizedAttr(formData, this.$data)
        formData.append(this.name + this.paramName + '[position]', this.position)
      },
      createPicture() {
        this.$refs.creation.processQueue()
      },
      updatePicture(picture) {
        let formData = new FormData
        formData.append(this.name + this.paramName + '[id]', picture.id)
        formData.append(this.name + this.paramName + '[position]', picture.position)
        this.changeLocalizedAttr(formData, picture)

        let changedPicture = this.$refs['edition' + picture.id][0].getAcceptedFiles()[0]
        if (changedPicture) {
          formData.append(this.name + this.paramName + '[file]', changedPicture)
        }
        axios.patch(this.action, formData).then(() => {
          this.fetch()
        })
      },
      deletePicture(id) {
        let formData = new FormData
        formData.append(this.name + this.paramName + '[id]', id)
        formData.append(this.name + this.paramName + '[_destroy]', 1)
        axios.patch(this.action, formData).then(() => {
          this.fetch()
        })
      },
      clear() {
        this.$refs.creation.removeAllFiles()
        this.position = 0
        this.setLocalizedAttr()
        this.fetch()
      },
      fetch() {
        this.pictures = []
        axios.get(this.action).then(response => {
          if (this.multiple && response.data.pictures) {
            this.pictures = response.data.pictures
          } else {
            response.data.picture && this.pictures.push(response.data.picture)
          }
        }).then(() => {
          this.pictures.forEach(picture => {
            let dropzone = this.$refs['edition' + picture.id][0]
            dropzone.manuallyAddFile(picture.file, picture.url)
          })
        })
      }
    }
  }
</script>

<style scoped>
.border-info {
  border-left-style: solid;
  border-left-width: 50px;
  border-radius: 0px;
}
</style>
