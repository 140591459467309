/* eslint no-console:0 */

import Vue from 'vue/dist/vue.esm'
import BootstrapVue from "bootstrap-vue"
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios'
import VeeValidate from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr'
import moment from 'vue-moment'

Vue.use(BootstrapVue)
Vue.use(VeeValidate, {
  fieldsBagName: 'inputs',
  inject: false,
  locale: 'fr',
  dictionary: {
    fr: {
      messages: {
        email: "L'email renseigné n'est pas au format valide.",
        min_value: fr.messages.min_value,
        max_value: fr.messages.max_value,
        required: "Ce champ est obligatoire.",
        regex: "Le format est incorrect.",
        url: fr.messages.url,
        length: (field, [length, max]) => {
          if (max) {
            return `Ce champ doit contenir entre ${length} et ${max} caractères.`
          }
          return `Ce champ doit contenir ${length} caractères.`
        },
        date_format: (field, [format]) => {
          return `La date doit être au format JJ/MM/AAAA.`
        }
      }
    }
  }
})
Vue.use(moment)

// Variables globales
Vue.prototype.$locales = JSON.parse(document.getElementById("application").dataset.locales)

// Layout & Common
import TopHeader from '../components/layout/TopHeader'
import Sidebar from '../components/layout/Sidebar'
Vue.component('top-header', TopHeader)
Vue.component('sidebar', Sidebar)

// Base components
import Delete from '../components/base/Delete'
import ItemInformation from '../components/base/ItemInformation'
import SearchSelect from '../components/base/SearchSelect'
Vue.component('delete', Delete)
Vue.component('item-information', ItemInformation)
Vue.component('search-select', SearchSelect)

// Brands
import BrandIndex from '../components/brands/BrandIndex'
import BrandForm from '../components/brands/BrandForm'
Vue.component('brand-index', BrandIndex)
Vue.component('brand-form', BrandForm)

// Customers
import CustomerIndex from '../components/customers/CustomerIndex'
import CustomerForm from '../components/customers/CustomerForm'
Vue.component('customer-form', CustomerForm)
Vue.component('customer-index', CustomerIndex)

// Lists
import ListIndex from '../components/lists/ListIndex'
import ListForm from '../components/lists/ListForm'
Vue.component('list-form', ListForm)
Vue.component('list-index', ListIndex)

// List Items
import ListItemIndex from '../components/list_items/ListItemIndex'
import ListItemForm from '../components/list_items/ListItemForm'
Vue.component('list-item-form', ListItemForm)
Vue.component('list-item-index', ListItemIndex)

// Organizations
import OrganizationIndex from '../components/organizations/OrganizationIndex'
import OrganizationForm from '../components/organizations/OrganizationForm'
Vue.component('organization-form', OrganizationForm)
Vue.component('organization-index', OrganizationIndex)

// Residences
import ResidenceIndex from '../components/residences/ResidenceIndex'
import ResidenceForm from '../components/residences/ResidenceForm'
Vue.component('residence-form', ResidenceForm)
Vue.component('residence-index', ResidenceIndex)

// Pricings
import PricingIndex from '../components/pricings/PricingIndex'
import PricingForm from '../components/pricings/PricingForm'
Vue.component('pricing-form', PricingForm)
Vue.component('pricing-index', PricingIndex)

// Records
import RecordIndex from '../components/records/RecordIndex'
import RecordForm from '../components/records/RecordForm'
Vue.component('record-form', RecordForm)
Vue.component('record-index', RecordIndex)

// Schools
import SchoolIndex from '../components/schools/SchoolIndex'
import SchoolForm from '../components/schools/SchoolForm'
Vue.component('school-form', SchoolForm)
Vue.component('school-index', SchoolIndex)

// Services
import ServiceIndex from '../components/services/ServiceIndex'
import ServiceForm from '../components/services/ServiceForm'
Vue.component('service-form', ServiceForm)
Vue.component('service-index', ServiceIndex)

// Units
import UnitIndex from '../components/units/UnitIndex'
import UnitForm from '../components/units/UnitForm'
Vue.component('unit-form', UnitForm)
Vue.component('unit-index', UnitIndex)

// Unit Types
import UnitTypeIndex from '../components/unit_types/UnitTypeIndex'
import UnitTypeForm from '../components/unit_types/UnitTypeForm'
Vue.component('unit-type-form', UnitTypeForm)
Vue.component('unit-type-index', UnitTypeIndex)

// Users
import UserIndex from '../components/users/UserIndex'
import UserForm from '../components/users/UserForm'
Vue.component('user-form', UserForm)
Vue.component('user-index', UserIndex)

// Signed Document Models
import SignedDocumentModelsIndex from '../components/signed_document_models/SignedDocumentModelIndex'
import SignedDocumentModelsForm from '../components/signed_document_models/SignedDocumentModelForm'
Vue.component('signed-document-model-form', SignedDocumentModelsForm)
Vue.component('signed-document-model-index', SignedDocumentModelsIndex)

// Offers
import OfferIndex from '../components/offers/OfferIndex'
import OfferForm from '../components/offers/OfferForm'
Vue.component('offer-form', OfferForm)
Vue.component('offer-index', OfferIndex)

// Partners
import PartnerIndex from '../components/partners/PartnerIndex'
import PartnerForm from '../components/partners/PartnerForm'
Vue.component('partner-form', PartnerForm)
Vue.component('partner-index', PartnerIndex)

// ManagerDashboard
import ManagerDashboard from '../components/home/ManagerDashboard'
Vue.component('manager-dashboard', ManagerDashboard)

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].getAttribute('content')

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#application'
  })
})
