<template>
  <div>
    <h5 class="card-title">Disponibilités</h5>
    <div class="form-row align-items-end mb-2"
      v-for="(availability, idx) in model.availabilities_attributes"
      v-show="!availability._destroy">
      <div class="col-md-2">
        <label>Année</label>
        <b-form-select
          disabled
          :options="yearOptions"
          size="sm"
          :name="'year_' + idx"
          v-model="availability.year"
          v-validate="'required'"
          :state="state('year_' + idx)">
        </b-form-select>
      </div>
      <div class="col-md-2">
        <label>Mois</label>
        <b-form-select
          disabled
          :options="monthOptions"
          size="sm"
          :name="'month_' + idx"
          v-model="availability.month"
          :data-vv-as="availability.month"
          v-validate="'required'"
          :state="state('month_' + idx)">
          <template slot="first">
            <option :value="null" disabled>Choisir...</option>
          </template>
        </b-form-select>
      </div>
      <div class="col-md-2">
        <label>Quantité</label>
        <b-form-input
          :readonly="readonly"
          type="number"
          min="0"
          size="sm"
          placeholder="0"
          :name="'quantity_' + idx"
          v-model="availability.quantity"
          v-validate="'required|min_value:0'"
          :state="state('quantity_' + idx)"
          @focus="$event.target.select()" />
      </div>
      <div class="col-md-3 mt-3">
        <b-button
          v-if="!readonly"
          size="sm"
          variant="outline-danger"
          class="btn-icon btn-icon-only btn-height"
          @click="removeAvailability(availability)">
          <i class="pe-7s-trash btn-icon-wrapper"></i>
        </b-button>
      </div>
      <b-form-invalid-feedback class="d-block">
        {{ errors.firstByRule('month_' + idx, 'month') }}
      </b-form-invalid-feedback>
    </div>
    <hr class="mt-3" v-if="!readonly">
    <div class="form-row align-items-end" v-if="!readonly">
      <div class="col-md-2">
        <label>Année</label>
        <b-form-select
          :options="yearOptionsNew"
          size="sm"
          name="year_new"
          v-model="current_new.year"
          data-vv-scope="availability"
          v-validate="'required'"
          :state="stateScoped('year_new')">
        </b-form-select>
      </div>
      <div class="col-md-2">
        <label>Mois</label>
        <b-form-select
          :options="monthOptions"
          size="sm"
          name="month_new"
          v-model="current_new.month"
          data-vv-scope="availability"
          :data-vv-as="current_new.month"
          v-validate="monthValidation(current_new)"
          :state="stateScoped('month_new')">
          <template slot="first">
            <option :value="null" disabled>Choisir...</option>
          </template>
        </b-form-select>
      </div>
      <div class="col-md-2">
        <label>Quantité</label>
        <b-form-input
          type="number"
          min="0"
          size="sm"
          placeholder="0"
          name="quantity_new"
          v-model="current_new.quantity"
          data-vv-scope="availability"
          v-validate="'required|min_value:0'"
          :state="stateScoped('quantity_new')"
          @focus="$event.target.select()" />
      </div>
      <div class="col-md-3 mt-3">
        <b-button size="sm" variant="outline-info btn-height" @click="addAvailability">
          Ajouter une date
        </b-button>
      </div>
      <b-form-invalid-feedback class="d-block ml-2">
        {{ errors.firstByRule('month_new', 'month', 'availability') }}
      </b-form-invalid-feedback>
    </div>
    <hr class="mt-3">
    <h5 class="card-title">Restriction à la réservation</h5>
    <div class="form-row mt-3">
      <div class="col-md-4">
        <b-form-checkbox
          :disabled="readonly"
          v-model="model.booking_restriction">
          Restreindre la réservation
        </b-form-checkbox>
      </div>
      <div class="col-md-4">
        <label>Code</label>
        <b-form-input
          :readonly="readonly || !model.booking_restriction"
          name="offer_booking_restriction_code"
          size="sm"
          v-model="model.booking_restriction_code"
          v-validate="{ required: model.booking_restriction, regex: /^[A-Z0-9]{6}$/ }"
          maxlength="6"
          placeholder="123ABC"
          :state="state('offer_booking_restriction_code')" />
        <b-form-invalid-feedback>
          {{ errors.first('offer_booking_restriction_code') }}
        </b-form-invalid-feedback>
        <b-form-text>
          Au format&nbsp;: 6 caractères alphanumériques, lettres en majuscule
        </b-form-text>
      </div>
      <div class="col-md-8">
        <label>Message</label>
        <b-form-input
          :readonly="readonly || !model.booking_restriction"
          name="booking_restriction_message"
          size="sm"
          v-model="model.booking_restriction_message"
          v-validate="{ required: model.booking_restriction }"
          :state="state('booking_restriction_message')" />
        <b-form-invalid-feedback>
          {{ errors.first('booking_restriction_message') }}
        </b-form-invalid-feedback>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import Form from '../mixins/Form'
  import Delete from '../base/Delete'

  moment.locale('fr')

  export default {
    mixins: [
      Form
    ],
    components: {
      Delete
    },
    props: {
      new_availability: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        years:  _.range(moment().year(), (moment().year() + 2)),
        months: _.range(0, 12),
        current_new: _.clone(this.new_availability),
        clear: false
      }
    },
    computed: {
      yearOptions() {
        return this.model.availabilities_attributes.map(av => {
          return { value: av.year, text: av.year }
        })
      },
      yearOptionsNew() {
        return this.years.map(year => {
          return { value: year, text: year }
        })
      },
      monthOptions() {
        return this.months.map(month => {
          return {
            value: month + 1,
            text: _.capitalize(moment().month(month).format('MMMM'))
          }
        })
      }
    },
    created() {
      this.$validator.extend('month', {
        getMessage: (field, args) => {
          let month = _.capitalize(moment().month(field - 1).format('MMMM'))
          return `Une disponibilité est déjà attribuée pour ${month} ${args[0]}`
        },
        validate: (value, args) => {
          let excluded = _.map(this.model.availabilities_attributes.filter(av => {
            return av._destroy ? av.year === args[0] && av._destroy !== 1 : av.year === args[0]
          }), 'month')
          return {
            valid: excluded ? !_.includes(excluded, value) : true
          }
        }
      })
    },
    methods: {
      stateScoped(ref) {
        if (this.clear) return null
        if (this.inputs['$availability']
          && (this.inputs['$availability'][ref].dirty
          || this.inputs['$availability'][ref].validated)) {
          return !this.errors.has(ref, 'availability')
        }
        return null
      },
      monthValidation(availability) {
        return {
          required: true,
          month: availability.year
        }
      },
      addAvailability() {
        this.$validator.validate('availability.*').then(valid => {
          if (valid) {
            this.clear = true
            this.model.availabilities_attributes.push(this.current_new)
            this.current_new = _.clone(this.new_availability)
          }
        })
        this.clear = false
      },
      removeAvailability(availability) {
        this.$set(availability, '_destroy', 1)
        this.current_new.month && this.$validator.validate('availability.month_new')
      }
    }
  }
</script>
