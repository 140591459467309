<template>
  <div class="content">
    <tabs
      :tabs="tabs"
      :currentTab="currentTab"
      wrapper-class="body-tabs shadow-tabs"
      tab-class="tab-item"
      tab-active-class="tab-item-active"
      line-class="tab-item-line"
      @onClick="changeTab"
    />
    <div v-if="currentTab === 'tab_table'">
      <b-row>
        <b-col md="12">
          <b-card class="mb-3" no-body>
            <div class="card-header">
              <i class="header-icon lnr-list icon-gradient bg-heavy-rain"></i>
              Liste des contrats
            </div>
            <div class="card-body">
              <div class="mb-3 widget-content bg-light" v-show="activeFiltersCount > 0" >
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-subheading">
                      <div class="badge badge-pill badge-secondary">
                        {{ activeFiltersCount }}
                      </div>
                      Filtres actifs
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <button type="button" class="btn ml-3 btn-sm btn-icon btn-transition btn-outline-info" @click="changeTab('tab_filters')">
                      <i class="pe-7s-filter btn-icon-wrapper"></i>
                      Modifier
                    </button>
                    <button type="button" class="btn ml-3 btn-sm btn-icon btn-transition btn-outline-danger" @click="clearFilters()">
                      <i class="pe-7s-trash btn-icon-wrapper"></i>
                      Effacer
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6">
                  <h6 class="pt-1">
                    <div class="badge badge-pill badge-primary">
                      {{ count }} / {{ total }}
                    </div>
                    <span class="ml-1 align-middle">
                      Résultats
                    </span>
                  </h6>
                </div>
                <div class="col-md-5">
                  <b-form-group>
                    <b-input-group>
                      <b-form-input v-model.trim="search" placeholder="Recherchez par mot clé" />
                      <b-input-group-append>
                        <b-btn class="btn-sm" :disabled="!search" @click="search = ''">
                          Effacer
                        </b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-md-1">
                  <b-form-group>
                    <b-form-select :options="pagination_options" v-model="per" />
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-table
                    class="table-responsive border-0"
                    show-empty
                    striped
                    hover
                    bordered
                    no-local-sorting
                    stacked="md"
                    :items="items"
                    :busy="loading"
                    :fields="fields"
                    @sort-changed="sortChanged"
                    empty-text="Aucun contrat trouvé">
                    <div slot="table-busy" class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Chargement...</strong>
                    </div>
                    <template slot="actions" slot-scope="data">
                      <b-button :href="data.item.show_url" class="btn-sm btn-icon btn-icon-only" variant="outline-info" v-b-tooltip.hover title="Voir ce contrat">
                        <i class="pe-7s-look btn-icon-wrapper"></i>
                      </b-button>
                      <b-button :href="data.item.edit_url" class="btn-sm btn-icon btn-icon-only" variant="outline-info" v-b-tooltip.hover title="Modifier ce contrat">
                        <i class="pe-7s-pen btn-icon-wrapper"></i>
                      </b-button>
                      <delete :href="data.item.destroy_url" tooltip="Supprimer ce contrat" @deleted="fetch()">
                        <i class="pe-7s-trash btn-icon-wrapper"></i>
                      </delete>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
            <b-pagination
              ref="pagination"
              size="md"
              align="center"
              :total-rows="count"
              :per-page="per"
              v-model="page" />
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-if="currentTab === 'tab_filters'">
      <b-row>
        <b-col md="12">
          <b-card class="mb-3" no-body>
            <form @submit.prevent="applyFilters()">
              <div class="card-header">
                <i class="header-icon lnr-magic-wand icon-gradient bg-heavy-rain"></i>
                Filtrer les résultats
              </div>
              <div class="card-body">
                <h5 class="card-title">Informations</h5>
                <div class="form-row">
                  <div class="col-md-6">
                    <label :class="{'text-success': filters.type}">Type de contrat</label>
                    <b-form-select
                      v-model="filters.type"
                      :options="types"
                      size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez un type de contrat</option>
                      </template>
                    </b-form-select>
                  </div>
                  <div class="col-md-6">
                    <label :class="{'text-success': filters.title}">Intitulé</label>
                    <input type="text" class="form-control form-control-sm" v-model="filters.title">
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">Enregistrement</h5>
                <div class="form-row">
                  <datepicker type="create" v-model="filters.created_at"></datepicker>
                  <div class="col-md-8">
                    <label>Créé par</label>
                    <div class="position-relative form-group">
                      <select class="form-control form-control-sm">
                        <option>TODO</option>
                      </select>
                      <small class="form-text text-muted">
                        Le créateur de l'enregistrement
                      </small>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <datepicker type="update" v-model="filters.updated_at"></datepicker>
                  <div class="col-md-8">
                    <label>Modifié par</label>
                    <div class="position-relative form-group">
                      <select class="form-control form-control-sm">
                        <option>TODO</option>
                      </select>
                      <small class="form-text text-muted">
                        Le dernier éditeur de l'enregistrement
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-block text-right card-footer">
                <button type="button" data-toggle="button" aria-pressed="true" class="btn btn-lg btn-link active float-left" @click="changeTab('tab_table')">
                  <i class="pe-7s-back"></i>
                  Retour à la liste
                </button>
                <button type="button" class="btn mr-2 btn-transition btn-outline-danger btn-lg" @click="clearFilters()">
                  Supprimer les filtres
                </button>
                <button class="btn btn-hover-shine btn-transition btn-primary btn-lg" type="submit">
                  Appliquer les filtres
                </button>
              </div>
            </form>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import Index from '../mixins/Index'
  import moment from 'moment'

  const fields = [
    {
      key: "id",
      label: "ID",
      sortable: false,
      tdClass: 'fit'
    },
    {
      key: "type",
      label: "Type",
      sortable: true
    },
    {
      key: "title",
      label: "Intitulé",
      sortable: true
    },
    {
      key: "published_at",
      label: "Date de publication",
      sortable: true,
      formatter: value => value ? moment(value).format('DD/MM/YYYY') : null
    },
    {
      key: "actions",
      label: '',
      sortable: false,
      tdClass: 'fit'
    }
  ]

  export default {
    mixins: [
      Index
    ],
    props: {
      types: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        fields
      }
    }
  }
</script>
