<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} une catégorie
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab title="Général" active>
                <h5 class="card-title">INFORMATIONS</h5>
                <div class="form-row">
                  <div class="col-md-12">
                    <label>Clé*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="list_key"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.key"
                      v-validate="'required|uniqueness:key'"
                      data-vv-delay="500"
                      :state="state('list_key')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('list_key') }}
                    </b-form-invalid-feedback>
                    <b-form-text></b-form-text>
                  </div>
                </div>
                <hr class="mt-3">
                <div class="row">
                  <div class="col-md-6" v-for="locale in this.$locales" :key="locale">
                    <h5 class="card-title">{{ locale }}</h5>
                    <div class="form-row mt-3">
                      <div class="col-md-12">
                        <label>Intitulé de la catégorie*</label>
                        <b-form-input
                          :readonly="readonly"
                          :name="`list_title_${locale}`"
                          type="text"
                          class="form-control form-control-sm"
                          v-model="model['title_' + locale]"
                          v-validate="'required'"
                          data-vv-delay="500"
                          :state="state(`list_title_${locale}`)"
                        />
                        <b-form-invalid-feedback>
                          {{ errors.first(`list_title_${locale}`) }}
                        </b-form-invalid-feedback>
                        <b-form-text></b-form-text>
                      </div>
                    </div>
                  </div>
                </div>
                <item-information :value="value" />
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit()"
              >
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import UniquenessValidator from '../mixins/UniquenessValidator'

  export default {
    mixins: [
      Form,
      UniquenessValidator
    ]
  }
</script>
