<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} un service
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab title="Général" active @click="disableSubmit = false">
                <h5 class="card-title">INFORMATIONS</h5>
                <div class="form-row">
                  <div class="col-md-6">
                    <label>Catégorie*</label>
                    <b-form-select
                     :disabled="readonly"
                      v-model="model.category"
                      :options="categories"
                      size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez une catégorie</option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>Prix par défaut*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="service_default_price"
                      type="number"
                      size="sm"
                      v-model="model.default_price"
                      v-validate="'required|min_value:0'"
                      data-vv-delay="500"
                      :state="state('service_default_price')"
                      @focus="$event.target.select()" />
                    <b-form-invalid-feedback>
                      {{ errors.first('service_default_price') }}
                    </b-form-invalid-feedback>
                    <b-form-text>
                      Saisissez un montant en euros. Ex. 9,99 ou 50.
                    </b-form-text>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>Clé*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="service_key"
                      size="sm"
                      placeholder="Saisissez une clé sans espaces, en minuscules"
                      v-model="model.key"
                      v-validate="keyValidation"
                      data-vv-delay="500"
                      :state="state('service_key')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('service_key') }}
                    </b-form-invalid-feedback>
                    <b-form-text>
                      Utilisé pour les classes css, les images, les icônes, les polices de caractères.
                    </b-form-text>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Position*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="service_position"
                      type="number"
                      min="0"
                      v-validate="'required'"
                      size="sm"
                      v-model="model.position" />
                    <b-form-invalid-feedback>
                      {{ errors.first('service_position') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <hr class="mt-4">
                <div class="row">
                  <div class="col-md-6" v-for="locale in this.$locales" :key="locale">
                    <h5 class="card-title">{{ locale }}</h5>
                    <div class="form-row mt-3">
                      <div class="col-md-12">
                        <label>Libellé{{ locale === 'fr' ? '*' : '' }}</label>
                        <b-form-input
                          :readonly="readonly"
                          :name="`service_title_${locale}`"
                          size="sm"
                          v-model="model['title_' + locale]"
                          v-validate="titleValidation(locale)"
                          :state="titleState(locale)" />
                        <b-form-invalid-feedback>
                          {{ errors.first(`service_title_${locale}`) }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row mt-3">
                      <div class="col-md-12">
                        <label>Description</label>
                        <b-form-textarea
                          :readonly="readonly"
                          size="sm"
                          v-model="model['description_' + locale]" />
                      </div>
                    </div>
                  </div>
                </div>
                <item-information :value="value" />
              </b-tab>
              <b-tab @click="disableSubmit = true">
                <template slot="title">
                  Photo <i class="small fas fa-lock" v-if="method === 'post'"></i>
                </template>
                <div v-if="!readonly">
                  <picture-form
                    :action="action"
                    :method="method"
                    :name="name"
                    :multiple="false"
                    paramName="[picture_attributes]"
                    title="service">
                  </picture-form>
                </div>
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="disabled"
                @click.stop.prevent="submit">
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import UniquenessValidator from '../mixins/UniquenessValidator'

  export default {
    mixins: [
      Form,
      UniquenessValidator
    ],
    props: {
      categories: {
        type: Array,
        required: true
      }
    },
    computed: {
      keyValidation() {
        return {
          required: true,
          uniqueness: 'key',
          regex: /^[a-z0-9_-]+$/
        }
      }
    },
    methods: {
      titleValidation(locale) {
        return locale === 'fr' ? 'required' : ''
      },
      titleState(locale) {
        return locale === 'fr' ? this.state('service_title_fr') : null
      }
    }
  }
</script>
