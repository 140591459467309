<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} un organisme
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab title="Général" active>
                <h5 class="card-title">INFORMATIONS</h5>
                <div class="form-row">
                  <div class="col-md-12">
                    <label>Nom de l'organisme*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="organization_title"
                      size="sm"
                      v-model="model.title"
                      v-validate="'required|uniqueness:title'"
                      data-vv-delay="500"
                      :state="state('organization_title')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('organization_title') }}
                    </b-form-invalid-feedback>
                    <b-form-text>
                      Nom de l'association, l'entreprise, la personne, ...
                    </b-form-text>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>Type*</label>
                    <div class="position-relative form-group">
                      <b-form-radio
                        :disabled="readonly"
                        id="organization_person-false"
                        class="custom-radio custom-control custom-control-inline"
                        name="organization_person"
                        v-model="model.person"
                        :value="false">
                        Personne morale
                      </b-form-radio>
                      <b-form-radio
                        :disabled="readonly"
                        id="organization_person-true"
                        class="custom-radio custom-control custom-control-inline"
                        name="organization_person"
                        v-model="model.person"
                        :value="true">
                        Personne physique
                      </b-form-radio>
                    </div>
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">CONTACT</h5>
                <div class="form-row" v-if="model.person">
                  <div class="col-md-4">
                    <label>Civilité*</label>
                    <div class="position-relative form-group">
                      <b-form-select
                        :disabled="readonly"
                        :options="{ male: 'M.', female: 'Mme' }"
                        v-model="model.gender"
                        size="sm" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label>Prénom*</label>
                    <div class="position-relative form-group">
                      <b-form-input
                        :readonly="readonly"
                        name="organization_first_name"
                        size="sm"
                        v-model="model.first_name"
                        v-validate="'required'"
                        :state="state('organization_first_name')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('organization_first_name') }}
                    </b-form-invalid-feedback>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label>Nom*</label>
                    <div class="position-relative form-group">
                      <b-form-input
                        :readonly="readonly"
                        name="organization_last_name"
                        size="sm"
                        v-model="model.last_name"
                        v-validate="'required'"
                        :state="state('organization_last_name')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('organization_last_name') }}
                    </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-8">
                    <label>Email*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="organization_email"
                      type="email"
                      size="sm"
                      v-model="model.email"
                      v-validate="'required|email'"
                      :state="state('organization_email')"  />
                    <b-form-invalid-feedback>
                      {{ errors.first('organization_email') }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-md-4">
                    <label>Téléphone</label>
                    <PhoneInput
                      v-model="model.phone_number"
                      :code=" model.phone_country_code"
                      name="phone_number"
                      @code="setCountryCode($event, 'phone_country_code')"
                      :readonly="readonly" />
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">ADRESSE</h5>
                <div class="form-row">
                  <div class="col-md-4">
                    <label>Adresse*</label>
                    <div class="position-relative form-group">
                      <b-form-textarea
                        :readonly="readonly"
                        name="organization_address"
                        size="sm"
                        v-model="model.address"
                        v-validate="'required'"
                        :state="state('organization_address')" />
                      <b-form-invalid-feedback>
                        {{ errors.first('organization_address') }}
                      </b-form-invalid-feedback>
                      <b-form-text>
                        Numéro et nom de la voie. Précisez à la ligne si nécessaire.
                      </b-form-text>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-4">
                    <label>Code postal*</label>
                    <div class="position-relative form-group">
                      <b-form-input
                        :readonly="readonly"
                        v-mask="'#####'"
                        name="organization_zip_code"
                        size="sm"
                        v-model="model.zip_code"
                        v-validate="'required'"
                        :state="state('organization_zip_code')" />
                      <b-form-invalid-feedback>
                        {{ errors.first('organization_zip_code') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="input3">Ville*</label>
                    <div class="position-relative form-group">
                      <b-form-input
                        :readonly="readonly"
                        name="organization_city"
                        size="sm"
                        v-model="model.city"
                        v-validate="'required'"
                        :state="state('organization_city')" />
                      <b-form-invalid-feedback>
                        {{ errors.first('organization_city') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="input3">Fax</label>
                    <PhoneInput
                      v-model="model.fax_number"
                      :code=" model.fax_country_code"
                      name="fax"
                      @code="setCountryCode($event, 'fax_country_code')"
                      :readonly="readonly" />
                  </div>
                </div>
                <item-information :value="value" />
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit">
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import UniquenessValidator from '../mixins/UniquenessValidator'

  export default {
    mixins: [
      Form,
      UniquenessValidator
    ]
  }
</script>
