<template>
  <div>
    <h5 class="card-title">CARACTERISTIQUES</h5>
    <div class="form-row mb-2" v-if="accommodation">
      <div class="col-md-4">
        <label>Surface habitable*</label>
        <b-form-input
          :readonly="readonly"
          name="unit_surface"
          type="number"
          size="sm"
          min="0"
          v-model="model.surface"
          v-validate="'required'"
          :state="state('unit_surface')"
          @focus="$event.target.select()" />
        <b-form-invalid-feedback>
          {{ errors.first('unit_surface') }}
        </b-form-invalid-feedback>
        <b-form-text>En m2</b-form-text>
      </div>
      <div class="col-md-4">
        <label>Surface corrigée</label>
        <b-form-input
          :readonly="readonly"
          type="number"
          min="0"
          size="sm"
          v-model="model.corrected_surface"
          @focus="$event.target.select()" />
        <b-form-text>En m2</b-form-text>
      </div>
      <div class="col-md-4">
        <label>Surface annexe</label>
        <b-form-input
          :readonly="readonly"
          type="number"
          min="0"
          size="sm"
          v-model="model.annex_surface"
          @focus="$event.target.select()" />
        <b-form-text>En m2</b-form-text>
      </div>
    </div>
    <div class="form-row mb-3" v-if="accommodation">
      <div class="col-md-4">
        <label>Surface totale</label>
        <b-form-input
          :readonly="readonly"
          type="number"
          min="0"
          size="sm"
          v-model="model.total_surface"
          @focus="$event.target.select()" />
        <b-form-text>En m2</b-form-text>
      </div>
      <div class="col-md-4">
        <label>Surface accessoire</label>
        <b-form-input
          :readonly="readonly"
          type="number"
          min="0"
          size="sm"
          v-model="model.auxiliary_surface"
          @focus="$event.target.select()" />
        <b-form-text>En m2</b-form-text>
      </div>
    </div>
    <div class="form-row mb-3">
      <div class="col-md-4">
        <template v-if="accommodation">
          <b-form-checkbox :disabled="readonly" v-model="model.equipped">
            Équipé
          </b-form-checkbox>
          <b-form-checkbox :disabled="readonly" v-model="model.balcony_or_terrace">
            Balcon ou terrasse
          </b-form-checkbox>
          <b-form-checkbox :disabled="readonly" v-model="model.parking_interior">
            Parking intérieur
          </b-form-checkbox>
          <b-form-checkbox :disabled="readonly" v-model="model.parking_exterior">
            Parking extérieur
          </b-form-checkbox>
        </template>
        <b-form-checkbox :disabled="readonly" v-model="model.reduced_mobility_access">
          Logement adapté aux handicapés
        </b-form-checkbox>
      </div>
      <div class="col-md-4" v-if="accommodation">
        <label>Surface balcon ou terrasse</label>
        <b-form-input
          :readonly="balconyTerraceDisable"
          type="number"
          min="0"
          size="sm"
          v-model="model.balcony_or_terrace_surface"
          @focus="$event.target.select()" />
        <b-form-text>En m2</b-form-text>
      </div>
    </div>
    <div class="form-row" v-if="accommodation">
      <div class="col-md-4">
        <label>Consommations</label>
        <b-form-input :readonly="readonly" size="sm" v-model="model.energy_consumption" />
      </div>
      <div class="col-md-4">
        <label>Emissions de gaz à effet de serre (GES)</label>
        <b-form-input :readonly="readonly" size="sm" v-model="model.greenhouse_gas_emissions" />
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'

  export default {
    mixins: [
      Form
    ],
    props: {
      accommodation: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      balconyTerraceDisable() {
        return this.readonly || !this.model.balcony_or_terrace
      }
    }
  }
</script>
