<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} un élément
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab title="Général" active>
                <h5 class="card-title">INFORMATIONS</h5>
                <div class="form-row">
                  <div class="col-md-6">
                    <label>Clé*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="list_item_key"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.key"
                      v-validate="keyValidation"
                      data-vv-delay="500"
                      :state="state('list_item_key')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('list_item_key') }}
                    </b-form-invalid-feedback>
                    <b-form-text></b-form-text>
                  </div>
                  <div class="col-md-4">
                    <label>Position*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="unit_type_position"
                      type="number"
                      min="0"
                      class="form-control form-control-sm"
                      v-model="model.position"
                      data-vv-as="position"
                      v-validate="'required|min_value:0'"
                      :state="state('unit_type_position')" />
                    <b-form-invalid-feedback>
                      {{ errors.first('unit_type_position') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <hr class="mt-3">
                <div class="row">
                  <div class="col-md-6" v-for="locale in this.$locales" :key="locale">
                    <h5 class="card-title">{{ locale }}</h5>
                    <div class="form-row mt-3">
                      <div class="col-md-12">
                        <label>Intitulé de l'élément*</label>
                        <b-form-input
                          :readonly="readonly"
                          :name="`list_item_title_${locale}`"
                          type="text"
                          class="form-control form-control-sm"
                          v-model="model['title_' + locale]"
                          v-validate="titleValidation"
                          data-vv-delay="500"
                          :state="state(`list_item_title_${locale}`)" />
                        <b-form-invalid-feedback>
                          {{ errors.first(`list_item_title_${locale}`) }}
                        </b-form-invalid-feedback>
                        <b-form-text></b-form-text>
                      </div>
                    </div>
                  </div>
                </div>
                <item-information :value="value" />
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit">
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import UniquenessValidator from '../mixins/UniquenessValidator'

  export default {
    mixins: [
      Form,
      UniquenessValidator
    ],
    computed: {
      keyValidation() {
        return {
          required: true,
          uniqueness: 'key'
        }
      }
    },
    methods: {
      titleValidation(locale) {
        if (locale === 'fr') {
          return {
            required: true,
            uniqueness: 'title',
          }
        } else {
          return ''
        }
      }
    }
  }
</script>
