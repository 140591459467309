<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} un type de lot
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab title="Général" active>
                <h5 class="card-title">INFORMATIONS</h5>
                <div class="form-row">
                  <div class="col-md-6">
                    <label :class="{'text-success': model.organization_id}">Organisme*</label>
                    <b-form-select
                      :disabled="readonly"
                      v-model="model.organization_id"
                      :options="organizations"
                      v-validate="'required'"
                      size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez un organisme</option>
                      </template>
                    </b-form-select>
                  </div>
                  <div class="col-md-6">
                    <label :class="{'text-success': model.kind_id}">Type*</label>
                    <b-form-select
                     :disabled="readonly"
                      v-model="model.kind_id"
                      :options="kinds"
                      v-validate="'required'"
                      size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez un type</option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>Nombre colocataires*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="unit_type_roomates_count"
                      type="number"
                      min="1"
                      max="5"
                      class="form-control form-control-sm"
                      v-model="model.roomates_count"
                      data-vv-as="nombre de colocataires"
                      v-validate="'min_value:1|max_value:5'"
                      :state="state('unit_type_roomates_count')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('unit_type_roomates_count') }}
                    </b-form-invalid-feedback>
                    <b-form-text>
                      Choisissez 2 colocataires pour les contrats solidaires (Ex T1 bis couple).
                    </b-form-text>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>Clé*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="unit_type_key"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.key"
                      v-validate="'required|uniqueness:key'"
                      data-vv-delay="500"
                      :state="state('unit_type_key')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('unit_type_key') }}
                    </b-form-invalid-feedback>
                    <b-form-text>
                      Ex. studio, t1bis, appcoloc2p
                    </b-form-text>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Position*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="unit_type_position"
                      type="number"
                      min="0"
                      class="form-control form-control-sm"
                      v-model="model.position"
                      v-validate="'required|min_value:0'"
                      :state="state('unit_type_position')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('unit_type_position') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <hr class="mt-3">
                <div class="row">
                  <div class="col-md-6" v-for="locale in this.$locales" :key="locale">
                    <h5 class="card-title">{{ locale }}</h5>
                    <div class="form-row mt-3">
                      <div class="col-md-12">
                        <label>Libellé*</label>
                        <b-form-input
                          :readonly="readonly"
                          :name="`unit_type_title_${locale}`"
                          type="text"
                          class="form-control form-control-sm"
                          v-model="model['title_' + locale]"
                          v-validate="'required|uniqueness:title'"
                          data-vv-delay="500"
                          :state="state(`unit_type_title_${locale}`)"
                        />
                        <b-form-invalid-feedback>
                          {{ errors.first(`unit_type_title_${locale}`) }}
                        </b-form-invalid-feedback>
                        <b-form-text>
                          Parking intérieur, T1bis C, ...
                        </b-form-text>
                      </div>
                    </div>
                    <div class="form-row mt-3">
                      <div class="col-md-12">
                        <label>Nom commercial*</label>
                        <b-form-input
                          :readonly="readonly"
                          :name="`unit_type_commercial_title_${locale}`"
                          type="text"
                          class="form-control form-control-sm"
                          v-model="model['commercial_title_' + locale]"
                          v-validate="'required|uniqueness:commercial_title'"
                          data-vv-delay="500"
                          :state="state(`unit_type_commercial_title_${locale}`)"
                        />
                        <b-form-invalid-feedback>
                          {{ errors.first(`unit_type_commercial_title_${locale}`) }}
                        </b-form-invalid-feedback>
                        <b-form-text>
                          Apparaît lors de la réservation
                        </b-form-text>
                      </div>
                    </div>
                    <div class="form-row mt-3">
                      <div class="col-md-12">
                        <label>Description*</label>
                        <b-form-textarea
                          :readonly="readonly"
                          :name="`unit_type_description_${locale}`"
                          class="form-control form-control-sm"
                          v-model="model['description_' + locale]"
                          v-validate="'required|max:150'"
                          :state="state(`unit_type_description_${locale}`)"
                        />
                        <b-form-invalid-feedback>
                          {{ errors.first(`unit_type_description_${locale}`) }}
                        </b-form-invalid-feedback>
                        <b-form-text>
                          Apparaît lors de la réservation (max: 150 caractères)
                        </b-form-text>
                      </div>
                    </div>
                  </div>
                </div>
                <item-information :value="value" />
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit()"
              >
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import UniquenessValidator from '../mixins/UniquenessValidator'

  export default {
    mixins: [
      Form,
      UniquenessValidator
    ],
    props: {
      kinds: Array,
      organizations: Array
    }
  }
</script>
