<template>
  <div class="header-dots">
    <b-dropdown toggle-class="p-0" menu-class="dropdown-menu-xl" variant="link" no-caret right>
      <span slot="button-content">
        <div class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-warning"></div>
          <i class="lnr-bullhorn text-warning"></i>
          <div class="badge badge-dot badge-dot-sm badge-warning" v-if="messages.length > 0">Notifications</div>
        </div>
      </span>
      <div class="dropdown-menu-header mb-0">
        <div class="dropdown-menu-header-inner bg-deep-blue">
          <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
          <div class="menu-header-content text-dark">
            <h5 class="menu-header-title">Notifications</h5>
            <h6 class="menu-header-subtitle" v-if="messages.length > 0">Messages non lus</h6>
            <h6 class="menu-header-subtitle" v-else>Aucun nouveau message</h6>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end pt-2">
        <form class="form-inline px-3">
          <select class="custom-select w-100 custom-select-sm text-truncate" id="residence_id" v-model="residenceId" @change="fetch()">
            <option :value="null" class="residence-option">-- Toutes les résidences --</option>
            <option class="residence-option" v-for="residence in residences" :key="residence.id" :value="residence.id">{{ residence.title }}</option>
          </select>
        </form>
      </div>
      <div v-if="messages.length > 1">
        <div class="scroll-area-md p-3">
          <VuePerfectScrollbar class="scrollbar-container">
            <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
              <div class="vertical-timeline-item vertical-timeline-element" v-for="message in messages" :key="message.id">
                <div>
                  <span class="vertical-timeline-element-icon bounce-in"></span>
                  <div class="vertical-timeline-element-content bounce-in">
                    <a :href="message.url">
                      <div>
                        <strong>{{ message.customer_name }}</strong>
                        <span class="pull-right text-muted">{{ message.created_at | moment('DD/MM/YYYY HH:mm') }}</span>
                      </div>
                      {{ message.content }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <VueElementLoading class="mt-2" v-show="fetching" />
              <b-button variant="link" v-show="!fetching" @click="fetchMore()">
                Voir plus
              </b-button>
            </div>
          </VuePerfectScrollbar>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import axios from 'axios'
  import VueElementLoading from 'vue-element-loading'

  export default {
    components: {
      VuePerfectScrollbar,
      VueElementLoading
    },
    props: {
      residences: {
        type: Array,
        required: true
      },
      messages_url: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        messages: [],
        residenceId: null,
        limit: 10,
        fetching: false
      }
    },
    created() {
      this.fetch()
    },
    methods: {
      fetch() {
        if (this.fetching) return
        this.fetching = true
        axios.get(
          this.messages_url,
          {
            params: {
              residence_id: this.residenceId,
              limit: this.limit
            }
          }
        ).then((response) => {
          this.messages = response.data
          this.fetching = false
        })
      },
      fetchMore() {
        this.limit += 10
        this.fetch()
      }
    }
  }
</script>
