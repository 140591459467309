<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} une offre
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab active :disabled="manager">
                <template slot="title">
                  Général <i class="small fas fa-lock" v-if="manager"></i>
                </template>
                <h5 class="card-title">Contexte</h5>
                <div class="form-row">
                  <div class="col-md-12">
                    <label>Résidence*</label>
                    <b-form-select
                      :disabled="readonly"
                      :options="residences"
                      size="sm"
                      name="offer_residence_id"
                      v-model="model.residence_id"
                      v-validate="'required'"
                      :state="state('offer_residence_id')"
                      @change="validateUniqueness">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez une résidence</option>
                      </template>
                    </b-form-select>
                  </div>
                  <div class="col-md-12 mt-3">
                    <label>Type de lot*</label>
                    <b-form-select
                      :disabled="readonly"
                      :options="unit_types"
                      size="sm"
                      name="offer_unit_type_id"
                      v-model="model.unit_type_id"
                      v-validate="'required|uniqueness:unit_type_id'"
                      :state="state('offer_unit_type_id')"
                      @change="validateUniqueness">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez un type de lot</option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback>
                      {{ errors.first('offer_unit_type_id') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div v-if="isAccommodation">
                  <hr class="mt-3">
                  <h5 class="card-title">Caractéristiques</h5>
                  <div class="form-row">
                    <div class="col-md-3">
                      <label>Type de surface habitable</label>
                      <b-form-select
                        :disabled="readonly"
                        :options="surface_types"
                        size="sm"
                        name="offer_surface_type"
                        v-model="model.surface_type"
                        v-validate="'required'"
                        :state="state('offer_surface_type')">
                        <template slot="first">
                          <option :value="null" disabled>Choisissez un type de surface</option>
                        </template>
                      </b-form-select>
                    </div>
                    <div class="col-md-3">
                      <label>{{ surfaceLabel }}</label>
                      <div class="position-relative form-group">
                        <b-form-input
                          :readonly="readonly"
                          type="number"
                          size="sm"
                          min="0"
                          placeholder="0"
                          name="offer_surface_from"
                          v-model="model.surface_from"
                          v-validate="'required|min_value:0'"
                          :state="state('offer_surface_from')" />
                      <b-form-text>En m²</b-form-text>
                      </div>
                    </div>
                    <div class="col-md-3" v-if="model.surface_type === 'range'">
                      <label>jusqu'à...</label>
                      <div class="position-relative form-group">
                        <b-form-input
                          :readonly="readonly"
                          type="number"
                          size="sm"
                          min="0"
                          placeholder="0"
                          name="offer_surface_to"
                          v-model="model.surface_to"
                          v-validate="'required|min_value:0'"
                          :state="state('offer_surface_to')" />
                      <b-form-text>En m²</b-form-text>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :disabled="manager">
                <template slot="title">
                  Loyer <i class="small fas fa-lock" v-if="manager"></i>
                </template>
                <OfferFormRent
                  :readonly="readonly"
                  :value="value"
                  :pricings="pricings"
                  :optionsRent="surface_types"
                  :optionsRentPlus="optionsRentPlus"
                  :new_offer_pricing="new_offer_pricing" />
              </b-tab>
              <b-tab title="Disponibilités">
                <OfferFormAvailabilities
                  :new_availability="new_availability"
                  :readonly="readonly"
                  :value="value" />
                <hr class="mt-3">
                <h5 class="card-title">Publication</h5>
                <div class="form-row">
                  <div class="col-md-4">
                    <label>Date de publication</label>
                    <div class="form-group">
                      <date-picker
                        :disabled="readonly"
                        name="offer_published_at"
                        v-model="model.published_at"
                        @clear="(clear)=>{model.published_at=''}"
                        lang="fr"
                        format="DD/MM/YYYY">
                      </date-picker>
                    </div>
                  </div>
                </div>
                <item-information :value="value" />
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit">
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import Form from '../mixins/Form'
  import UniquenessValidator from '../mixins/UniquenessValidator'
  import OfferFormRent from './OfferFormRent'
  import OfferFormAvailabilities from './OfferFormAvailabilities'

  export default {
    mixins: [
      Form,
      UniquenessValidator
    ],
    components: {
      DatePicker,
      OfferFormRent,
      OfferFormAvailabilities
    },
    props: {
      residences: {
        type: Array,
        default: () => []
      },
      unit_types: {
        type: Array,
        default: () => []
      },
      surface_types: {
        type: Array,
        default: () => []
      },
      pricings: {
        type: Object,
        default: () => {}
      },
      new_offer_pricing: {
        type: Object,
        default: () => {}
      },
      new_availability: {
        type: Object,
        default: () => {}
      },
      manager: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        params: {},
        optionsRentPlus: [
          ...this.surface_types,
          { value: "none", text: 'Aucun' },
          { value: "free", text: 'Offert' }
        ]
      }
    },
    computed: {
      isAccommodation() {
        const unit_type = this.unit_types.find((ut) => ut.value == this.model.unit_type_id)
        return unit_type != null && !unit_type.parking
      },
      surfaceLabel() {
        if (this.model.surface_type === 'unique') return 'Unique'
        if (this.model.surface_type === 'from') return 'À partir de'
        if (this.model.surface_type === 'range') return 'De...'
      }
    },
    created() {
      this.$set(this.params, 'residence_id', this.model.residence_id)
    },
    methods: {
      validateUniqueness() {
        this.$set(this.params, 'residence_id', this.model.residence_id)
        this.$validator.validate('offer_unit_type_id')
      }
    }
  }
</script>
