<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ title }}
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab title="Général" active>
                <div v-if="!account">
                  <h5 class="card-title">Contexte</h5>
                  <div class="form-row">
                    <div class="col-md-4">
                      <label :class="{ 'text-success': model.role }"
                        >Rôle*</label
                      >
                      <b-form-select
                        :disabled="readonly"
                        name="user_role"
                        v-model="model.role"
                        v-validate="'required'"
                        :options="roles"
                        size="sm"
                      >
                        <template slot="first">
                          <option :value="null" disabled
                            >Choisissez un rôle</option
                          >
                        </template>
                      </b-form-select>
                    </div>
                    <div class="col-md-4">
                      <label
                        :class="{ 'text-success': model.organization_name }"
                        >Nom de l'organisme*</label
                      >
                      <b-form-select
                        :disabled="readonly"
                        name="user_organization_id"
                        v-model="model.organization_id"
                        v-validate="'required'"
                        :options="organizations"
                        size="sm"
                      >
                        <template slot="first">
                          <option :value="null" disabled
                            >Choisissez un organisme</option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <hr class="mt-3" />
                </div>
                <h5 class="card-title">Contact</h5>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Civilité*</label>
                    <div class="position-relative form-group">
                      <b-form-radio
                        :disabled="readonly"
                        id="user-gender-man"
                        class="custom-radio custom-control custom-control-inline"
                        name="user_gender"
                        v-model="model.gender"
                        :value="'male'"
                      >
                        Monsieur
                      </b-form-radio>
                      <b-form-radio
                        :disabled="readonly"
                        id="user_gender-woman"
                        class="custom-radio custom-control custom-control-inline"
                        name="user_gender"
                        v-model="model.gender"
                        :value="'female'"
                      >
                        Madame
                      </b-form-radio>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-4">
                    <label>Prénom*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="user_first_name"
                      size="sm"
                      v-model="model.first_name"
                      v-validate="'required'"
                      :state="state('user_first_name')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first("user_first_name") }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-md-4">
                    <label>Nom*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="user_last_name"
                      size="sm"
                      v-model="model.last_name"
                      v-validate="'required'"
                      :state="state('user_last_name')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first("user_last_name") }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Email*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="user_email"
                      type="email"
                      size="sm"
                      v-model="model.email"
                      data-vv-delay="500"
                      v-validate="emailValidation"
                      :state="state('user_email')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first("user_email") }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label>Téléphone mobile</label>
                    <PhoneInput
                      v-model="model.mobile_phone_number"
                      :code="model.mobile_phone_country_code"
                      name="mobile_phone_number"
                      @code="
                        setCountryCode($event, 'mobile_phone_country_code')
                      "
                      :readonly="readonly"
                    />
                    <b-form-text>
                      Au format international : +33 0 00 00 00 00
                    </b-form-text>
                  </div>
                  <div class="col-md-4">
                    <label>Fax</label>
                    <PhoneInput
                      v-model="model.fax_number"
                      :code="model.fax_country_code"
                      name="fax"
                      @code="setCountryCode($event, 'fax_country_code')"
                      :readonly="readonly"
                    />
                    <b-form-text>
                      Au format international : +33 0 00 00 00 00
                    </b-form-text>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div v-if="readonly"></div>
                  <div v-else-if="model.id" class="col-md-4">
                    <label>Modifier le mot de passe</label>
                    <b-form-input
                      :readonly="readonly"
                      name="user_password"
                      size="sm"
                      v-model="model.password"
                      v-validate="'min:10'"
                      :state="state('user_password')"
                    />
                    <b-form-text>
                      Au moins 10 caractères avec au moins une majuscule, une
                      minuscule, un chiffre et un caractère spécial parmi
                      !"#$%&'()*+,-./ :;<=>?@[]^_`{|}~.
                    </b-form-text>
                  </div>
                  <div v-else class="col-md-4">
                    <label>Mot de passe*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="user_password"
                      size="sm"
                      v-model="model.password"
                      v-validate="'required|min:10'"
                      :state="state('user_password')"
                    />
                    <b-form-text>
                      Au moins 10 caractères avec au moins une majuscule, une
                      minuscule, un chiffre et un caractère spécial parmi
                      !"#$%&'()*+,-./ :;<=>?@[]^_`{|}~.
                    </b-form-text>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-12">
                    <label>Compte confirmé</label>
                    <b-form-radio-group
                      :disabled="readonly"
                      :checked="model.confirmed"
                      @change="confirmUser"
                      :options="[
                        { text: 'Oui', value: true },
                        { text: 'Non', value: false },
                      ]"
                    />
                  </div>
                </div>
                <hr class="mt-3" />
                <h5 class="card-title">Adresse</h5>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Adresse</label>
                    <div class="position-relative form-group">
                      <b-form-textarea
                        :readonly="readonly"
                        name="user_address"
                        size="sm"
                        v-model="model.address"
                      />
                      <b-form-text>
                        Numéro et nom de la voie. Précisez à la ligne si
                        nécessaire.
                      </b-form-text>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label>Code postal</label>
                    <div class="position-relative form-group">
                      <b-form-input
                        :readonly="readonly"
                        v-mask="'######'"
                        name="user_zip_code"
                        size="sm"
                        v-model="model.zip_code"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="input3">Ville</label>
                    <div class="position-relative form-group">
                      <b-form-input
                        :readonly="readonly"
                        name="user_city"
                        size="sm"
                        v-model="model.city"
                      />
                    </div>
                  </div>
                </div>
                <hr class="mt-3" />
                <h5 class="card-title">Suspension</h5>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Compte désactivé le</label>
                    <div class="form-group">
                      <date-picker
                        :disabled="readonly"
                        name="user_suspended_at"
                        v-model="model.suspended_at"
                        @clear="
                          (clear) => {
                            model.suspended_at = '';
                          }
                        "
                        lang="fr"
                        format="DD/MM/YYYY"
                      >
                      </date-picker>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <label>Raison de la désactivation</label>
                    <b-form-textarea
                      :readonly="readonly"
                      name="user_suspension_reason"
                      size="sm"
                      v-model="model.suspension_reason"
                    />
                  </div>
                </div>
                <hr class="my-3" />
                <h5 class="card-title">INFORMATIONS</h5>
                <div class="form-row">
                  <div class="col-md-12">
                    <label>
                      Mot de passe réinitialisé le
                      <span
                        class="mb-2 mr-2 badge badge-light"
                        v-if="model.reset_password_sent_at"
                        >{{
                          model.reset_password_sent_at
                            | moment("DD/MM/YYYY à HH:mm:ss")
                        }}</span
                      >
                      <span class="mb-2 mr-2 badge badge-light" v-else
                        >jamais</span
                      >
                    </label>
                  </div>
                  <div class="col-md-12">
                    <label>
                      Se souvenir de moi enregistré le
                      <span
                        class="mb-2 mr-2 badge badge-light"
                        v-if="model.remember_created_at"
                        >{{
                          model.remember_created_at
                            | moment("DD/MM/YYYY à HH:mm:ss")
                        }}</span
                      >
                      <span class="mb-2 mr-2 badge badge-light" v-else
                        >jamais</span
                      >
                    </label>
                  </div>
                  <div class="col-md-12">
                    <label>
                      Nombre de connexions
                      <span class="mb-2 mr-2 badge badge-light">{{
                        model.sign_in_count
                      }}</span>
                    </label>
                  </div>
                  <div class="col-md-12">
                    <label>
                      Connexion en cours depuis le
                      <span class="mb-2 mr-2 badge badge-light">{{
                        model.current_sign_in_at
                          | moment("DD/MM/YYYY à HH:mm:ss")
                      }}</span>
                    </label>
                  </div>
                  <div class="col-md-12">
                    <label>
                      Dernière connexion le
                      <span
                        class="mb-2 mr-2 badge badge-light"
                        v-if="model.last_sign_in_at"
                        >{{
                          model.last_sign_in_at
                            | moment("DD/MM/YYYY à HH:mm:ss")
                        }}</span
                      >
                      <span class="mb-2 mr-2 badge badge-light" v-else
                        >jamais</span
                      >
                    </label>
                  </div>
                  <div class="col-md-12">
                    <label>
                      Compte confirmé le
                      <span
                        class="mb-2 mr-2 badge badge-light"
                        v-if="model.confirmed_at"
                        >{{
                          model.confirmed_at | moment("DD/MM/YYYY à HH:mm:ss")
                        }}</span
                      >
                      <span class="mb-2 mr-2 badge badge-light" v-else
                        >jamais</span
                      >
                    </label>
                  </div>
                  <div class="col-md-12">
                    <label>
                      Confirmation envoyée le
                      <span
                        class="mb-2 mr-2 badge badge-light"
                        v-if="model.confirmation_sent_at"
                        >{{
                          model.confirmation_sent_at
                            | moment("DD/MM/YYYY à HH:mm:ss")
                        }}</span
                      >
                      <span class="mb-2 mr-2 badge badge-light" v-else
                        >jamais</span
                      >
                    </label>
                  </div>
                </div>
                <item-information :value="value" />
              </b-tab>
              <b-tab title="Résidences">
                <h5 class="card-title">Résidences</h5>
                <div class="form-row">
                  <div class="col-md-12">
                    <div class="d-flex justify-content-between mb-2">
                      <label>Liste des résidences attribuées</label>
                      <div
                        class="btn btn-outline-light btn-sm"
                        @click.prevent="clearResidences"
                        v-if="!disableResidence"
                      >
                        Vider la liste
                      </div>
                    </div>
                    <multiselect
                      multiple
                      class="multiselect"
                      :disabled="disableResidence"
                      :options="residences"
                      :clearable="false"
                      label="text"
                      :reduce="(text) => text.value"
                      v-model="model.residence_ids"
                      ref="select"
                    >
                      <div slot="no-options">Aucune résidence trouvée</div>
                    </multiselect>
                    <b-form-text v-if="!disableResidence">
                      Recherchez une ou plusieurs résidences dans la liste.
                    </b-form-text>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a
                :href="cancel_url"
                class="btn btn-lg btn-link active float-left"
              >
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg"
              >
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit"
              >
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "../mixins/Form";
import UniquenessValidator from "../mixins/UniquenessValidator";
import DatePicker from "vue2-datepicker";

export default {
  mixins: [Form, UniquenessValidator],
  components: {
    DatePicker,
  },
  props: {
    roles: {
      type: Array,
      default: () => [],
    },
    organizations: {
      type: Array,
      default: () => [],
    },
    residences: {
      type: Array,
      default: () => [],
    },
    disable_residences: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return this.account
        ? "Modifier mon compte"
        : `${this.action_type} un utilisateur`;
    },
    emailValidation() {
      return {
        email: true,
        required: true,
        uniqueness: "email",
      };
    },
    disableResidence() {
      return this.readonly || this.disable_residences;
    },
  },
  methods: {
    confirmUser(value) {
      this.model.confirmed_at = value ? new Date() : "null";
    },
    clearResidences() {
      this.$refs.select.clearSelection();
    },
  },
};
</script>
