<template>
  <div class="content">
    <tabs
      :tabs="tabs"
      :currentTab="currentTab"
      wrapper-class="body-tabs shadow-tabs"
      tab-class="tab-item"
      tab-active-class="tab-item-active"
      line-class="tab-item-line"
      @onClick="changeTab"
    />
    <div v-if="currentTab === 'tab_table'">
      <b-row>
        <b-col md="12">
          <b-card class="mb-3" no-body>
            <div class="card-header">
              <i class="header-icon lnr-list icon-gradient bg-heavy-rain"></i>
              Liste des utilisateurs
              <div class="btn-actions-pane-right">
                <b-dropdown variant="outline-secondary" right :text="selectionLabel" :disabled="selection.length == 0">
                  <b-dropdown-item :href="exportUrl()">
                    <i class="text-muted far fa-fw fa-file-excel mr-2"></i>
                    Exporter (Excel)
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item-button @click="clearSelection()">
                    Tout désélectionner
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>
            <div class="card-body">
              <div class="mb-3 widget-content bg-light" v-show="activeFiltersCount > 0" >
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-subheading">
                      <div class="badge badge-pill badge-secondary">
                        {{ activeFiltersCount }}
                      </div>
                      Filtres actifs
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <button type="button" class="btn ml-3 btn-sm btn-icon btn-transition btn-outline-info" @click="changeTab('tab_filters')">
                      <i class="pe-7s-filter btn-icon-wrapper"></i>
                      Modifier
                    </button>
                    <button type="button" class="btn ml-3 btn-sm btn-icon btn-transition btn-outline-danger" @click="clearFilters()">
                      <i class="pe-7s-trash btn-icon-wrapper"></i>
                      Effacer
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6">
                  <h6 class="pt-1">
                    <div class="badge badge-pill badge-primary">
                      {{ count }} / {{ total }}
                    </div>
                    <span class="ml-1 align-middle">
                      Résultats
                    </span>
                  </h6>
                </div>
                <div class="col-md-5">
                  <b-form-group>
                    <b-input-group>
                      <b-form-input v-model.trim="search" placeholder="Recherchez par mot clé" />
                      <b-input-group-append>
                        <b-btn class="btn-sm" :disabled="!search" @click="search = ''">
                          Effacer
                        </b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-md-1">
                  <b-form-group>
                    <b-form-select :options="pagination_options" v-model="per" />
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-table
                    class="table-responsive border-0"
                    show-empty
                    striped
                    hover
                    bordered
                    no-local-sorting
                    stacked="md"
                    :items="items"
                    :busy="loading"
                    :fields="fields"
                    @sort-changed="sortChanged"
                    empty-text="Aucun utilisateur trouvé">
                    <div slot="table-busy" class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Chargement...</strong>
                    </div>
                    <template slot="HEAD_check">
                      <b-form-checkbox v-model="allSelected" @change="toggleAll" />
                    </template>
                    <template slot="check" slot-scope="data">
                      <b-form-checkbox :id="`selection-${data.item.id}`" v-model="selection" :value="data.item" />
                    </template>
                    <template slot="contact" slot-scope="data">
                      {{ data.item.first_name }} {{ data.item.last_name }}
                      <br>
                      {{ data.item.phone_number }}
                    </template>
                    <template slot="suspended_at" slot-scope="data">
                      <span v-if="data.item.suspended_at">Non</span>
                      <span v-else>Oui</span>
                    </template>
                    <template slot="created_at" slot-scope="data">
                      {{ data.item.created_at | moment('DD/MM/YYYY') }}
                    </template>
                    <template slot="last_sign_in_at" slot-scope="data">
                      {{ data.item.last_sign_in_at | moment('DD/MM/YYYY') }}
                    </template>
                    <template slot="actions" slot-scope="data">
                      <b-button :href="data.item.show_url" class="btn-sm btn-icon btn-icon-only" variant="outline-info" v-b-tooltip.hover title="Voir cet utilisateur">
                        <i class="pe-7s-look btn-icon-wrapper"></i>
                      </b-button>
                      <b-button v-if="data.item.edit_url" :href="data.item.edit_url" class="btn-sm btn-icon btn-icon-only" variant="outline-info" v-b-tooltip.hover title="Modifier cet utilisateur">
                        <i class="pe-7s-pen btn-icon-wrapper"></i>
                      </b-button>
                      <delete v-if="data.item.destroy_url" :href="data.item.destroy_url" tooltip="Supprimer cet utilisateur" @deleted="fetch()">
                        <i class="pe-7s-trash btn-icon-wrapper"></i>
                      </delete>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
            <b-pagination
              ref="pagination"
              size="md"
              align="center"
              :total-rows="count"
              :per-page="per"
              v-model="page" />
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-if="currentTab === 'tab_filters'">
      <b-row>
        <b-col md="12">
          <b-card class="mb-3" no-body>
            <form @submit.prevent="applyFilters()">
              <div class="card-header">
                <i class="header-icon lnr-magic-wand icon-gradient bg-heavy-rain"></i>
                Filtrer les résultats
              </div>
              <div class="card-body">
                <h5 class="card-title">Contexte</h5>
                <div class="form-row">
                  <div class="col-md-6">
                    <label :class="{'text-success': filters.role }">Rôle</label>
                    <b-form-select
                      v-model="filters.role"
                      :options="roles"
                      size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez un rôle</option>
                      </template>
                    </b-form-select>
                  </div>
                  <div class="col-md-6">
                    <label :class="{'text-success': filters.organization_id}">Nom de l'organisme</label>
                    <b-form-select
                      v-model="filters.organization_id"
                      :options="organizations"
                      size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez un organisme</option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">Informations</h5>
                <div class="form-row mt-3">
                  <div class="col-md-3">
                    <label :class="{'text-success': filters.first_name }">Prénom</label>
                    <input type="text" class="form-control form-control-sm" v-model="filters.first_name">
                  </div>
                  <div class="col-md-3">
                    <label :class="{'text-success': filters.last_name }">Nom</label>
                    <input type="text" class="form-control form-control-sm" v-model="filters.last_name">
                  </div>
                  <div class="col-md-6">
                    <label :class="{'text-success': filters.email }">Email</label>
                    <input type="text" class="form-control form-control-sm" v-model="filters.email">
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-9">
                    <label :class="{'text-success': filters.city }">Ville</label>
                    <input type="text" class="form-control form-control-sm" v-model="filters.city">
                  </div>
                  <div class="col-md-3">
                    <label :class="{'text-success': filters.zip_code }">Code postal</label>
                    <input type="text" class="form-control form-control-sm" v-model="filters.zip_code">
                  </div>
                </div>
                <hr class="mt-3">
                <h5 class="card-title">Enregistrement</h5>
                <div class="form-row">
                  <datepicker type="create" v-model="filters.created_at"></datepicker>
                  <div class="col-md-8">
                    <label>Créé par</label>
                    <div class="position-relative form-group">
                      <select class="form-control form-control-sm">
                        <option>TODO</option>
                      </select>
                      <small class="form-text text-muted">
                        Le créateur de l'enregistrement
                      </small>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <datepicker type="update" v-model="filters.updated_at"></datepicker>
                  <div class="col-md-8">
                    <label>Modifié par</label>
                    <div class="position-relative form-group">
                      <select class="form-control form-control-sm">
                        <option>TODO</option>
                      </select>
                      <small class="form-text text-muted">
                        Le dernier éditeur de l'enregistrement
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-block text-right card-footer">
                <button type="button" data-toggle="button" aria-pressed="true" class="btn btn-lg btn-link active float-left" @click="changeTab('tab_table')">
                  <i class="pe-7s-back"></i>
                  Retour à la liste
                </button>
                <button type="button" class="btn mr-2 btn-transition btn-outline-danger btn-lg" @click="clearFilters()">
                  Supprimer les filtres
                </button>
                <button class="btn btn-hover-shine btn-transition btn-primary btn-lg" type="submit">
                  Appliquer les filtres
                </button>
              </div>
            </form>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import Index from '../mixins/Index'

  const fields = [
    {
      key: "check",
      label: "",
      sortable: false,
      tdClass: 'fit text-center'
    },
    {
      key: "role",
      label: "Rôle",
      sortable: true
    },
    {
      key: "organization_title",
      label: "Organisme",
      sortable: false
    },
    {
      key: "contact",
      label: "Contact",
      sortable: false
    },
    {
      key: "suspended_at",
      label: "Compte actif",
      sortable: true,
    },
    {
      key: "created_at",
      label: "Compte créé le",
      sortable: true
    },
    {
      key: "last_sign_in_at",
      label: "Dernière connexion le",
      sortable: true
    },
    {
      key: "actions",
      label: '',
      sortable: false,
      tdClass: 'fit'
    }
  ]

  export default {
    mixins: [
      Index
    ],
    props: {
      roles: {
        type: Array,
        default: () => []
      },
      organizations: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        fields
      }
    },
    computed: {
      selectionLabel() {
        switch(this.selection.length) {
          case 0:
            return 'Aucune sélection'
          case 1:
            return '1 utilisateur sélectionné'
          default:
            return `${this.selection.length} utilisateurs sélectionnés`
        }
      }
    },
  }
</script>
