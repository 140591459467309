<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} une enseigne
            </div>
            <error-messages :messages="messages" />
            <div class="py-3 px-4">
              <h5 class="card-title">CONTEXTE</h5>
              <div class="form-row">
                <div class="col-md-12">
                  <label>Nom de l'organisme*</label>
                  <div class="position-relative form-group">
                    <b-form-select
                      name="organization_id"
                      :disabled="readonly"
                      :options="organizations"
                      v-model="model.organization_id"
                      v-validate="'required'"
                      :state="state('organization_id')"
                      size="sm"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('organization_id') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </div>
            <hr class="m-0">
            <div class="py-3 px-4">
              <h5 class="card-title">INFORMATIONS</h5>
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label>Nom de l'enseigne*</label>
                  <b-form-input
                    :readonly="readonly"
                    name="brand_title"
                    type="text"
                    class="form-control form-control-sm"
                    v-model="model.title"
                    v-validate="'required|uniqueness:title'"
                    data-vv-delay="500"
                    :state="state('brand_title')"
                  />
                  <b-form-invalid-feedback>
                    {{ errors.first('brand_title') }}
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6">
                  <label>Clé*</label>
                  <div class="position-relative form-group">
                    <b-form-input
                      :readonly="readonly"
                      name="brand_key"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.key"
                      v-validate="'required|uniqueness:key'"
                      data-vv-delay="500"
                      :state="state('brand_key')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('brand_key') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Ordre*</label>
                  <div class="position-relative form-group">
                    <b-form-input
                      :readonly="readonly"
                      name="brand_position"
                      type="number"
                      min="0"
                      v-validate="'required|min_value:0'"
                      class="form-control form-control-sm"
                      v-model="model.position"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('brand_position') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <item-information :value="value" />
            </div>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit()"
              >
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import UniquenessValidator from '../mixins/UniquenessValidator'

  export default {
    props: {
      organizations: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        params: {}
      }
    },
    created() {
      this.params = {
        organization_id: this.model.organization_id
      }
    },
    mixins: [
      Form,
      UniquenessValidator
    ],
    watch: {
      'model.organization_id': _.debounce(function() {
        this.$set(this.params, 'organization_id', this.model.organization_id)
        this.$validator.validate('brand_title')
      }, 500),
      'model.key': _.debounce(function() {
        this.params = {}
      }, 500)
    }
  }
</script>
