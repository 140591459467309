<template>
  <div>
    <h5 class="card-title">Tarif</h5>
    <div class="form-row">
      <div class="col-md-12">
        <label>Tarif</label>
        <b-form-select
          :disabled="readonly"
          v-model="selectedPricingId"
          :options="pricings.options"
          @change="setCurrentUnitPricing"
          size="sm">
        </b-form-select>
      </div>
    </div>
    <hr class="mt-4">
    <h5 class="card-title">Montants pour le tarif sélectionné : {{ pricingTitle }}</h5>
    <div class="form-row mb-3">
      <div class="col-md-4">
        <label>Loyer Hors Charges*</label>
        <b-input-group size="sm" append="€">
          <b-form-input
            :readonly="readonly"
            type="number"
            min="0"
            v-model="unitPricing.rent_amount_without_charges"
            name="unit_rent_amount_without_charges"
            v-validate="'required'"
            :state="state('unit_rent_amount_without_charges')"
            @focus="$event.target.select()" />
        </b-input-group>
      </div>
      <div class="col-md-4">
        <label>Forfait charges</label>
        <b-input-group size="sm" append="€">
          <b-form-input
            :readonly="readonly"
            type="number"
            min="0"
            v-model="unitPricing.charges_amount"
            @focus="$event.target.select()" />
        </b-input-group>
      </div>
      <div class="col-md-4">
        <label>Prestations Annexes*</label>
        <b-input-group size="sm" append="€">
          <b-form-input
            :readonly="readonly"
            type="number"
            min="0"
            v-model="unitPricing.annex_rent_amount"
            name="unit_annex_rent_amount"
            v-validate="'required'"
            :state="state('unit_annex_rent_amount')"
            @focus="$event.target.select()" />
        </b-input-group>
      </div>
    </div>
    <div class="form-row mb-3">
      <div class="col-md-4">
        <label>Loyer TTC & TCC*</label>
        <b-input-group size="sm" append="€">
          <b-form-input
            :readonly="readonly"
            type="number"
            min="0"
            v-model="unitPricing.rent_amount"
            name="unit_rent_amount"
            v-validate="'required'"
            :state="state('unit_rent_amount')"
            @focus="$event.target.select()" />
        </b-input-group>
      </div>
      <div class="col-md-4">
        <label>Dépôt de garantie*</label>
        <b-input-group size="sm" append="€">
          <b-form-input
            :readonly="readonly"
            type="number"
            min="0"
            v-model="unitPricing.deposit_amount"
            name="unit_deposit_amount"
            v-validate="'required'"
            :state="state('unit_deposit_amount')"
            @focus="$event.target.select()" />
        </b-input-group>
      </div>
      <div class="col-md-4">
        <label>Prestations d'entrée</label>
        <b-input-group size="sm" append="€">
          <b-form-input
            :readonly="readonly"
            type="number"
            min="0"
            v-model="unitPricing.packages_amount"
            @focus="$event.target.select()" />
        </b-input-group>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4">
        <label>Loyer accessoire</label>
        <b-input-group size="sm" append="€">
          <b-form-input
            :readonly="readonly"
            type="number"
            min="0"
            v-model="unitPricing.auxiliary_amount"
            @focus="$event.target.select()" />
        </b-input-group>
      </div>
      <div class="col-md-4">
        <label>Frais électricité</label>
        <b-input-group size="sm" append="€">
          <b-form-input
            :readonly="readonly"
            type="number"
            min="0"
            v-model="unitPricing.electricity_costs"
            @focus="$event.target.select()" />
        </b-input-group>
      </div>
      <div class="col-md-4">
        <label>Caution</label>
        <b-input-group size="sm" append="€">
          <b-form-input
            :readonly="readonly"
            type="number"
            min="0"
            v-model="unitPricing.additional_deposit_amount"
            @focus="$event.target.select()" />
        </b-input-group>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'

  export default {
    mixins: [
      Form
    ],
    props: {
      pricings: {
        type: Object,
        default: () => {}
      },
      new_unit_pricing: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        unitPricing: {},
        selectedPricingId: null
      }
    },
    computed: {
      pricingTitle() {
        return this.pricings.options.find(opt => opt.value === this.selectedPricingId).text
      },
      isNew() {
        return !_.some(this.model.unit_pricings_attributes, { unit_id: this.unitPricing.unit_id })
      }
    },
    created() {
      this.selectedPricingId = this.pricings.current.id
      this.setCurrentUnitPricing(this.selectedPricingId)
      if (this.model.unit_pricings_count === 0) {
        this.model.unit_pricings_attributes.push(this.unitPricing)
      }
    },
    methods: {
      setCurrentUnitPricing() {
        let unitPricing = this.model.unit_pricings_attributes.find(up => up.pricing_id === this.selectedPricingId)
        if (unitPricing) {
          this.unitPricing = unitPricing
        } else {
          this.unitPricing = this.new_unit_pricing
          this.unitPricing.pricing_id = this.selectedPricingId
        }
      }
    },
    watch: {
      selectedPricingId(id) {
        if (this.model.unit_pricings_count > 0) {
          let alreadySet = _.some(this.model.unit_pricings_attributes, { unit_id: null })
          this.$nextTick(() => {
            if (this.isNew && !alreadySet) {
              this.model.unit_pricings_attributes.push(this.unitPricing)
            }
            if (!this.isNew && alreadySet) {
              _.remove(this.model.unit_pricings_attributes, up => up.unit_id === null)
            }
          })
        } else {
          this.unitPricing.pricing_id = id
        }
      }
    }
  }
</script>
