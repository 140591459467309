<template>
  <div>
    <div v-for="(services, category_title, idx) in groupedServices" :key="'groupedService' + idx">
      <hr class="mt-3" v-if="idx !== 0">
      <h5 class="card-title">{{ category_title }}</h5>
      <div v-for="service in services" v-if="isReadonly(service.id)" :key="'service' + service.id">
        <div class="form-row mt-2 align-items-center">
          <div class="col-md-5">
            <b-form-checkbox
              :disabled="readonly"
              :value="service.id"
              v-model="selectedServicesIds"
              @change="setServiceId($event, service.category)">
              {{ service.title }}
            </b-form-checkbox>
          </div>
          <div class="col-md-2 mt-1" v-if="service.category === 'paid'">
            <b-input-group size="sm" append="€">
              <b-form-input
                :disabled="disabledPrice(service.id)"
                :name="'service_price_' + service.id"
                type="number"
                min="0"
                v-model="service.default_price"
                v-validate="priceValidation(service.id)"
                data-vv-as=" "
                :state="priceState(service.id)"
                @focus="$event.target.select()"
                @input="setPrice($event, service.id)" />
            </b-input-group>
          </div>
          <b-form-invalid-feedback class="d-block col-md-5">
            {{ errors.first('service_price_' + service.id) }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'

  export default {
    mixins: [
      Form
    ],
    props: {
      services_options: {
        type: Array,
        default: () => []
      },
      new_residence_service: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        services: _.clone(this.services_options),
        current_new: _.clone(this.new_residence_service),
        selectedServicesIds: []
      }
    },
    computed: {
      groupedServices() {
        return _.groupBy(this.services, service => service.category_title)
      }
    },
    created() {
      this.setResidenceServices()
    },
    methods: {
      setResidenceServices() {
        this.model.residence_services_attributes.forEach(rs => {
          let service = this.services.find(service => service.id === rs.service_id)
          if (service) {
            this.selectedServicesIds.push(service.id)
            this.$set(service, 'default_price', rs.price)
          }
        })
      },
      isReadonly(id) {
        return _.includes(this.model.residence_services_attributes.map(rs => rs.service_id), id) || !this.readonly
      },
      setServiceId(id, category) {
        if (id) {
          let rService = this.model.residence_services_attributes.find(rs => rs.service_id === id)
          if (rService) {
            rService._destroy = 0
          } else {
            this.current_new.service_id = id
            this.current_new.price = this.services.find(s => s.id === id).default_price
            this.model.residence_services_attributes.push(this.current_new)
            this.current_new = _.clone(this.new_residence_service)
          }
          this.$nextTick(() => category === 'paid' && this.$validator.validate('service_price_' + id) )
        } else {
          let ids = this.model.residence_services_attributes.map(rs => rs.service_id)
          this.$nextTick(() => {
            let excludedId = _.difference(ids,this.selectedServicesIds)[0]
            let rService = this.model.residence_services_attributes.find(rs => rs.service_id === excludedId)
            rService._destroy = 1
          })
        }
      },
      setPrice(value, id) {
        let rService = this.model.residence_services_attributes.find(rs => rs.service_id === id)
        rService.price = value
      },
      disabledPrice(id) {
        return !_.includes(this.selectedServicesIds, id) || this.readonly
      },
      priceValidation(id) {
        return _.includes(this.selectedServicesIds, id) ? 'required|min_value:0' : null
      },
      priceState(id) {
        return _.includes(this.selectedServicesIds, id) ? this.state('service_price_' + id) : null
      }
    }
  }
</script>
