<template>
  <div>
    <hr class="my-3">
    <h5 class="card-title">ENREGISTREMENT</h5>
    <div class="form-row">
      <div class="col-md-12">
        <label>
          Créé le
          <span class="mb-2 mr-2 badge badge-secondary">{{ model.created_at | moment('DD/MM/YYYY à HH:mm:ss') }}</span>
        </label>
        <label v-if="model.creator">
          par
          <span class="mb-2 mr-2 badge badge-secondary">{{ model.creator.first_name }} {{ model.creator.last_name }}</span>
        </label>
      </div>
      <div class="col-md-12">
        <label>
          Mis à jour le
          <span class="mb-2 mr-2 badge badge-secondary">{{ model.updated_at | moment('DD/MM/YYYY à HH:mm:ss') }}</span>
        </label>
        <label v-if="model.editor">
          par
          <span class="mb-2 mr-2 badge badge-secondary">{{ model.editor.first_name }} {{ model.editor.last_name }}</span>
        </label>
      </div>
      <div v-if="typeof(model.grpd_consent_given_at) !== 'undefined'" class="col-md-12">
        <label>
          Consentement donné le
          <span class="mb-2 mr-2 badge badge-secondary">{{ model.grpd_consent_given_at | moment('DD/MM/YYYY à HH:mm:ss') }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        model: this.value
      }
    }
  }

</script>
