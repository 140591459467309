<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} une école
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab title="Général" active>
                <h5 class="card-title">INFORMATIONS</h5>
                <div class="form-row">
                  <div class="col-md-4">
                    <label :class="{'text-success': model.type_id}">Type d'établissement*</label>
                    <b-form-select
                      :disabled="readonly"
                      name="school_type_id"
                      v-model="model.type_id"
                      v-validate="'required'"
                      :options="types"
                      size="sm">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez un type d'établissement</option>
                      </template>
                    </b-form-select>
                  </div>
                  <div class="col-md-4">
                    <label>Nom*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="school_title"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.title"
                      v-validate="'required'"
                      :state="state('school_title')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('school_title') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Sigle</label>
                    <b-form-input
                      :readonly="readonly"
                      name="school_acronym"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.acronym"
                      :state="state('school_acronym')"
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Université</label>
                    <b-form-input
                      :readonly="readonly"
                      name="school_university"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.university"
                      :state="state('school_university')"
                    />
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Adresse*</label>
                    <b-form-textarea
                      :readonly="readonly"
                      name="school_address"
                      class="form-control form-control-sm"
                      v-model="model.address"
                      v-validate="'required'"
                      :state="state('school_address')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('school_address') }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-md-4">
                    <label>Code postal*</label>
                    <b-form-input
                      :readonly="readonly"
                      v-mask="'#####'"
                      name="school_zip_code"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.zip_code"
                      v-validate="'required'"
                      :state="state('school_zip_code')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('school_zip_code') }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-md-4">
                    <label>Ville*</label>
                    <b-form-input
                      :readonly="readonly"
                      name="school_city"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.city"
                      v-validate="'required'"
                      :state="state('school_city')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first('school_city') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Téléphone</label>
                    <PhoneInput
                      v-model="model.phone_number"
                      :code=" model.phone_country_code"
                      name="phone_number"
                      @code="setCountryCode($event, 'phone_country_code')"
                      :readonly="readonly" />
                    <b-form-text>
                      Au format international : +33 0 00 00 00 00
                    </b-form-text>
                  </div>
                  <div class="col-md-4">
                    <label>Académie</label>
                    <b-form-input
                      :readonly="readonly"
                      name="school_academy"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.academy"
                      :state="state('school_academy')"
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Région</label>
                    <b-form-input
                      :readonly="readonly"
                      name="school_region"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.region"
                      :state="state('school_region')"
                    />
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Longitude</label>
                    <div class="position-relative form-group">
                      <b-form-input
                        :readonly="readonly"
                        name="school_longitude"
                        type="number"
                        class="form-control form-control-sm"
                        v-model="model.longitude"
                        :state="state('school_longitude')"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label>Latitude</label>
                    <div class="position-relative form-group">
                      <b-form-input
                        :readonly="readonly"
                        name="school_latitude"
                        type="number"
                        class="form-control form-control-sm"
                        v-model="model.latitude"
                        :state="state('school_latitude')"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label>URL</label>
                    <b-form-input
                      :readonly="readonly"
                      name="school_url_onisep"
                      type="text"
                      placeholder="https://"
                      class="form-control form-control-sm"
                      v-model="model.url_onisep"
                      v-validate="'url'"
                      :state="state('school_url_onisep')"
                    />
                  </div>
                </div>
                <item-information :value="value" />
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit()"
              >
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'

  export default {
    mixins: [
      Form
    ],
    props: {
      types: {
        type: Array,
        default: () => []
      }
    }
  }
</script>
