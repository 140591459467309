<template>
  <div>
    <h5 class="card-title">
      Services payants
    </h5>
    <b-alert variant="info" :show="model.unit_id == null">
      Les services payants apparaîtront dans cet onglet lorsque vous aurez attribué un lot.
    </b-alert>
    <div v-for="residenceService in residenceServices" :key="'service' + residenceService.id">
      <div class="form-row align-items-center">
        <div class="col-md-4">
          <b-form-checkbox
            name="record[residence_service_ids]"
            :disabled="readonly || !residenceServicesEditable"
            :value="residenceService.id"
            v-model="model.residence_service_ids">
            {{ residenceService.service_title }}
          </b-form-checkbox>
        </div>
        <div class="col-md-2 mt-1">
          <b-input-group size="sm" append="€">
            <b-form-input
              disabled
              :value="price(residenceService)" />
          </b-input-group>
        </div>
        <div class="d-block col-md-6">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import Form from '../mixins/Form'

  export default {
    mixins: [
      Form
    ],
    props: {
      units: Array,
      residenceServicesUrl: String,
      subscribedServices: Array,
      residenceServicesEditable: Boolean
    },
    data() {
      return {
        residenceServices: null
      }
    },
    computed: {
      unit_id() {
        return this.model.unit_id
      }
    },
    methods: {
      fetchResidenceServices() {
        if (this.unit_id != null) {
          axios.get(
            this.residenceServicesUrl,
            {
              params: {
                unit_id: this.unit_id,
                options: true
              }
            }
          ).then((response) => {
            this.residenceServices = response.data
          })
        }
      },
      price(residenceService) {
        let result = _.find(this.subscribedServices, { service_id: residenceService.id })
        if (result == null) {
          result = _.find(this.residenceServices, { id: residenceService.id })
        }
        return result.price
      }
    },
    watch: {
      unit_id: {
        immediate: true,
        handler: 'fetchResidenceServices'
      }
    }
  }
</script>
