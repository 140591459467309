<template>
  <div class="app-header header-shadow">
    <div class="logo-src"/>
    <div class="app-header__content">
      <div class="app-header-right">
        <HeaderDots
          :messages_url="messages_url"
          :residences="residences"
        />
        <UserArea
          :user="user"
          :role="role"
          :edit_url="edit_url"
          :logout_url="logout_url" />
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" v-bind:class="{ 'is-active' : isOpen }" @click="toggleMobile('closed-sidebar-open')">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <span>
        <b-button class="btn-icon btn-icon-only" variant="primary" size="sm" v-bind:class="{ 'active' : isOpenMobileMenu }" @click="toggleMobile2('header-menu-open')">
          <div class="btn-icon-wrapper">
            <font-awesome-icon icon="ellipsis-v" />
          </div>
        </b-button>
      </span>
    </div>
  </div>
</template>

<script>
  import HeaderDots from './HeaderDots';
  import UserArea from './HeaderUserArea';
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

  library.add(
    faEllipsisV,
  );
  export default {
    name: "Header",
    components: {
      HeaderDots,
      UserArea,
      'font-awesome-icon': FontAwesomeIcon
    },
    props: {
      user: {
        type: Object,
        required: true
      },
      role: {
        type: String,
        required: true
      },
      edit_url: {
        type: String,
        required: true
      },
      logout_url: {
        type: String,
        required: true
      },
      messages_url: {
        type: String,
        required: true
      },
      residences: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        isOpen: false,
        isOpenMobileMenu: false
      }
    },
    methods: {
      toggleMobile(className) {
        const el = document.body;
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      },
      toggleMobile2(className) {
        const el = document.body;
        this.isOpenMobileMenu = !this.isOpenMobileMenu;
        if (this.isOpenMobileMenu) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      }
    }
  }
</script>
