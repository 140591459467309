<script>
  export default {
    created() {
      this.$validator.extend('json', {
        getMessage: field => "Format JSON invalide.",
        validate: (value) => {
          try {
            JSON.parse(value)
            return true
          } catch (error) {
            return false
          }
        }
      })
    }
  }
</script>
