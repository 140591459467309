<script>
  import axios from 'axios'

  export default {
    created() {
      this.$validator.extend('uniqueness', {
        getMessage: field => "N'est pas disponible.",
        validate: (value, field) => {
          if (this.search_url) {
            return axios.get(this.search_url, {
              params: {
                [field]: value,
                uniqueness: true,
                id: this.value.id,
                ...this.params
              }
            }).then(resp => {
              let scoped
              if (_.isEmpty(this.params)) {
                scoped = this.value[field] === value
              } else {
                let key = _.keys(this.params)[0]
                scoped = this.value[field] === value && this.value[key] === this.params[key]
              }
              return {
                valid: _.isEmpty(resp.data.items) || scoped
              }
            })
          } else {
            return true
          }
        }
      })
    }
  }
</script>
