<script>
  import Vue from 'vue/dist/vue.esm'
  import axios from 'axios'
  import Multiselect from 'vue-select'
  import VueMask from 'v-mask'
  import _ from 'lodash'
  import PictureForm from '../base/PictureForm'
  import PhoneInput from '../base/PhoneInput'
  import ErrorMessages from '../base/ErrorMessages'
  import ItemInformation from '../base/ItemInformation'

  Vue.use(VueMask)

  export default {
    $_veeValidate: {
      validator: 'new'
    },
    inject: [
      '$validator'
    ],
    components: {
      Multiselect,
      PictureForm,
      PhoneInput,
      ErrorMessages,
      ItemInformation
    },
    props: {
      name: String,
      action: String,
      method: String,
      value: {
        type: Object,
        required: true
      },
      cancel_url: String,
      search_url: String,
      readonly: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        messages: [],
        model: this.value,
        disableSubmit: false
      }
    },
    computed: {
      action_type() {
        if (this.readonly) return 'Consulter'
        if (this.method === 'patch') return 'Modifier'
        if (this.method === 'post') return 'Ajouter'
      },
      disabled() {
        return this.invalid() || this.disableSubmit
      }
    },
    methods: {
      submit() {
        axios({
          method: this.method,
          url: this.action,
          data: this.formData()
        }).then(response => {
          document.location = response.headers.location
        }).catch(error => {
          if (error.response.status === 422) {
            this.messages = error.response.data.errors
          }
        })
      },
      invalid() {
        return !this.readonly && _.some(_.keys(this.inputs), key => this.inputs[key].invalid)
      },
      state(ref) {
        if (this.inputs[ref] && (this.inputs[ref].dirty || this.inputs[ref].validated)) {
          return !this.errors.has(ref)
        }
        return null
      },
      formData() {
        // Transforme un objet JS en FormData, compatible avec l'envoi de fichier et les nested attributes de Rails
        // TODO: À refactorer (en mode récursif ?)
        const data = new FormData()
        for (let key in this.model) {
          let value = this.model[key]
          if (value !== null) {
            if (value instanceof Array) {
              value.forEach((item) => {
                if (item instanceof Object) {
                  for (let entry in item) {
                    if (item[entry] !== null) {
                      if (item[entry] instanceof Array) {
                        item[entry].forEach((sub_item) => {
                          for (let sub_entry in sub_item) {
                            if (sub_item[sub_entry] !== null) {
                              data.append(`${this.name}[${key}][][${entry}][][${sub_entry}]`, sub_item[sub_entry])
                            }
                          }
                        })
                      } else {
                        data.append(`${this.name}[${key}][][${entry}]`, item[entry])
                      }
                    }
                  }
                } else {
                  data.append(`${this.name}[${key}][]`, item)
                }
              })
            } else {
              data.append(`${this.name}[${key}]`, value)
            }
          }
        }
        return data
      },
      setCountryCode(value, field) {
        this.model[field] = value
        if (this.messages.length > 0) {
          this.messages = []
        }
      }
    }
  }
</script>
