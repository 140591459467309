<script>
  import _ from 'lodash'
  import axios from 'axios'
  import Tabs from 'vue-tabs-with-active-line'
  import Datepicker from '../base/DatePicker'

  export default {
    components: {
      Tabs,
      Datepicker
    },
    props: {
      url: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        tabs: [{
          title: 'Tableau',
          value: 'tab_table'
        }, {
          title: 'Filtres',
          value: 'tab_filters'
        }],
        currentTab: 'tab_table',
        loading: false,
        items: [],
        selection: [],
        allSelected: false,
        page: 1,
        per: 25,
        count: 0,
        total: 0,
        order: 'created_at',
        direction: 'desc',
        filters: {},
        search: '',
        pagination_options: [10, 25, 50, 100, 500],
        watch_active: true,
        reset_page: false
      }
    },
    mounted() {
      this.queryStringToFilters()
      this.filtersToQueryString()
      this.fetch()
    },
    computed: {
      activeFiltersCount() {
        let activeFilters = _.reject(_.values(this.filters), (value) => {
          return (_.isNil(value) || value == '')
        })
        return activeFilters.length
      }
    },
    methods: {
      exportUrl(format = 'xlsx') {
        return `${this.url}.${format}${location.search}&ids=${_.map(this.selection, 'id').join(',')}`
      },
      queryStringToFilters() {
        this.watch_active = false
        let query = location.search
        let params = localStorage.getItem(location.pathname)
        if (query == '' && params != null) {
          params = new URLSearchParams(params)
          this.replaceHistoryState(params)
        } else {
          params = new URLSearchParams(query.slice(1))
        }
        for (let param of params) {
          if (param[0] == 'per') {
            this.$set(this, 'per', decodeURIComponent(param[1] || 25), false)
          } else if (param[0] == 'search') {
            this.$set(this, 'search', decodeURIComponent(param[1] || ''), false)
          } else if (param[0] == 'page') {
            this.$set(this, 'page', decodeURIComponent(param[1] || 1), false)
          } else if (param[0] == 'order') {
            this.$set(this, 'order', decodeURIComponent(param[1] || 'created_at'), false)
          } else if (param[0] == 'direction') {
            this.$set(this, 'direction', decodeURIComponent(param[1] || 'desc'), false)
          } else {
            this.$set(this.filters, param[0], decodeURIComponent(param[1] || ''))
          }
        }
        this.$nextTick(() => {
          this.watch_active = true
          // Pour une raison inconnue, <b-pagination v-model="page"> n'est pas pris en compte
          this.$refs.pagination.currentPage = this.page
        })
      },
      filtersToQueryString() {
        let params = new URLSearchParams()
        for (let key in this.filters) {
          if (this.filters.hasOwnProperty(key)) {
            params.set(key, this.filters[key])
          }
        }
        params.set('per', this.per)
        params.set('search', this.search)
        params.set('page', this.page)
        params.set('order', this.order)
        params.set('direction', this.direction)
        localStorage.setItem(location.pathname, params)
        this.replaceHistoryState(params)
      },
      replaceHistoryState(params) {
        window.history.replaceState({}, "", decodeURIComponent(`${location.pathname}?${params}`))
      },
      fetch(clearSelection = true) {
        if (this.loading) return
        this.loading = true
        axios.get(this.url, {
          params: {
            per: this.per,
            page: this.page,
            order: this.order,
            direction: this.direction,
            search: this.search,
            ...this.filters
          }
        }).then(response => {
          this.items = response.data.items
          this.count = response.data.pagination.count
          this.total = response.data.pagination.vars.total
          this.loading = false
          this.reset_page = true
          this.allSelected = false
          if (clearSelection) {
            this.clearSelection()
          }
        })
      },
      applyFilters() {
        this.page = 1
        this.filtersToQueryString()
        this.fetch()
        this.changeTab('tab_table')
      },
      clearFilters() {
        this.filters = {}
        this.page = 1
        this.search = ''
        this.per = 25
        this.filtersToQueryString()
        this.fetch()
        this.changeTab('tab_table')
      },
      clearSelection() {
        this.selection = []
        this.allSelected = false
      },
      toggleAll(checked) {
        if (checked) {
          this.selection = _.concat(this.selection, this.items)
        } else {
          this.clearSelection()
        }
      },
      changeTab(tab) {
        this.currentTab = tab
      },
      sortChanged(sorting) {
        this.order = sorting.sortBy
        this.direction = sorting.sortDesc ? 'desc' : 'asc'
        this.fetch()
      },
      destroy(url) {
        axios.delete(url).then((response) => {
          document.location = this.url
        })
      }
    },
    watch: {
      order() {
        if (!this.watch_active) return
        if (this.reset_page) this.page = 1
        this.filtersToQueryString()
        this.fetch()
      },
      direction() {
        if (!this.watch_active) return
        if (this.reset_page) this.page = 1
        this.filtersToQueryString()
        this.fetch()
      },
      per() {
        if (!this.watch_active) return
        if (this.reset_page) this.page = 1
        this.filtersToQueryString()
        this.fetch()
      },
      page() {
        if (!this.watch_active) return
        this.filtersToQueryString()
        this.fetch(false)
      },
      search: _.debounce(function() {
        if (!this.watch_active) return
        if (this.reset_page) this.page = 1
        this.filtersToQueryString()
        this.fetch()
      }, 500)
    }
  }
</script>
