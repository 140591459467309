<template>
  <div class="col-md-12 mt-4" v-if="messages.length > 0">
    <b-alert show variant="danger">
      <i class="fas fa-exclamation-circle"></i>
      <b>Des erreurs ont empêché la validation du formulaire&nbsp;:</b>
      <ul class="list-unstyled mt-1 mb-0">
        <li v-for="message in messages">
          {{ message }}
        </li>
      </ul>
    </b-alert>
  </div>
</template>

<script>
  export default {
    props: {
      messages: Array
    }
  }
</script>
