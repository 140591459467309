<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} {{ title }}
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab active :disabled="manager" @click="disableSubmit = false">
                <template slot="title">
                  Général <i class="small fas fa-lock" v-if="manager"></i>
                </template>
                <h5 class="card-title">INFORMATIONS</h5>
                <div class="form-row">
                  <div class="col-md-12">
                    <label>Type*</label>
                    <b-form-select
                      :disabled="readonly"
                      v-model="model.type"
                      :options="units"
                      size="sm"
                      name="unit_type"
                      v-validate="'required'"
                      :state="state('unit_type')">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez un type</option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback>
                      {{ errors.first('unit_type') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-12">
                    <label>Résidence*</label>
                    <b-form-select
                      :disabled="readonly"
                      v-model="model.residence_id"
                      :options="residences"
                      size="sm"
                      name="unit_residence"
                      v-validate="'required'"
                      :state="state('unit_residence')">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez une résidence</option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback>
                      {{ errors.first('unit_residence') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-12">
                    <label>Type de lot*</label>
                    <b-form-select
                      :disabled="disableUT"
                      v-model="model.unit_type_id"
                      :options="formattedUT"
                      size="sm"
                      name='unit_UT'
                      v-validate="'required'"
                      :state="state('unit_UT')">
                      <template slot="first">
                        <option :value="null" disabled>Choisissez un type de lot</option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback>
                      {{ errors.first('unitUT') }}
                    </b-form-invalid-feedback>
                    <b-form-text v-if="!model.type">
                      Veuillez sélectionner un type avant de pouvoir choisir un type de lot.
                    </b-form-text>
                  </div>
                </div>
                <hr class="mt-3">
                <UnitFormDesc
                  :readonly="readonly"
                  :value="value"
                  :accommodation="isAccommodation"
                  :search_url="search_url"
                  :floors="floors"
                  :residence_id="model.residence_id" />
                <hr class="mt-3">
                <UnitFormFeatures
                  :readonly="readonly"
                  :value="value"
                  :accommodation="isAccommodation" />
                <hr class="mt-3">
                <UnitFormInfos
                  :readonly="readonly"
                  :value="value"
                  :accommodation="isAccommodation" />
                <item-information :value="value" />
              </b-tab>
              <b-tab :disabled="manager" @click="disableSubmit = false">
                <template slot="title">
                  Loyer <i class="small fas fa-lock" v-if="manager"></i>
                </template>
                <UnitFormRent
                  :readonly="readonly"
                  :value="value"
                  :pricings="pricings"
                  :new_unit_pricing="new_unit_pricing" />
              </b-tab>
              <b-tab :disabled="manager" @click="disableSubmit = true">
                <template slot="title">
                  Photos <i class="small fas fa-lock" v-if="manager || method === 'post'"></i>
                </template>
                <div v-if="!readonly">
                  <PictureForm :action="action" :method="method" :name="name" title="lot" />
                </div>
              </b-tab>
              <b-tab title="Disponibilités">
                <h5 class="card-title">Disponibilités</h5>
                <div class="form-row mt-3">
                  <div class="col-md-12">
                    <label>Disponibilité*</label>
                    <b-form-select
                      :disabled="readonly"
                      v-model="model.status"
                      :options="statuses"
                      size="sm">
                    </b-form-select>
                    <b-form-text>
                      {{ statusDescription }}
                    </b-form-text>
                    <hr class="mt-3">
                    <AssociatedRecord
                      title="Dossier en cours"
                      v-model="model.record" />
                    <hr class="mt-3">
                    <AssociatedRecord
                      title="Historique des dossiers passés en gestion"
                      v-model="model.records" />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="disabled"
                @click.stop.prevent="submit">
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import UnitFormDesc from './UnitFormDesc'
  import UnitFormFeatures from './UnitFormFeatures'
  import UnitFormInfos from './UnitFormInfos'
  import UnitFormRent from './UnitFormRent'
  import AssociatedRecord from '../base/AssociatedRecord'

  export default {
    mixins: [
      Form
    ],
    components: {
      UnitFormDesc,
      UnitFormFeatures,
      UnitFormInfos,
      UnitFormRent,
      AssociatedRecord
    },
    props: {
      residences: {
        type: Array,
        default: () => []
      },
      unit_types: {
        type: Array,
        default: () => []
      },
      pricings: {
        type: Object,
        default: () => {}
      },
      statuses: {
        type: Array,
        default: () => []
      },
      status_descriptions: {
        type: Array,
        default: () => []
      },
      floors: {
        type: Array,
        default: () => []
      },
      new_unit_pricing: {
        type: Object,
        default: () => {}
      },
      manager: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        units: [
          { value: 'Accommodation', text: 'Appartement' },
          { value: 'ParkingSpace', text: 'Place de parking' }
        ]
      }
    },
    computed: {
      isAccommodation() {
        return this.model.type === 'Accommodation'
      },
      isParking() {
        return this.model.type === 'ParkingSpace'
      },
      title() {
        if (this.isAccommodation) return 'un appartement'
        if (this.isParking) return 'une place de parking'
        return 'un lot'
      },
      disableUT() {
        return this.readonly || !this.model.type
      },
      formattedUT() {
        if (this.isAccommodation) {
          return _.filter(this.unit_types, unit => unit.key !== 'pk_interior' && unit.key !== 'pk_exterior')
        }
        if (this.isParking) {
          return _.filter(this.unit_types, unit => unit.key == 'pk_interior' || unit.key == 'pk_exterior')
        }
      },
      statusDescription() {
        return this.status_descriptions.find(desc => desc.value === this.model.status).text
      }
    },
    mounted() {
      let surfaces = ['surface', 'corrected_surface', 'annex_surface', 'total_surface', 'auxiliary_surface', 'balcony_or_terrace_surface']
      surfaces.forEach(surface => {
        if (!this.model[surface]) {
          this.model[surface] = 0
        }
      })
      if (this.method === 'post') {
        this.model.expired_term = true
      }
    }
  }
</script>
