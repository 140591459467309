<template>
  <v-select
    :value="selection"
    @search:focus="fetch"
    @input="select"
    :options="options"
    @search="fetch"
    :filterable="false"
    :disabled="disabled"
    :clearable="!disabled"
    :multiple="multiple"
    label="id"
  >
    <template slot="no-options">
      <span v-if="empty">
        Aucun résultat.
      </span>
      <span v-else>
        {{ placeholder }}
      </span>
    </template>
    <template slot="option" slot-scope="option">
      <slot name="option" v-bind="option" />
    </template>
    <template slot="selected-option" slot-scope="option">
      <slot name="selected-option" v-bind="option" />
    </template>
  </v-select>
</template>

<script>
  import _ from 'lodash'
  import axios from 'axios'
  import vSelect from 'vue-select'

  export default {
    components: {
      vSelect
    },
    props: {
      value: [Number, String, Array],
      multiple: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: "Rechercher..."
      },
      url: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      order: {
        type: String,
        default: "created_at"
      },
      direction: {
        type: String,
        default: "desc"
      },
      per: {
        type: Number,
        default: 100
      },
      query: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        selection: null,
        options: [],
        empty: false
      }
    },
    created() {
      if (this.value) {
        let url = null
        if (this.multiple) {
          if (this.value.length == 0) return
          url = `${this.url}?ids=${this.value.join(',')}`
        } else {
          url = `${this.url}/${this.value}`
        }
        axios.get(url,
          {
            params: {
              options: true
            }
          }
        ).then((response) => {
          this.selection = response.data
        })
      }
    },
    methods: {
      select(option) {
        this.selection = option
        if (this.selection == null) {
          this.$emit('input', null)
        } else {
          if (this.multiple) {
            this.$emit('input', this.selection.map(s => s.id))
          } else {
            this.$emit('input', this.selection.id)
          }
        }
        this.$emit('change')
      },
      fetch: _.debounce(function(search, loading) {
        if (search == '') {
          this.empty = false
        }
        if (loading) loading(true)
        axios.get(
          this.url,
          {
            params: {
              ...this.query,
              search: search,
              order: this.order,
              direction: this.direction,
              per: this.per,
              options: true
            }
          }
        ).then((response) => {
          this.options = response.data
          this.empty = this.options.length == 0
          if (loading) {
            loading(false)
          }
          this.$emit('results', this.options)
        })
      }, 200)
    }
  }
</script>
