<template>
  <div>
    <div v-for="(day, idx) in model.opening_days_attributes" :key="'day' + idx" v-if="isReadonly(day)">
      <h5 class="card-title">{{ dayTitle(day.day) }}</h5>
        <div class="form-row mt-2 mb-4">
          <div class="col-md-2">
            <b-form-checkbox
              class="pt-1"
              :disabled="readonly"
              v-model="day.opened">
              Ouvert
            </b-form-checkbox>
          </div>
          <div class="col-md-10">
            <div class="form-row">
              <div class="col-md-12 d-flex align-items-center">
                <div class="mr-5">Le matin de</div>
                <b-form-select
                  class="select-time"
                  size="sm"
                  :disabled="disabledTime(day)"
                  v-model="day.morning_begin_time"
                  :options="timeOptions(7, 13, day)"
                  :name="'morning_begin_time_' + day.day"
                  v-validate="{ not_after: day.morning_end_time }"
                  :state="state('morning_begin_time_' + day.day)">
                </b-form-select>
                <div class="mx-2">à</div>
                <b-form-select
                  class="select-time"
                  size="sm"
                  :disabled="disabledTime(day)"
                  v-model="day.morning_end_time"
                  :options="timeOptions(7, 13)"
                  :name="'morning_end_time_' + day.day"
                  v-validate="{ not_before: day.morning_begin_time}"
                  :state="state('morning_end_time_' + day.day)">
                </b-form-select>
              </div>
            </div>
            <div class="form-row mt-2">
              <div class="col-md-12 d-flex align-items-center">
                <div class="mr-4">L'après-midi de</div>
                <b-form-select
                  class="select-time"
                  size="sm"
                  :disabled="disabledTime(day)"
                  v-model="day.afternoon_begin_time"
                  :options="timeOptions(13, 22)"
                  :name="'afternoon_begin_time_' + day.day"
                  v-validate="{ not_after: day.afternoon_end_time}"
                  :state="state('afternoon_begin_time_' + day.day)">
                </b-form-select>
                <div class="mx-2">à</div>
                <b-form-select
                  class="select-time"
                  size="sm"
                  :disabled="disabledTime(day)"
                  v-model="day.afternoon_end_time"
                  :options="timeOptions(13, 22)"
                  :name="'afternoon_end_time_' + day.day"
                  v-validate="{ not_before: day.afternoon_begin_time}"
                  :state="state('afternoon_end_time_' + day.day)">
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import Form from '../mixins/Form'

  moment.locale('fr')

  export default {
    mixins: [
      Form
    ],
    created() {
      this.setDefaultTimes()
      this.$validator.extend('not_after', {
        validate: (value, arg) => {
          return {
            valid: moment(value, 'HH[h]mm').isBefore(moment(arg[0], 'HH[h]mm'))
          }
        }
      })
      this.$validator.extend('not_before', {
        validate: (value, arg) => {
          return {
            valid: moment(value, 'HH[h]mm').isAfter(moment(arg[0], 'HH[h]mm'))
          }
        }
      })
    },
    methods: {
      setDefaultTimes() {
        this.model.opening_days_attributes.forEach(day => {
          let morningBegin = day.morning_begin_time ? moment(day.morning_begin_time).format('HH[h]mm') : '09h00'
          day.morning_begin_time = morningBegin
          let morningEnd = day.morning_end_time ? moment(day.morning_end_time).format('HH[h]mm') : '12h00'
          day.morning_end_time = morningEnd
          let afternoonBegin = day.afternoon_begin_time ? moment(day.afternoon_begin_time).format('HH[h]mm') : '14h00'
          day.afternoon_begin_time = afternoonBegin
          let afternoonEnd = day.afternoon_end_time ? moment(day.afternoon_end_time).format('HH[h]mm') : '17h00'
          day.afternoon_end_time = afternoonEnd
        })
      },
      dayTitle(day) {
        return moment().days(day).format('dddd')
      },
      disabledTime(day) {
        return this.readonly || !day.opened
      },
      isReadonly(day) {
        return day.opened || !this.readonly
      },
      timeOptions(start, end) {
        let range = Array.apply(null, { length: (end - start) * 60 / 5 }).map((value, i) => {
          let hour = Math.floor(i * 5 / 60)
          let minutes = i * 5 - hour * 60
          return moment().hour(hour + start).minutes(minutes).format('HH[h]mm')
        })
        range.push(moment().hour(end).minutes(0).format('HH[h]mm'))
        return range
      }
    }
  }
</script>
