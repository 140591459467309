<template>
  <div class="card card-border border-info mb-1">
    <div class="card-header-tab card-header">
      <div class="card-header-title mr-4">
        Pièces jointes {{ typeTitle }} n°{{ index + 1 }}&nbsp;: {{ model.first_name }} {{ model.last_name }}
      </div>
      <small>{{ situationTitle }}</small>
    </div>
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Intitulé</th>
            <th>Fichier</th>
            <th class="text-center">Requis</th>
            <th class="text-center" v-if="!readonly">Outils</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(sdt, row) in relevantSituationDocumentTypes">
            <td>
              {{ sdt.document_type_title }}
            </td>
            <td>
              <b-form-file
                v-if="!readonly && documentAttributesModel(sdt).file_url == null"
                :name="`${prefix}_${index}_documents_attributes_${row}_file`"
                v-model="documentAttributesModel(sdt).file"
                size="xs"
                placeholder="Sélectionner un fichier..."
                drop-placeholder="Déposer un fichier..."
                browse-text="Parcourir"
              />
              <a v-else :href="documentAttributesModel(sdt).file_url" target="_blank">
                {{ documentAttributesModel(sdt).file_name }}
              </a>
            </td>
            <td class="text-center">
              <span :class="{'font-weight-bold text-danger': sdt.required}">
                {{ sdt.required ? 'Oui' : 'Non' }}
              </span>
            </td>
            <td class="text-center" v-if="!readonly">
              <b-button variant="outline-danger" size="sm" v-show="documentAttributesModel(sdt).file_url != null" @click="remove(sdt)">
                <i class="fa fa-trash"></i>
                Supprimer
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'

  export default {
    mixins: [
      Form
    ],
    props: {
      index: Number,
      situations: Array,
      situationDocumentTypes: Array
    },
    computed: {
      prefix() {
        return _.lowerCase(this.model.type)
      },
      typeTitle() {
        switch(this.model.type) {
          case 'Tenant':
            return 'locataire'
          case 'Guarantor':
            return 'garant'
        }
      },
      situationTitle() {
        return _.find(this.situations, { value: this.model.situation }).text
      },
      relevantSituationDocumentTypes() {
        return _.filter(this.situationDocumentTypes, (sdt) => {
          return (
            this.model.type == 'Tenant' && sdt.tenant_situation == this.model.situation ||
            this.model.type == 'Guarantor' && sdt.guarantor_situation == this.model.situation
          )
        })
      }
    },
    methods: {
      documentAttributesModel(sdt) {
        let model = _.find(this.model.documents_attributes, { document_type_id: sdt.document_type_id })
        if (model == null) {
          model = {
            document_type_id: sdt.document_type_id,
            file: null,
            _destroy: false
          }
          this.model.documents_attributes.push(model)
        }
        return model
      },
      remove(sdt) {
        let model = this.documentAttributesModel(sdt)
        _.merge(model, {
          file_url: null,
          _destroy: true
        })
      }
    }
  }
</script>

<style scoped>
  .border-info {
    border-left-style: solid;
    border-left-width: 50px;
    border-radius: 0px;
  }
</style>
