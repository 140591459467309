<template>
  <div>
    <b-form-input
      v-if="readonly"
      readonly
      size="sm"
      :value="value" />
    <PhoneNumberInput
      v-else
      v-bind="options"
      v-model="phone"
      :name="name"
      @update="setPhoneNumber"
      v-validate="validations" />
  </div>
</template>

<script>
  import PhoneNumberInput from 'vue-phone-number-input'
  import { parsePhoneNumberFromString } from 'libphonenumber-js'

  export default {
    components: {
      PhoneNumberInput
    },
    props: {
      value: {
        type: String,
        default: null
      },
      code: {
        type: String,
        default: null
      },
      name: {
        type: String
      },
      readonly: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      }
    },
    inject: ['$validator'],
    data() {
      return {
        phone: this.value,
        valid: false,
        options: {
          color: '#ced4da',
          validColor: '#3ac47d',
          defaultCountryCode: this.code,
          error: this.required,
          required: this.required,
          noFlags: true,
          size: 'sm',
          translations: {
            phoneNumberLabel: ''
          }
        }
      }
    },
    computed: {
      validations() {
        return this.required ? 'required|phone' : 'phone'
      }
    },
    created() {
      this.$validator.extend('phone', {
        getMessage: field => "Format de téléphone invalide.",
        validate: (value) => {
          return {
            valid: this.valid
          }
        }
      })
    },
    mounted() {
      if (this.phone && !this.readonly) {
        let parse = parsePhoneNumberFromString(this.phone)
        this.phone = parse.nationalNumber
      }
    },
    methods: {
      setPhoneNumber(payload) {
        this.valid = payload.isValid
        if (this.phone && this.inputs[this.name]) {
          this.$validator.validate(this.name)
        } else {
          this.valid = true
        }
        this.$emit('code', payload.countryCode)
      }
    },
    watch: {
      phone(value) {
        this.$emit('input', value)
      }
    }
  }
</script>
