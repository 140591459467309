<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} un dossier
              <div class="btn-actions-pane-right" v-if="readonly && (model.synthesis_url || model.tegia_synthesis_url || model.exportable)">
                <b-button v-if="model.synthesis_url" :href="model.synthesis_url" target="_blank" variant="outline-secondary">
                  <i class="far fa-file-pdf mr-1"></i>
                  Fiche synthèse
                </b-button>
                <b-button v-if="model.tegia_synthesis_url" :href="model.tegia_synthesis_url" target="_blank" variant="outline-secondary">
                  <i class="far fa-file-pdf mr-1"></i>
                  Fiche synthèse TEGIA
                </b-button>
                <b-button v-if="model.exportable" @click="toExport()" :disabled="model.to_export" variant="outline-secondary">
                  <i class="far fa-file mr-1"></i>
                  Réexporter
                </b-button>
              </div>
              <div class="btn-actions-pane-right" v-if="!readonly && model.id">
                <b-dropdown text="Options" variant="outline-primary" right>
                  <b-dropdown-item @click="newTenant()">
                    Ajouter un locataire
                  </b-dropdown-item>
                  <b-dropdown-item @click="newGuarantor()">
                    Ajouter un garant
                  </b-dropdown-item>
                  <b-dropdown-item @click="showInstitutionalGuarantor = true">
                    Ajouter une garantie locative
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab
                title="Général"
                :active="currentHash == '#general'"
                @click="submitEnabled = !readonly">
                <h5 class="card-title">Informations</h5>
                <div class="form-row">
                  <div class="col-md-6" v-if="model.number">
                    <label>N° de dossier</label>
                    <b-form-input
                      readonly
                      name="record_number"
                      type="text"
                      class="form-control form-control-sm"
                      v-model="model.number"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Partenaire</label>
                    <div class="position-relative form-group">
                      <search-select
                        :url="partnersUrl"
                        :disabled="readonly"
                        :state="state('record_partner_id')"
                        v-model="model.partner_id"
                        order="title"
                        direction="asc"
                      >
                        <template slot="selected-option" slot-scope="partner">
                          {{ partner.title }} ({{ partner.code }})
                        </template>
                        <template slot="option" slot-scope="partner">
                          {{ partner.title }} ({{ partner.code }})
                        </template>
                      </search-select>
                      <b-form-invalid-feedback>
                        {{ errors.first('record_partner_id') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>Client</label>
                    <div class="position-relative form-group">
                      <search-select
                        :url="customersUrl"
                        :disabled="readonly"
                        v-validate="'required'"
                        :state="state('record_customer_id')"
                        v-model="model.customer_id"
                        order="last_name"
                        direction="asc"
                      >
                        <template slot="selected-option" slot-scope="customer">
                          {{ customer.last_name }} {{ customer.first_name }}
                        </template>
                        <template slot="option" slot-scope="customer">
                          {{ customer.last_name }} {{ customer.first_name }}
                          <span class="text-muted">({{ customer.email }})</span>
                        </template>
                      </search-select>
                      <b-form-invalid-feedback>
                        {{ errors.first('record_customer_id') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label>Provenance</label>
                    <div class="position-relative form-group">
                      <b-form-select
                        disabled
                        name="record_origin"
                        type="text"
                        class="form-control form-control-sm"
                        v-model="model.origin"
                        :options="origins"
                        size="sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>Offre commerciale*</label>
                    <div class="position-relative form-group">
                      <search-select
                        :url="offersUrl"
                        :disabled="readonly"
                        v-validate="'required'"
                        :state="state('record_offer_id')"
                        v-model="model.offer_id"
                        placeholder="Rechercher par nom de résidence..."
                      >
                        <template slot="selected-option" slot-scope="offer">
                          {{ offer.residence_title }} {{ offer.unit_type_title }}
                        </template>
                        <template slot="option" slot-scope="offer">
                          <span class="text-primary">{{ offer.residence_title }}</span>
                          {{ offer.unit_type_title }}
                        </template>
                      </search-select>
                      <b-form-invalid-feedback>
                        {{ errors.first('record_offer_id') }}
                      </b-form-invalid-feedback>
                      <b-form-text>
                        Offre sélectionnée par le client
                      </b-form-text>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label>Mois sélectionné</label>
                    <div class="position-relative form-group">
                      <b-form-select
                        disabled
                        name="record_availability_month"
                        v-model="model.availability_month"
                        :options="monthOptions"
                        size="sm">
                        <template slot="first">
                          <option :value="null" disabled>Choisissez un mois</option>
                        </template>
                      </b-form-select>
                      <b-form-text>
                        Mois sélectionné par le client
                      </b-form-text>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label>Année sélectionnée</label>
                    <div class="position-relative form-group">
                      <b-form-select
                        disabled
                        name="record_availability_year"
                        v-model="model.availability_year"
                        :options="years"
                        size="sm">
                        <template slot="first">
                          <option :value="null" disabled>Choisissez une année</option>
                        </template>
                      </b-form-select>
                      <b-form-text>
                        Année sélectionnée par le client
                      </b-form-text>
                    </div>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>
                      Du...
                    </label>
                    <div class="position-relative form-group">
                      <DatePicker
                        ref="record_desired_started_at"
                        name="record_desired_started_at"
                        v-model="model.desired_started_at"
                        lang="fr"
                        format="DD/MM/YYYY"
                        :disabled="readonly"
                        :not-after="model.desired_finished_at"
                      />
                      <b-form-text>
                        Date d'entrée souhaitée
                      </b-form-text>
                      <b-form-invalid-feedback>
                        {{ errors.first('record_desired_started_at') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label>Au...</label>
                    <div class="position-relative form-group">
                      <DatePicker
                        name="record_desired_finished_at"
                        v-model="model.desired_finished_at"
                        lang="fr"
                        format="DD/MM/YYYY"
                        :disabled="readonly"
                        :not-before="model.desired_started_at"
                      />
                      <b-form-text>
                        Date de sortie souhaitée
                      </b-form-text>
                    </div>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-12">
                    <div class="position-relative form-group">
                      <b-form-checkbox :disabled="readonly" v-model="model.accept_technical_diagnostic">
                        Autorise Arpej à communiquer le dossier de diagnostic technique prévu par la loi du 6 juillet 1989 par voie dématérialisée
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <record-contract-form
                  :readonly="readonly"
                  :value="value"
                  :units-url="unitsUrl"
                  @results="units = $event"
                  :assignable-unit="assignableUnit"
                  :available-units="availableUnits"
                  :pricings="pricings"
                  :signed-document-model-lists="signedDocumentModelLists"
                  :default-ar-code-prefix="defaultArCodePrefix" />
                <item-information :value="value" />
              </b-tab>
              <b-tab
                v-for="(tenant, index) in model.tenants_attributes"
                :title="`Locataire n°${index + 1}`"
                :key="`tenant-${index}`"
                :active="tenant._active">
                <b-alert :show="tenant.confirmed" variant="info">
                  <i class="fas fa-info-circle"></i>
                  L'adresse e-mail {{tenant.email}} a été confirmée le {{ tenant.confirmed_at | moment('DD/MM/YYYY [à] HH[h]mm') }}.
                </b-alert>
                <b-alert :show="tenant.id && !tenant.confirmed" variant="warning">
                  <i class="fas fa-info-circle"></i>
                  L'adresse e-mail {{tenant.email}} n'a pas été confirmée.
                  <span :show="tenant.confirmation_sent_at">
                    La demande a été adressée le {{ tenant.confirmation_sent_at | moment('DD/MM/YYYY [à] HH[h]mm') }}.
                  </span>
                  <a href="#" @click="createConfirmationRequest(tenant)">Cliquez ici</a> pour renvoyer l'email de confirmation.
                </b-alert>
                <record-person-form
                  v-model="model.tenants_attributes[index]"
                  :index="index"
                  :readonly="readonly"
                  :all-people="tenants"
                  :selected-people="model.tenants_attributes"
                  :situations="tenantSituations"
                  :countries="countries"
                  :nationalities="nationalities"
                  :family-situations="familySituations"
                  :studies="studies"
                  :school-types="schoolTypes"
                  :schools-url="schoolsUrl"
                  :study-sectors="studySectors"
                  :activity-sectors="activitySectors"
                  :employment-contracts="employmentContracts"
                  :fields="tenantFields"
                  :contract-situations="contractSituations" />
              </b-tab>
              <b-tab
                v-for="(guarantor, index) in model.guarantors_attributes"
                :title="`Garant n°${index + 1}`"
                :key="`guarantor-${index}`"
                :active="guarantor._active"
                @click="submitEnabled = !readonly">
                <b-alert :show="guarantor.confirmed" variant="info">
                  <i class="fas fa-info-circle"></i>
                  L'adresse e-mail {{guarantor.email}} a été confirmée le {{ guarantor.confirmed_at | moment('DD/MM/YYYY [à] HH[h]mm') }}.
                </b-alert>
                <b-alert :show="guarantor.id && !guarantor.confirmed" variant="warning">
                  <i class="fas fa-info-circle"></i>
                  L'adresse e-mail {{guarantor.email}} n'a pas été confirmée.
                  <span :show="guarantor.confirmation_sent_at">
                    La demande a été adressée le {{ guarantor.confirmation_sent_at | moment('DD/MM/YYYY [à] HH[h]mm') }}.
                  </span>
                  <a href="#" @click="createConfirmationRequest(guarantor)">Cliquez ici</a> pour renvoyer l'email de confirmation.
                </b-alert>
                <record-person-form
                  v-model="model.guarantors_attributes[index]"
                  :index="index"
                  :readonly="readonly"
                  :all-people="guarantors"
                  :selected-people="model.guarantors_attributes"
                  :situations="guarantorSituations"
                  :countries="countries"
                  :activity-sectors="activitySectors"
                  :employment-contracts="employmentContracts"
                  :nationalities="nationalities"
                  :family-situations="familySituations"
                  :relationships="relationships"
                  :marital-statuses="maritalStatuses"
                  :company-types="companyTypes"
                  :fields="guarantorFields" />
              </b-tab>
              <b-tab title="Garantie locative" v-if="showInstitutionalGuarantor">
                <div class="mb-4 widget-content bg-light" v-if="!readonly">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                      <div class="widget-subheading">
                        Supprimer cette garantie locative&nbsp;?
                      </div>
                    </div>
                    <div class="widget-content-right">
                      <button type="button" class="btn ml-3 btn-sm btn-icon btn-transition btn-outline-danger" @click="deleteInstitutionalGuarantor">
                        <i class="pe-7s-trash btn-icon-wrapper"></i>
                        Supprimer
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-6">
                    <label>Garantie locative</label>
                    <div class="position-relative form-group">
                      <b-form-select
                        :disabled="readonly"
                        name="record_institutional_guarantor_id"
                        v-model="model.institutional_guarantor_id"
                        :options="institutionalGuarantors"
                        v-validate="'required'"
                        :state="state('record_institutional_guarantor_id')"
                        size="sm">
                        <template slot="first">
                          <option :value="null" disabled>Choisissez une garantie locative</option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label>Référence garantie locative</label>
                    <div class="position-relative form-group">
                      <b-form-input
                        :readonly="readonly"
                        name="record_institutional_guarantor_number"
                        type="text"
                        class="form-control form-control-sm"
                        v-model="model.institutional_guarantor_number"
                        :state="state('record_institutional_guarantor_number')" />
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab
                v-if="model.tenants_attributes.length > 0 || model.guarantors_attributes.length > 0"
                title="Pièces jointes"
                @click="submitEnabled = !readonly">
                <record-document-form
                  v-for="(tenant, index) in model.tenants_attributes"
                  v-model="model.tenants_attributes[index]"
                  :index="index"
                  :readonly="readonly"
                  :situations="tenantSituations"
                  :situation-document-types="situationDocumentTypes" />
                <record-document-form
                  v-for="(guarantor, index) in model.guarantors_attributes"
                  v-model="model.guarantors_attributes[index]"
                  :index="index"
                  :readonly="readonly"
                  :situations="guarantorSituations"
                  :situation-document-types="situationDocumentTypes" />
              </b-tab>
              <b-tab title="État" @click="submitEnabled = true">
                <record-state-form
                  v-model="model"
                  :statuses="statuses"
                  :readonly="!readonly"
                  :states="states" />
              </b-tab>
              <b-tab title="Messages" @click="changeToMessagesTab" v-if="messagesUrl" :active="currentHash == '#messages'">
                <record-message-form
                  :messages="messages_"
                  :action="messagesUrl"
                  :read_url="readMessagesUrl"
                  :markAsRead="markAsReadTrigger" />
              </b-tab>
              <b-tab title="Signatures" v-if="isProceduresVisible">
                <b-alert variant="info">
                  <i class="fas fa-info-circle"></i>
                  Le tampon serveur apparaîtra dans les documents lorsque tous les contrats auront été signés.
                </b-alert>
                <div v-for="transaction in model.yousign.transactions" v-bind:key="transaction.created_at">
                  <h5 class="card-title">{{ transaction.title }}</h5>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center">Identifiant</th>
                          <th class="text-center">Contrats à signer</th>
                          <th class="text-center">Statut</th>
                          <th class="text-center">Date de création</th>
                          <th class="text-center">Expire le</th>
                          <th class="text-center">Refusée le</th>
                          <th class="text-center" v-for="person in model.yousign.record_people" v-bind:key="person.id">
                            {{ person.name }}
                          </th>
                          <th class="text-center">Télécharger</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="procedure in transaction.procedures" v-bind:key="procedure.id">
                          <td class="text-center align-middle">{{ procedure.id }}</td>
                          <td class="text-center align-middle">{{ procedure.signed_pdf_title }}</td>
                          <td class="text-center align-middle">{{ procedure.status }}</td>
                          <td class="text-center align-middle">{{ procedure.created_at }}</td>
                          <td class="text-center align-middle">{{ procedure.expired_at }}</td>
                          <td class="text-center align-middle">{{ procedure.refused_at }}</td>
                          <td class="text-center align-middle" v-for="signatory in procedure.signatories" v-bind:key="signatory.id" v-bind:style="{ backgroundColor: signatory.color }">
                            <span v-show="signatory.info">
                              {{ signatory.info }}
                            </span>
                          </td>
                          <td class="text-center align-middle">
                            <b-button
                              variant="outline-primary"
                              size="sm"
                              :href="procedure.signed_pdf_file_url"
                              :disabled="!procedure.signed_pdf_file_url"
                              target="_blank">
                              <i class="fas fa-file-alt"></i>
                              Document
                            </b-button>
                            <b-button
                              variant="outline-primary"
                              size="sm"
                              :href="procedure.proof_zip_file_url"
                              :disabled="!procedure.proof_zip_file_url"
                              target="_blank">
                              <i class="far fa-file-alt"></i>
                              Preuve
                            </b-button>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="6"></td>
                          <td class="text-center align-middle" v-for="email in transaction.emails" v-bind:key="email.index">
                            {{ email.sent_at }}
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="6"></td>
                          <td class="text-center align-middle" v-for="email in transaction.emails" v-bind:key="email.index">
                            <b-button
                              variant="outline-primary"
                              size="sm"
                              :disabled="!email.resend_url || email.sending"
                              @click="resendYousignEmail(email)">
                              <i class="fas fa-file-alt"></i>
                              Réenvoyer l'email
                            </b-button>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </b-tab>
              <b-tab @click="submitEnabled = !readonly">
                <template slot="title">
                  <i class="fas fa-exclamation-circle text-warning" v-show="subscribedServices.length > 0"></i>
                  Services payants
                </template>
                <record-subscribed-service-form
                  :readonly="readonly"
                  v-model="model"
                  :units="units"
                  :subscribed-services="subscribedServices"
                  :residence-services-url="residenceServicesUrl"
                  :residence-services-editable="residenceServicesEditable"
                />
              </b-tab>
              <b-tab title="Dossiers liés">
                <div class="form-row mt-3">
                  <div class="col-md-12">
                    <AssociatedRecord
                      v-model="model.records" />
                  </div>
                </div>
              </b-tab>
              <b-tab title="Gestion" v-if="model.erp_data">
                <h5 class="card-title">Gestion</h5>
                <div v-if="!model.erp_data.rent">
                  <b-alert class="text-center" show>Aucun dossier ERP lié.</b-alert>
                </div>
                <div v-else>
                  <div class="form-row">
                    <div class="col-md-6">
                      <label>Code AR (TEGIA)</label>
                      <b-form-input
                        readonly
                        type="text"
                        class="form-control form-control-sm"
                        v-model="model.erp_data.tenant.reference"
                      />
                    </div>
                  </div>
                  <div class="form-row mt-3">
                    <div class="col-md-6">
                      <label>Prénom</label>
                      <b-form-input
                        readonly
                        type="text"
                        class="form-control form-control-sm"
                        v-model="model.erp_data.tenant.first_name"
                      />
                    </div>
                    <div class="col-md-6">
                      <label>Nom</label>
                      <b-form-input
                        readonly
                        type="text"
                        class="form-control form-control-sm"
                        v-model="model.erp_data.tenant.last_name"
                      />
                    </div>
                  </div>
                  <div class="form-row mt-3">
                    <div class="col-md-6">
                      <label>E-mail</label>
                      <b-form-input
                        readonly
                        type="text"
                        class="form-control form-control-sm"
                        v-model="model.erp_data.tenant.email"
                      />
                    </div>
                    <div class="col-md-6">
                      <label>Téléphone</label>
                      <b-form-input
                        readonly
                        type="text"
                        class="form-control form-control-sm"
                        v-model="model.erp_data.tenant.phone_number"
                      />
                    </div>
                  </div>
                  <div class="form-row mt-3">
                    <div class="col-md-6">
                      <label>Situation familiale</label>
                      <b-form-input
                        readonly
                        type="text"
                        class="form-control form-control-sm"
                        v-model="model.erp_data.tenant.mariral_status"
                      />
                    </div>
                  </div>

                  <hr class="my-3">
                  <h5 class="card-title">Assurance</h5>
                  <div class="form-row">
                    <div class="col-md-6">
                      <label>Compagnie</label>
                      <b-form-input
                        readonly
                        type="text"
                        class="form-control form-control-sm"
                        v-model="model.erp_data.rent.insurance"
                      />
                    </div>
                    <div class="col-md-6">
                      <label>Date d'échéance</label>
                      <b-form-input
                        readonly
                        type="text"
                        class="form-control form-control-sm"
                        v-model="model.erp_data.rent.insurance_ended_at"
                      />
                    </div>
                  </div>

                  <hr class="my-3">
                  <h5 class="card-title">Compte</h5>
                  <div class="form-row">
                    <div class="col-md-6">
                      <label>Solde</label>
                      <b-form-input
                        readonly
                        type="text"
                        class="form-control form-control-sm"
                        v-model="model.erp_data.account.balance"
                      />
                    </div>
                    <div class="col-md-6">
                      <label>Date de mise à jour</label>
                      <b-form-input
                        readonly
                        type="text"
                        class="form-control form-control-sm"
                        v-model="model.erp_data.account.erp_updated_at"
                      />
                      <!-- {{ model.erp_data.account.erp_updated_at | moment('DD/MM/YYYY') }} -->
                    </div>
                  </div>

                  <hr class="my-3">
                  <h5 class="card-title">Dernières opérations du compte</h5>
                  <table v-if="model.erp_data.account.lines.length > 0" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th class="text-center">Date</th>
                        <th>Crédit</th>
                        <th>Débit</th>
                        <th>Libellé</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="line in model.erp_data.account.lines" v-bind:key="line.id">
                        <td class="text-center">{{ line.date | moment('DD/MM/YYYY') }}</td>
                        <td>{{ line.credit }}</td>
                        <td>{{ line.debit }}</td>
                        <td>{{ line.label }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a :href="cancel_url" class="btn btn-lg btn-link active float-left">
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg">
                Annuler
              </a>
              <b-button
                v-if="submitEnabled"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit">
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import RecordContractForm from './RecordContractForm'
  import RecordPersonForm from './RecordPersonForm'
  import RecordDocumentForm from './RecordDocumentForm'
  import RecordStateForm from './RecordStateForm'
  import RecordMessageForm from './RecordMessageForm'
  import RecordSubscribedServiceForm from './RecordSubscribedServiceForm'
  import DatePicker from 'vue2-datepicker'
  import moment from 'moment'
  import axios from 'axios'
  import AssociatedRecord from '../base/AssociatedRecord'

  moment.locale('fr')

  export default {
    mixins: [
      Form
    ],
    components: {
      DatePicker,
      RecordContractForm,
      RecordPersonForm,
      RecordDocumentForm,
      RecordStateForm,
      RecordMessageForm,
      RecordSubscribedServiceForm,
      AssociatedRecord
    },
    props: {
      statuses: Array,
      states: Array,
      origins: Array,
      offers: Array,
      years: Array,
      institutionalGuarantors: Array,
      assignableUnit: Boolean,
      availableUnits: Boolean,
      pricings: Object,
      signedDocumentModelLists: Array,
      tenants: Array,
      tenantSituations: Array,
      tenantFields: Object,
      guarantors: Array,
      guarantorSituations: Array,
      guarantorFields: Object,
      countries: Array,
      nationalities: Array,
      familySituations: Array,
      studies: Array,
      schoolTypes: Array,
      studySectors: Array,
      activitySectors: Array,
      employmentContracts: Array,
      companyTypes: Array,
      relationships: Array,
      maritalStatuses: Array,
      situationDocumentTypes: Array,
      messages_: Array,
      messagesUrl: String,
      readMessagesUrl: String,
      contractSituations: Array,
      subscribedServices: Array,
      customersUrl: String,
      offersUrl: String,
      unitsUrl: String,
      schoolsUrl: String,
      residenceServicesUrl: String,
      residenceServicesEditable: Boolean,
      residencePaidServices: Array,
      partnersUrl: String,
      defaultArCodePrefix: String
    },
    data() {
      return {
        units: [],
        showInstitutionalGuarantor: false,
        submitEnabled: !this.readonly,
        months: _.range(0, 12),
        markAsReadTrigger: false,
        currentHash: null
      }
    },
    computed: {
      monthOptions() {
        return this.months.map(month => {
          return {
            value: month + 1,
            text: _.capitalize(moment().month(month).format('MMMM'))
          }
        })
      },
      isProceduresVisible() {
        return this.readonly && this.model.yousign.transactions.length > 0
      }
    },
    mounted() {
      this.currentHash = location.hash
      if (this.currentHash == '#messages') {
        this.$nextTick(() => this.changeToMessagesTab())
      }
      if (this.model.id && this.model.institutional_guarantor_id) {
        this.showInstitutionalGuarantor = true
      }
    },
    methods: {
      async toExport() {
        try {
          const response = await axios.patch(this.model.export_url, { to_export: true })
          document.location = response.headers.location
        } catch {
          alert("Une erreur est survenue. Veuillez réessayer ultérieurement.")
        }
      },
      newTenant() {
        this.model.tenants_attributes.push({
          _create: true,
          _active: true,
          type: 'Tenant',
          customer_id: this.model.customer_id,
          situation: this.tenantSituations[0].value,
          gender: 'male',
          family_situation_id: this.familySituations[0].value,
          documents_attributes: []
        })
      },
      newGuarantor() {
        this.model.guarantors_attributes.push({
          _create: true,
          _active: true,
          type: 'Guarantor',
          customer_id: this.model.customer_id,
          situation: this.guarantorSituations[0].value,
          gender: 'male',
          family_situation_id: this.familySituations[0].value,
          documents_attributes: []
        })
      },
      deleteInstitutionalGuarantor() {
        this.$set(this.model, 'institutional_guarantor_id', '')
        this.$set(this.model, 'institutional_guarantor_number', '')
        this.showInstitutionalGuarantor = false
      },
      changeToMessagesTab() {
        this.markAsReadTrigger = true
        this.submitEnabled = false
      },
      async createConfirmationRequest(record_person) {
        try {
          const response = await axios.post(record_person.confirmation_request_url)
          document.location = response.headers.location
        } catch {
          alert("Une erreur est survenue. Veuillez réessayer ultérieurement.")
        }
      },
      async resendYousignEmail(email) {
        try {
          email.sending = true
          const response = await axios.post(email.resend_url)
          email.sent_at = response.data.sent_at
          email.sending = false
        } catch {
          email.sending = false
          alert("Veuillez réessayer ultérieurement.")
        }
      }
    }
  }
</script>
