<template>
	<div>
		<h5 class="card-title">{{ title }}</h5>
		<b-alert variant="info" :show="true" v-if="records.length == 0">
		  <i class="fas fa-info-circle mr-2"></i>
		  Il n'y a pas de dossier lié
		</b-alert>
		<div class="table-responsive" v-else>
		  <table class="table table-striped table-bordered">
		    <thead>
		      <tr>
		        <th class="text-center">Id</th>
		        <th>Numéro</th>
		        <th>Etat</th>
		        <th>Résidence</th>
		        <th>Date de création</th>
		        <th>Date de début du contrat</th>
		        <th>Date de suppression</th>
		        <th class="text-center">Options</th>
		      </tr>
		    </thead>
		    <tbody>
		      <tr v-for="record in records" v-bind:key="record.id">
		        <td class="text-center">{{ record.id }}</td>
		        <td>{{ record.number }}</td>
		        <td>{{ record.state }}</td>
		        <td>{{ record.residence_title }}</td>
		        <td>{{ record.created_at | moment('DD/MM/YYYY [à] HH[h]mm') }}</td>
		        <td>{{ record.agreement_given_at | moment('DD/MM/YYYY [à] HH[h]mm') }}</td>
		        <td>{{ record.removed_at | moment('DD/MM/YYYY [à] HH[h]mm') }}</td>
		        <td class="text-center">
		          <b-button
								v-if="!record.removed"
		            variant="outline-primary"
		            size="sm"
		            :href="record.show_url"
		            target="_blank">
		            <i class="fas fa-eye"></i>
		            Voir
		          </b-button>
		        </td>
		      </tr>
		    </tbody>
		  </table>
		</div>
	</div>
</template>

<script>
  import moment from 'moment'
  import _ from 'lodash'

  export default {
    components: {
      moment
    },
    props: {
      title: String,
      value: {
        type: [Array, Object],
        default: []
      }
    },
    data() {
      return {
        records: _.compact(_.flatten([this.value]))
      }
    }
  }
</script>
