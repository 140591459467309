<template>
  <span>
    <b-button
      class="btn-icon btn-icon-only"
      :class="{ 'btn-sm': !onResource }"
      variant="outline-danger"
      v-b-tooltip.hover
      :title="tooltip"
      :disabled="disabled"
      v-b-modal="modalId">
      <slot />
    </b-button>
    <b-modal :id="modalId" v-bind="modalOptions" @ok="submit">
      Confirmer la suppression.
    </b-modal>
  </span>
</template>

<script>
  import axios from 'axios'

  export default {
    props: {
      href: {
        type: String,
        required: true
      },
      onResource: {
        type:Boolean,
        default: false
      },
      tooltip: String
    },
    data() {
      return {
        disabled: false,
        modalOptions: {
          size: 'sm',
          centered: true,
          hideHeader: true,
          footerBgVariant: 'white',
          footerBorderVariant: 'white',
          footerClass: 'pt-0',
          cancelTitle: 'Annuler',
          okTitle: 'Supprimer',
          okVariant: 'danger'
        }
      }
    },
    computed: {
      modalId() {
        return 'delete_' + _.last(this.href.split('/'))
      }
    },
    methods: {
      submit() {
        this.disabled = true
        axios.delete(
          this.href
        ).then(response => {
          this.disabled = false
          if (this.onResource) {
            document.location = response.headers.location
          } else {
            this.$emit('deleted', this.href)
          }
        })
      }
    }
  }
</script>
