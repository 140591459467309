<template>
  <div class="app-sidebar sidebar-shadow" @mouseover="toggleSidebarHover('add', 'closed-sidebar-open')" @mouseleave="toggleSidebarHover('remove', 'closed-sidebar-open')">
    <div class="app-header__logo">
      <div class="logo-src"></div>
      <div class="header__pane ml-auto">
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" :class="{ 'is-active' : isOpen }" @click="toggleBodyClass('closed-sidebar')">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-sidebar-content">
      <VuePerfectScrollbar class="app-sidebar-scroll" v-once>
        <sidebar-menu ref="menu" :menu="menu" />
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
  import { SidebarMenu } from 'vue-sidebar-menu'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  export default {
    components: {
      SidebarMenu,
      VuePerfectScrollbar
    },
    props: {
      menu: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        isOpen: false,
        sidebarActive: false,
        collapsed: true,
        windowWidth: 0
      }
    },
    methods: {
      toggleBodyClass(className) {
        const el = document.body;
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      },
      toggleSidebarHover(add, className) {
        const el = document.body;
        this.sidebarActive = !this.sidebarActive;
        this.windowWidth = document.documentElement.clientWidth;
        if (this.windowWidth > '992') {
          if (add == 'add') {
            el.classList.add(className);
          } else {
            el.classList.remove(className);
          }
        }
      },
      getWindowWidth(event) {
        const el = document.body;
        this.windowWidth = document.documentElement.clientWidth;
        if (this.windowWidth < '1350') {
          el.classList.add('closed-sidebar', 'closed-sidebar-md');
        } else {
          el.classList.remove('closed-sidebar', 'closed-sidebar-md');
        }
      }
    },
    mounted() {
      this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth);
        this.getWindowWidth()
      })
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.getWindowWidth);
    }
  }
</script>
