<template>
  <div>
    <h5 class="card-title">INFORMATIONS</h5>
    <div class="form-row mb-3">
      <div class="col-md-4">
        <b-form-checkbox :disabled="readonly" v-model="model.expired_term">
          Terme échu
        </b-form-checkbox>
        <b-form-checkbox :disabled="readonly" v-model="model.conventioned" v-if="accommodation">
          Conventionné
        </b-form-checkbox>
      </div>
    </div>
    <div class="form-row" v-if="accommodation">
      <div class="col-md-4">
        <label>Numéro de convention</label>
        <b-form-input
          :readonly="readonly"
          size="sm"
          v-model="model.convention_number"
          name="unit_convention_number"
          :state="state('unit_convention_number')" />
        <b-form-invalid-feedback>
          {{ errors.first('unit_convention_number') }}
        </b-form-invalid-feedback>
      </div>
      <div class="col-md-4">
        <label>Date de convention</label>
        <DatePicker v-model="model.conventioned_at" lang="fr" format="DD/MM/YYYY" :disabled="readonly" @change="formatDate" />
      </div>
    </div>
    <div class="form-row mt-3" v-if="accommodation">
      <div class="col-md-8">
        <label>Commentaire sur le réservataire</label>
        <b-form-textarea :readonly="readonly" size="sm" v-model="model.priority_comment" />
      </div>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import moment from 'moment'
  import Form from '../mixins/Form'

  export default {
    mixins: [
      Form
    ],
    components: {
      DatePicker
    },
    props: {
      accommodation: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      formatDate() {
        this.model.conventioned_at = moment(this.model.conventioned_at).format('Y-M-D')
      }
    }
  }
</script>
