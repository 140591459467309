<template>
  <div>
    <div class="content">
      <div class="row">
        <div class="col-md-8">
          <div class="main-card mb-3 card">
            <div class="card-header d-flex justify-content-between">
              Mes dossiers
              <a :href="exportUrl" class="btn btn-transition btn-outline-primary">
                <i class="far fa-file-excel mr-1"></i>
                Export
              </a>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <label for="residence">Résidence</label>
                  <b-form-select v-model="filters.residence_id" :options="residences" size="sm" @change="applyFilters">
                    <template v-slot:first>
                      <option :value="null">Toutes vos résidences</option>
                    </template>
                  </b-form-select>
                </div>
                <div class="col-md-5">
                  <label for="daterange">Période de création</label>
                  <date-picker
                    lang="fr"
                    name="record_created_at"
                    v-model="filters.created_at"
                    :not-after="new Date()"
                    format="DD/MM/YYYY"
                    value-type="format"
                    @input="applyFilters"
                    @clear="filters.created_at = null"
                    :range="true">
                  </date-picker>
                </div>
                <div class="col-md-2">
                  <button @click="reset" :disabled="loading_records" class="btn btn-transition btn-outline-secondary pull-right">Réinitialiser</button>
                </div>
              </div>
            </div>
            <hr>
            <div class="card-body">
              <div class="no-gutters row">
                <div class="col-md-7">
                  <div>
                    <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                      <VueElementLoading :active="loading_records" spinner="ring" />
                      <div v-if="!loading_records">
                        <div
                          class="vertical-timeline-element"
                          v-for="record in records"
                          v-bind:key="record.status_name">
                          <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                              <i :class="'badge badge-dot badge-dot-xl badge-' + record.status_color_name"></i>
                            </span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">
                                <a :class="'text-' + record.status_color_name" :href="recordsUrl(record)">{{ record.status_name }}</a>
                              </h4>
                              <p
                                v-for="state in record.states"
                                v-bind:key="state.state_name">
                                {{ state.state_name }}&nbsp;:
                                <a :href="recordsUrl(state)">
                                  <b>{{ state.count }}</b>
                                </a>
                              </p>
                              <p v-if="record.states.length == 0">Aucun</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <VueElementLoading :active="loading_records" spinner="ring" />
                  <apexchart ref="donut" height="" type="donut" :options="chartOptions" :series="chartSeries"></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-3 text-dark card-border card text-white bg-light">
            <div class="card-header">Logements et parkings</div>
            <div class="card-body">
              <VueElementLoading :active="loading_units" spinner="ring" />
              <div class="grid-menu grid-menu-2col overflow-hidden" v-if="!loading_units">
                <div class="no-gutters row">
                  <div class="col-sm-6"
                    v-for="unit in units"
                    v-bind:key="unit.label">
                    <a :href="unit.url" class="btn-icon-vertical btn-square btn-transition btn btn-outline-info">{{ unit.label }} : {{ unit.count }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3 text-dark card-border card text-white bg-light">
            <div class="card-header">Offres</div>
            <div class="card-body">
              <VueElementLoading :active="loading_offers" spinner="ring" />
              <div class="grid-menu grid-menu-2col overflow-hidden" v-if="!loading_offers">
                <div class="no-gutters row">
                  <div class="col-sm-6"
                    v-for="offer in offers"
                    v-bind:key="offer.label">
                    <a :href="offer.url" class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary">{{ offer.label }} : {{ offer.count }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import axios from 'axios'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import VueElementLoading from 'vue-element-loading'
  import VueApexCharts from 'vue-apexcharts'
  import moment from 'moment'

  export default {
    mixins: [],
    components: {
      DatePicker,
      VuePerfectScrollbar,
      VueElementLoading,
      'apexchart': VueApexCharts,
      moment
    },
    props: {
      records_url: {
        type: String,
        required: true
      },
      units_url: {
        type: String,
        required: true
      },
      offers_url: {
        type: String,
        required: true
      },
      residences: {
        type: Array,
        default: () => []
      },
      export_url: String
    },
    data() {
      return {
        loading_records: false,
        loading_units: false,
        loading_offers: false,
        filters: {
          residence_id: null,
          created_at: null
        },
        records: [],
        units: [],
        offers: []
      }
    },
    mounted() {
      this.fetchAll()
    },
    methods: {
      recordsUrl(record) {
        if (this.filters.residence_id) {
          return `${record.url}&residence_id=${this.filters.residence_id}`
        } else {
          return record.url
        }
      },
      fetchAll() {
        this.fetchRecords()
        this.fetchUnits()
        this.fetchOffers()
      },
      fetchRecords() {
        this.loading_records = true
        this.fetch(this.records_url, (data) => {
          this.records = data.records
          this.loading_records = false
        })
      },
      fetchUnits() {
        this.loading_units = true
        this.fetch(this.units_url, (data) => {
          this.units = data.units
          this.loading_units = false
        })
      },
      fetchOffers() {
        this.loading_offers = true
        this.fetch(this.offers_url, (data) => {
          this.offers = data.offers
          this.loading_offers = false
        })
      },
      fetch(url, callback) {
        axios.get(url, {
          params: {
            ...this.filters
          }
        }).then(response => {
          callback(response.data)
        }).catch(error => {
          if (error.response && error.response.data && error.response.data.errors) {
            alert(error.response.data.errors.join('. '))
          } else {
            alert("Une erreur est survenue. Veuillez réessayer ultérieurement.")
          }
        })
      },
      applyFilters() {
        this.fetchRecords()
      },
      reset() {
        this.filters = {
          residence_id: null,
          created_at: null
        }
        this.fetchRecords()
      }
    },
    computed: {
      exportUrl() {
        let params = []
        if (this.filters.residence_id) {
          params.push(`residence_id=${this.filters.residence_id}`)
        }
        if (this.filters.created_at) {
          params.push(`created_at[]=${this.filters.created_at[0]}`)
          params.push(`created_at[]=${this.filters.created_at[1]}`)
        }
        return `${this.export_url}?${params.join('&')}`
      },
      chartOptions() {
        return {
          labels: this.records.map(record => record.status_name),
          legend: {
            show: true,
            position: 'bottom'
          },
          colors: this.records.map(record => record.status_color)
        }
      },
      chartSeries() {
        return this.records.map(record => record.percent)
      }
    }
  }
</script>

<style type="text/css">
  .mx-datepicker-range { width: 100%; }
</style>
